import { Injectable } from "@angular/core";
import { Route, UrlTree } from "@angular/router";
import { first, map, Observable, take } from "rxjs";
import { AuthModel } from "../+store/model";

@Injectable({
  providedIn: 'root'
})
export class AuthenticateLoad  {

  constructor(private authModel: AuthModel) { }

  canLoad(route: Route): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const { data } = route;
    const isPublicOnlyRoute = !!data?.["publicOnly"];

    return this.authModel.isLogged$.pipe(
      take(1),
      map((isLogged) => {
        if (isPublicOnlyRoute) { return isLogged === false; }
        return isLogged === true;
      }),
    );
  }

}