import { Observable, ObservableInput, ObservableInputTuple, ObservedValueOf, SchedulerLike, combineLatest, debounceTime } from 'rxjs';

export function combineLatestForFrame(sources: []): Observable<never>;
export function combineLatestForFrame<A extends readonly unknown[]>(sources: readonly [...ObservableInputTuple<A>]): Observable<A>;
export function combineLatestForFrame<A extends readonly unknown[], R>(
  sources: readonly [...ObservableInputTuple<A>],
  resultSelector: (...values: A) => R,
  scheduler: SchedulerLike
): Observable<R>;
export function combineLatestForFrame<A extends readonly unknown[], R>(
  sources: readonly [...ObservableInputTuple<A>],
  resultSelector: (...values: A) => R
): Observable<R>;
export function combineLatestForFrame<A extends readonly unknown[]>(
  sources: readonly [...ObservableInputTuple<A>],
  scheduler: SchedulerLike
): Observable<A>;
export function combineLatestForFrame<A extends readonly unknown[]>(...sources: [...ObservableInputTuple<A>]): Observable<A>;
export function combineLatestForFrame<A extends readonly unknown[], R>(
  ...sourcesAndResultSelectorAndScheduler: [...ObservableInputTuple<A>, (...values: A) => R, SchedulerLike]
): Observable<R>;
export function combineLatestForFrame<A extends readonly unknown[], R>(
  ...sourcesAndResultSelector: [...ObservableInputTuple<A>, (...values: A) => R]
): Observable<R>;
export function combineLatestForFrame<A extends readonly unknown[]>(
  ...sourcesAndScheduler: [...ObservableInputTuple<A>, SchedulerLike]
): Observable<A>;
export function combineLatestForFrame(sourcesObject: { [K in any]: never }): Observable<never>;
export function combineLatestForFrame<T extends Record<string, ObservableInput<any>>>(
  sourcesObject: T
): Observable<{ [K in keyof T]: ObservedValueOf<T[K]> }>;

export function combineLatestForFrame(...args: any): any {
  return combineLatest(...args).pipe(debounceTime(0))
}
