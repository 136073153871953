import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { interval, race } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { AuthModel } from '../+store/model';
import { RouterModel } from 'router-module';
import { Lottie } from 'lottie-module';
import { NotificationModel, NotificationType } from 'notification-module';
import { TeamsService } from '../services/teams.service';
import { LoadingTenseState } from 'shared';

@Component({
  selector: 'db-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss'],
})
export class CheckInComponent {
  showLottie$ = interval(0).pipe(take(1), map(() => true), startWith(false));

  isLoading = false;
  LoadingTenseState = LoadingTenseState;
  Lottie = Lottie;

  formValue = {
    email: '',
    accept: false,
  };

  constructor(
    private routerModel: RouterModel,
    private authModel: AuthModel,
    private notificationModel: NotificationModel,
    teamsService: TeamsService
  ) {
    this.routerModel.selectors.queryParams$.pipe(take(1)).subscribe((qp) => {
      const email: string | undefined = qp?.['email'];
      if (!email) {
        return;
      }
      this.formValue = { email, accept: true };
      this.isLoading = true;
      this.checkIn(email);
    });

    teamsService.getContext().then((context) => {
      const email = context.user?.userPrincipalName;
      if (context && Validators.email({ value: email } as any) === null) {
        this.formValue.email = email || '';
      }
    });
  }

  submitForm(form: NgForm): void {
    if (form.invalid || this.isLoading) {
      return;
    }

    this.checkIn(form.controls['email'].value);
  }

  checkIn(email: string): void {
    this.isLoading = true;
    this.authModel.actions.dispatch.checkEmail({ email });
    race(this.authModel.actions.listen.checkEmailSuccess$, this.authModel.actions.listen.checkEmailFailure$)
      .pipe(take(1))
      .subscribe((result) => {
        this.isLoading = false;
        if ('error' in result) {
          if (result.error.status === 503) {
            return;
          }
          this.notificationModel.actions.dispatch.showNotification({
            data: $localize`:@@auth-module|check-email-failed:Email check failed`,
            notificationType: NotificationType.ERROR,
          });
          return;
        }
        const {
            emailCheckResult: { companyExists, inviteOnlyAccess, userInvited },
        } = result;
        if (!companyExists) {
          this.routerModel.actions.dispatch.navigate({ commands: ['login', 'not-active-company'] });
          return;
        }
        if (inviteOnlyAccess && !userInvited) {
          this.routerModel.actions.dispatch.navigate({ commands: ['login', 'not-active-user'] });
          return;
        }
        this.routerModel.actions.dispatch.navigate({ commands: ['login', 'sign-in'], extras: { queryParamsHandling: 'preserve' } });
      });
  }
}
