import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BACKEND_SLACK_LOGIN_URL, ICompany, ICorporateInfo, IDemoCompany, IEmailCheck, IOffice, IStatusResponse } from 'common-module';
import { IPostRegistrationPayload, ISlackDataPayload } from '../interfaces-and-types';
import { IUser } from 'types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, @Inject(BACKEND_SLACK_LOGIN_URL) private backendSlackUrl: string) {}

  checkEmail(email: string): Observable<IStatusResponse<IEmailCheck>> {
    return this.http.post<IStatusResponse<IEmailCheck>>('/api/onboarding/checkEmail', { email });
  }

  getUser(): Observable<IUser> {
    return this.http.get<IUser>('/api/user');
  }

  getCorporateInfo(): Observable<IStatusResponse<ICorporateInfo> & { errorCode?: string }> {
    return this.http.get<IStatusResponse<ICorporateInfo> & { errorCode?: string }>('/api/user/corporateInfo');
  }

  // TODO: Find out what is returned here so we can put the correct generic interface!
  postRegistration(data: IPostRegistrationPayload, isSSO = false): Observable<any> {
    return this.http.post<any>(`/api/payments/postRegistration${isSSO ? '?sso=true' : ''}`, data);
  }

  // TODO: Find out what is returned here so we can put the correct generic interface!
  authSaml(data: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    profile: any;
  }): Observable<{ data: { isNewUser: boolean; customToken: string }; error: any; success: boolean }> {
    return this.http.post<any>('/api/auth/saml', data);
  }

  syncGoogle(accessToken: string): Observable<IStatusResponse<void> & { user: IUser }> {
    const body = { provider: 'Google', token: accessToken };
    return this.http.put<IStatusResponse<void> & { user: IUser }>('/api/googleDirectory/sync/user?officeIds', body);
  }

  syncMicrosoft(accessToken: string): Observable<IStatusResponse<void> & { user: IUser }> {
    const body = { provider: 'Azure', token: accessToken };
    return this.http.put<IStatusResponse<void> & { user: IUser }>('/api/activeDirectory/sync/user?officeIds', body);
  }

  syncMicrosoftForTeams(accessToken: string, tid: string): Observable<IStatusResponse<void> & { user: IUser }> {
    const body = { provider: 'Azure', id_token: { token: accessToken, tid } };
    return this.http.put<IStatusResponse<void> & { user: IUser }>('/api/activeDirectory/sync/user?officeIds', body);
  }

  authenticateWithMicrosoftToken(token: string): Observable<IStatusResponse<{ customToken: string; isNewUser: boolean }>> {
    const body = { isMicrosoftSignIn: true, token };
    return this.http.post<IStatusResponse<{ customToken: string; isNewUser: boolean }>>('/api/auth/microsoft', body);
  }

  loadPublicDomainsList(): Observable<string[]> {
    return this.http.get<string[]>('/assets/public-domains.json');
  }

  updateUser(updates: Partial<IUser>): Observable<IStatusResponse<Partial<IUser>>> {
    return this.http.put<IStatusResponse<Partial<IUser>>>('/api/user', updates);
  }

  createFreeTrialCompany(
    demoCompany: IDemoCompany
  ): Observable<{ businessCompany: ICompany; success: boolean; office1: IOffice; office2: IOffice; user: IUser; message?: string }> {
    return this.http.post<{
      businessCompany: ICompany;
      success: boolean;
      office1: IOffice;
      office2: IOffice;
      user: IUser;
      message?: string;
    }>('/api/internalWorkspaces/createFreeTrialCompany', demoCompany);
  }

  sendSlackData(data: ISlackDataPayload) {
    return this.http.post(this.backendSlackUrl, data, {
      headers: {
        'x-slack-signature': data.slackSignature,
        'x-slack-request-timestamp': data.slackRequestTimestamp,
      },
    });
  }
}
