<db-card>
  <div class="wrapper">
    <h3 *ngIf="emailCheckResult$ | async">
      <ng-container
        *ngIf="
          !(emailCheckResult$ | async)?.userExists;
          else welcomeBackTemplate
        "
        i18n="@@auth-module|welcome"
      >
        Welcome to {{ (emailCheckResult$ | async)?.companyName }}
      </ng-container>
      <ng-template #welcomeBackTemplate i18n="@@auth-module|welcome-back"
        >Welcome back to {{ (emailCheckResult$ | async)?.companyName }}
      </ng-template>
    </h3>

    <div
      class="company-logo"
      [ngStyle]="{
        'background-image':
          'url(' + (emailCheckResult$ | async)?.companyLogoUrl + ')',
        'background-position': 'center'
      }"
    ></div>
    <div class="email">
      <img src="/assets/icons/purple-check-mark.svg" alt="Check" />
      <p class="email">{{ (emailCheckResult$ | async)?.email }}</p>
    </div>
    <div
      id="social-sign-in"
      class="social-sign-in-buttons"
      *ngIf="emailCheckResult$ | async as emailCheckResult"
    >
      <ng-container *ngIf="emailCheckResult.userExists; else newUser">
        <div class="info" *ngIf="emailCheckResult.extraInformation">
          <img src="/assets/icons/info.svg" alt="I" />
          <p>{{ emailCheckResult.extraInformation }}</p>
        </div>
        <db-button
          *ngIf="emailCheckResult.loginMethods?.google?.enabled"
          [buttonType]="'outlined'"
          [disabled]="
            isSigningInWithGoogle ||
            isSigningInWithMicrosoft ||
            isSigningInWithSAML
          "
          [width]="'full-width'"
          id="google"
          (click)="onGoogleSignIn()"
        >
          <img src="/assets/icons/google-logo.svg" alt="Google Logo" />
          <db-loading-tense
            infinitiveText="Sign in with Google"
            presentText="Signing in"
            [state]="
              isSigningInWithGoogle
                ? LoadingTenseState.Present
                : LoadingTenseState.Infinitive
            "
            i18n-infinitiveText="
              @@auth-module|sign-in|google-loader-infinitive-text"
            i18n-presentText="@@auth-module|sign-in-loader-present-text"
          >
          </db-loading-tense>
        </db-button>
        <db-button
          *ngIf="emailCheckResult.loginMethods?.microsoft?.enabled"
          id="microsoft"
          (click)="onMicrosoftSignIn()"
          [buttonType]="'outlined'"
          [width]="'full-width'"
          [disabled]="
            isSigningInWithGoogle ||
            isSigningInWithMicrosoft ||
            isSigningInWithSAML
          "
        >
          <img
            src="/assets/icons/microsoft-logo-small.svg"
            alt="Microsoft Logo"
          />
          <db-loading-tense
            infinitiveText="Sign in with Microsoft"
            presentText="Signing in"
            [state]="
              isSigningInWithMicrosoft
                ? LoadingTenseState.Present
                : LoadingTenseState.Infinitive
            "
            i18n-infinitiveText="
              @@auth-module|sign-in|microsoft-loader-infinitive-text"
            i18n-presentText="@@auth-module|sign-in-loader-present-text"
          >
          </db-loading-tense>
        </db-button>
        <db-button
          *ngIf="emailCheckResult.loginMethods?.saml?.enabled"
          id="saml"
          (click)="onSamlSignIn()"
          [buttonType]="'outlined'"
          [width]="'full-width'"
          [disabled]="
            isSigningInWithGoogle ||
            isSigningInWithMicrosoft ||
            isSigningInWithSAML
          "
        >
          <img src="/assets/icons/sso.svg" alt="SSO" />
          <db-loading-tense
            infinitiveText="Sign in with company account (SSO)"
            presentText="Signing in"
            [state]="
              isSigningInWithSAML
                ? LoadingTenseState.Present
                : LoadingTenseState.Infinitive
            "
            i18n-infinitiveText="
              @@auth-module|sign-in|saml-loader-infinitive-text"
            i18n-presentText="@@auth-module|sign-in-loader-present-text"
          >
          </db-loading-tense>
        </db-button>
        <db-button
          *ngIf="emailCheckResult.loginMethods?.email?.enabled"
          id="email"
          [buttonType]="'outlined'"
          [width]="'full-width'"
          [disabled]="
            isSigningInWithGoogle ||
            isSigningInWithMicrosoft ||
            isSigningInWithSAML
          "
          (click)="onEmailSignIn()"
        >
          <img src="/assets/icons/envelope.svg" alt="Envelope" />
          <span i18n="@@auth-module|sign-in|sign-in-email"
            >Sign in with email</span
          >
        </db-button>
      </ng-container>
      <ng-template #newUser>
        <div class="info" *ngIf="emailCheckResult.extraInformation">
          <img src="/assets/icons/info.svg" alt="I" />
          <p>{{ emailCheckResult.extraInformation }}</p>
        </div>
        <db-button
          *ngIf="emailCheckResult.loginMethods?.google?.enabled"
          id="google"
          [buttonType]="'outlined'"
          [width]="'full-width'"
          (click)="onGoogleSignIn()"
          [disabled]="
            isSigningInWithGoogle ||
            isSigningInWithMicrosoft ||
            isSigningInWithSAML
          "
        >
          <img src="/assets/icons/google-logo.svg" alt="Google Logo" />
          <db-loading-tense
            infinitiveText="Sign up with Google"
            presentText="Signing up"
            [state]="
              isSigningInWithGoogle
                ? LoadingTenseState.Present
                : LoadingTenseState.Infinitive
            "
            i18n-infinitiveText="
              @@auth-module|sign-up|google-loader-infinitive-text"
            i18n-presentText="@@auth-module|sign-up-loader-present-text"
          >
          </db-loading-tense>
        </db-button>
        <db-button
          *ngIf="emailCheckResult.loginMethods?.microsoft?.enabled"
          id="microsoft"
          [buttonType]="'outlined'"
          [width]="'full-width'"
          (click)="onMicrosoftSignIn()"
          [disabled]="
            isSigningInWithGoogle ||
            isSigningInWithMicrosoft ||
            isSigningInWithSAML
          "
        >
          <img
            src="/assets/icons/microsoft-logo-small.svg"
            alt="Microsoft Logo"
          />
          <db-loading-tense
            infinitiveText="Sign up with Microsoft"
            presentText="Signing up"
            [state]="
              isSigningInWithMicrosoft
                ? LoadingTenseState.Present
                : LoadingTenseState.Infinitive
            "
            i18n-infinitiveText="
              @@auth-module|sign-up|microsoft-loader-infinitive-text"
            i18n-presentText="@@auth-module|sign-up-loader-present-text"
          >
          </db-loading-tense>
        </db-button>
        <db-button
          *ngIf="emailCheckResult.loginMethods?.saml?.enabled"
          id="saml"
          [buttonType]="'outlined'"
          [width]="'full-width'"
          (click)="onSamlSignIn()"
          [disabled]="
            isSigningInWithGoogle ||
            isSigningInWithMicrosoft ||
            isSigningInWithSAML
          "
        >
          <img src="/assets/icons/sso.svg" alt="SSO" />
          <db-loading-tense
            infinitiveText="Sign up with company account (SSO)"
            presentText="Signing up"
            [state]="
              isSigningInWithSAML
                ? LoadingTenseState.Present
                : LoadingTenseState.Infinitive
            "
            i18n-infinitiveText="
              @@auth-module|sign-up|saml-loader-infinitive-text"
            i18n-presentText="@@auth-module|sign-up-loader-present-text"
          >
          </db-loading-tense>
        </db-button>
        <db-button
          *ngIf="emailCheckResult.loginMethods?.email?.enabled"
          id="email"
          [buttonType]="'outlined'"
          [width]="'full-width'"
          (click)="onEmailSignUp()"
          [disabled]="
            isSigningInWithGoogle ||
            isSigningInWithMicrosoft ||
            isSigningInWithSAML
          "
        >
          <img src="/assets/icons/envelope.svg" alt="Envelope" />
          <span i18n="@@auth-module|sign-up-email">Sign up with email</span>
        </db-button>
      </ng-template>
      <a
        class="big-link"
        [routerLink]="['/login']"
        queryParamsHandling="preserve"
        i18n="@@auth-module|go-back"
        >Go back</a
      >
    </div>
  </div>
</db-card>
