import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { RouterModel } from 'router-module';

import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthModel } from '../+store/model';

@Injectable({
  providedIn: 'root'
})
export class HasEmailSetGuard  {

  constructor(
    private router: Router,
    private authModel: AuthModel,
    private routerModel: RouterModel
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const isInitialNavigation = !this.router.navigated;
    if (route.data['allowSetEmailFromQuery'] && route.queryParams['email']) { return of(true); }
    return this.authModel.selectors.email$.pipe(
      take(1),
      map((email) => !!email),
      tap(canAccess => {
        if (canAccess || !isInitialNavigation) { return; }
        this.routerModel.actions.dispatch.navigate({ commands: ['login'] });
      })
    );
  }
}
