import { Injectable } from '@angular/core';
import { connectSelectors, connectBundles } from 'ngrx-action-bundles';
import { featureFlagBundles } from './bundles';
import { featureFlagSelectors } from './selectors';
import { Observable, map } from 'rxjs';
import { FeatureFlag } from 'types';
import { FEATURE_FLAG_DEFAULT_VALUE } from '../../constants-feature-flags';

@Injectable({ providedIn: 'root' })
export class FeatureFlagModel {
  selectors = connectSelectors(featureFlagSelectors);
  actions = connectBundles(featureFlagBundles);

  featureEnableBookingForGuests$ = this.getFeatureFlagValue(FeatureFlag.ENABLE_BOOKING_FOR_GUESTS);
  featureEnableSchedulingCalendarSync$ = this.getFeatureFlagValue(FeatureFlag.ENABLE_SCHEDULING_CALENDAR_SYNC);
  featureEnableBookingLimit50$ = this.getFeatureFlagValue(FeatureFlag.ENABLE_BOOKING_LIMIT_50);
  featureEnableDailyOfficeView$ = this.getFeatureFlagValue(FeatureFlag.ENABLE_DAILY_OFFICE_VIEW);
  featureEnableOfficeRoles$ = this.getFeatureFlagValue(FeatureFlag.ENABLE_OFFICE_ROLES);
  featureFlagEnableHybridWorkPoliciesPreview$ = this.getFeatureFlagValue(FeatureFlag.ENABLE_HYBRID_WORK_POLICIES_PREVIEW);
  featureEnableAssistant$ = this.getFeatureFlagValue(FeatureFlag.ENABLE_ASSISTANT);
  featureHideIconsInMenu$ = this.getFeatureFlagValue(FeatureFlag.HIDE_ICONS_IN_MENU);
  featureEnableCateringAndServices$ = this.getFeatureFlagValue(FeatureFlag.ENABLE_CATERING_AND_SERVICES);
  featureEnableOfficeEquipment$ = this.getFeatureFlagValue(FeatureFlag.ENABLE_OFFICE_EQUIPMENT);
  featureEnableFailureOnRecurringMeetingConflict$ = this.getFeatureFlagValue(FeatureFlag.ENABLE_FAIL_ON_RECURRING_MEETING_CONFLICT);
  featureEnableRoleBasedLeadTime$ = this.getFeatureFlagValue(FeatureFlag.ENABLE_ROLE_BASED_LEAD_TIME);
  featureEnableAreaConfigurationV2$ = this.getFeatureFlagValue(FeatureFlag.DEV_AREA_CONFIGURATION_V2);
  featureEnablePublicApiKeys$ = this.getFeatureFlagValue(FeatureFlag.ENABLE_PUBIC_API_KEYS);

  private getFeatureFlagValue(flag: FeatureFlag): Observable<boolean> {
    return this.selectors.featureFlags$.pipe(map((flags) => flags[flag]?.value || FEATURE_FLAG_DEFAULT_VALUE));
  }
}
