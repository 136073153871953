import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs';
import { notificationTypeLocales } from '../constants';
import { NotificationModel } from './model';

@Injectable({
  providedIn: 'root',
})
export class NotificationEffects {
  showToastMessage$ = createEffect(
    () =>
      this.notificationModel.actions.listen.showNotification$.pipe(
        tap(({ data, notificationType }) => {
          this.toastService.add({
            severity: notificationType,
            summary: notificationTypeLocales[notificationType],
            detail: data,
            data,
            life: 5000,
          });
        })
      ),
    { dispatch: false }
  );

  constructor(private notificationModel: NotificationModel, private toastService: MessageService) {}
}
