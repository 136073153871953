export interface ITimeBlocker {
  startTime: string;
  endTime: string;
}

export const isStartInRange = (time: string, blockers: ITimeBlocker[]) =>
  blockers.some(({ startTime, endTime }) => startTime <= time && time < endTime);

export const isEndInRange = (time: string, blockers: ITimeBlocker[]) =>
  blockers.some(({ startTime, endTime }) => startTime < time && time <= endTime);

export const isOverlapping = (sideValues: [string, string], timeBlockers: ITimeBlocker[]) => {
  const [startingValue, endingValue] = sideValues;
  return timeBlockers.some(({ startTime, endTime }) => startingValue < startTime && endTime < endingValue);
};
