import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { FavoriteSpaceActionIconComponent } from 'favorite-spaces-module/favorite-space-action-icon';
import { ClickFavoriteSpaceEntryPoint } from 'shared-enums';
import { HoverDirective } from 'shared-directives';
import { DeskAreaType, IBookingCheckInParams, IBookingViewProps, IUserDedicatedResource, IUserFavoriteResource, TimeFormat } from 'types';
import { AreaRestrictionIconComponent } from './../../area-restriction-icon';
import { BookingStartEndTimeComponent } from './../booking-start-end-time';
import { BookingStatusComponent } from './../booking-status';
import { SkeletonLoaderComponent } from './../../skeleton-loader';
import { SkeletonModule } from 'primeng/skeleton';
import { IBookingViewWidgetProps } from './types';
import { bookingStatusDataTestIdForCheckInCta, favoriteSpaceDataTestId } from './providers';

@Component({
  selector: 'db-booking-card-item',
  templateUrl: './booking-card-item.component.html',
  styleUrls: ['./booking-card-item.component.scss'],
  standalone: true,
  imports: [
    AreaRestrictionIconComponent,
    NgIf,
    FavoriteSpaceActionIconComponent,
    HoverDirective,
    NgClass,
    BookingStartEndTimeComponent,
    BookingStatusComponent,
    SkeletonModule,
    SkeletonLoaderComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCardItemComponent implements OnChanges {
  readonly bookingStatusDataTestIdForCheckInCta = inject(bookingStatusDataTestIdForCheckInCta, {
    optional: true,
  });
  readonly favoriteSpaceDataTestId = inject(favoriteSpaceDataTestId, {
    optional: true,
  });
  readonly deskAreaType = DeskAreaType;
  isHovered = false;
  resourceName = '';
  isFavoriteIconPossible = false;
  bookingProps: IBookingViewWidgetProps | undefined;
  isDedicatedResource = false;

  @Input() set booking(value: IBookingViewWidgetProps | undefined) {
    if (value) {
      this.bookingProps = value;
      this.resourceName = getResourceName(value);
      this.isFavoriteIconPossible = isFavoriteIconPossible(value);
    }
  }

  @Input() authUserTimeFormat: TimeFormat | undefined = TimeFormat.TWENTY_FOUR_HOUR;
  @Input() authUserDedicatedResources: IUserDedicatedResource[] | undefined;
  @Input() authUserFavoriteResources: IUserFavoriteResource[] | undefined = [];
  @Input() authUserId!: string;
  @Input() @HostBinding('class.clickable') isClickable = false;
  @Input() favoriteSpaceGtmEntryPoint!: ClickFavoriteSpaceEntryPoint;
  @Input() isLoading = false;
  /** Control if the resource name, area (and guest name) are displayed on one line. If false, each detail is displayed on separated line */
  @Input() fitDetailsOnOneLine = false;
  @Output() bookingCheckIn = new EventEmitter<IBookingCheckInParams>();

  @HostListener('mouseenter') mouseEnterHandler() {
    this.isHovered = true;
  }

  @HostListener('mouseleave') mouseLeaveHandler() {
    this.isHovered = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['booking']?.currentValue && changes['authUserDedicatedResources']?.currentValue) {
      this.isDedicatedResource = !!isDedicatedResource(this.bookingProps!, this.authUserDedicatedResources);
    }
  }

  checkInHandler() {
    this.bookingCheckIn.emit({
      bookingId: this.bookingProps!.id,
      workspaceId: this.bookingProps!.workspaceId,
      resourceId: this.bookingProps!.resourceId,
      zoneItemId: this.bookingProps!.zoneItemId,
    });
  }
}

const getResourceName = (booking: IBookingViewProps): string => {
  if (booking.resource.type === DeskAreaType.MeetingRoom) {
    return booking.resource.name;
  } else {
    return booking.zoneItemName;
  }
};

const isFavoriteIconPossible = (booking: IBookingViewProps) => !!(booking.zoneItem && booking.resource.type !== DeskAreaType.MeetingRoom);

const isDedicatedResource = (booking: IBookingViewProps, userDedicatedResources: IUserDedicatedResource[] | undefined) =>
  userDedicatedResources?.some((resource) => resource.zoneItemId === booking.zoneItemId);
