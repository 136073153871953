import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { GtmEventDirective, TrimDirective } from '../../directives';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ErrorDictionaryEntry, standardErrorDictionary } from 'types';

@Component({
  selector: 'db-text-input',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    InputNumberModule,
    FormsModule,
    TrimDirective,
    InputTextareaModule,
    GtmEventDirective
  ],
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextInputComponent,
      multi: true,
    },
  ],
})
export class TextInputComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  errorDictionaryList: ErrorDictionaryEntry[] = standardErrorDictionary;
  @ViewChild('inputElement') inputElement!: ElementRef;
  @Input() name = '';
  @Input() set errorListDictionary(dictionary: ErrorDictionaryEntry[]) {
    if (dictionary && dictionary.length > 0) {
      this.errorDictionaryList = [...standardErrorDictionary, ...dictionary];
    }
  }
  @Input() placeholder = '';
  @Input() required?: string;
  @Input() showAsterisk = false;
  @Input() optional = false;
  @Input() label = '';
  @Input() pattern = '';
  @Input() type: 'text' | 'number' | 'textarea' = 'text';
  @Input() hideInternalError = false;
  @Input() disabled = false;
  @Input() dataTestId: string | undefined = undefined;
  
  /** Applicable for input number */
  @Input () suffix: string | undefined = undefined;
  @Input() min = 0;
  @Input() max : number | undefined = undefined;

  @Input() gtmEvent: string | null = null;
  @Input() trackFocus: boolean = false;
  @Input() disableAutocomplete: boolean = false;

  @Output() inputHandler: EventEmitter<string | number | null> = new EventEmitter<string | number | null>();

  value: string | number | null = null;
  inputId = '';

  private onChange!: (value: string | number) => {};
  private onTouch!: () => {};
  private readonly injector = inject(Injector);

  control: FormControl | undefined;

  ngOnInit(): void {
    this.inputId = `input-id-${this.name}`;
  }

  ngAfterViewInit(): void {
    this.control = this.injector.get(NgControl, null, { optional: true })?.control as FormControl;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  valueChangedHandler(value: string | number): void {
    this.inputHandler.emit(value);
    this.value = value;
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.onTouch) {
      this.onTouch();
    }
  }
}
