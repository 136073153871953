export interface IServiceRequest {
	requestedCategories: IRequestedCategory[];
}

export interface IRequestedCategory {
  categoryId: string;
  title?: string;
  requestedItems: IRequestedItem[];
  notes: string | undefined;
}

export interface IRequestedItem {
  itemId: string;
  quantity: number;
  title?: string;
}
