import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  inject,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownModule, Dropdown } from 'primeng/dropdown';
import { EventEmitterValue } from '../../types/event-emitter-value';
import { PrimeTemplate } from 'primeng/api';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';

// If (When) needed uncomment this:
// import { OverlayOptions } from 'primeng/api';
// import { ScrollerOptions } from 'primeng/scroller';

@Component({
  selector: 'db-dropdown',
  standalone: true,
  imports: [NgIf, NgFor, NgTemplateOutlet, DropdownModule, FormsModule],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownComponent,
      multi: true,
    },
  ],
})
export class DropdownComponent<T = any> implements OnInit, ControlValueAccessor {
  @ContentChildren(PrimeTemplate) templates!: QueryList<PrimeTemplate>;
  @ViewChild(Dropdown, { read: Dropdown, static: true }) primeDropdown!: Dropdown;

  @Output() onClick = new EventEmitter<EventEmitterValue<Dropdown['onClick']>>();
  @Output() onChange = new EventEmitter<EventEmitterValue<Dropdown['onChange']>>();
  @Output() onFilter = new EventEmitter<EventEmitterValue<Dropdown['onFilter']>>();
  @Output() onFocus = new EventEmitter<EventEmitterValue<Dropdown['onFocus']>>();
  @Output() onBlur = new EventEmitter<EventEmitterValue<Dropdown['onBlur']>>();
  @Output() onShow = new EventEmitter<EventEmitterValue<Dropdown['onShow']>>();
  @Output() onHide = new EventEmitter<EventEmitterValue<Dropdown['onHide']>>();
  @Output() onClear = new EventEmitter<EventEmitterValue<Dropdown['onClear']>>();
  @Output() onLazyLoad = new EventEmitter<EventEmitterValue<Dropdown['onLazyLoad']>>();

  show!: Dropdown['show'];
  hide!: Dropdown['hide'];

  @Input() selectedValue: any;
  @Input() dataTestId?: string;
  /** Use to provide templates as input instead of ContentChildren.
   * Used from db-dropdown-with-search because templates are projected after the initial rendering of the p-dropdown and as a result the templates are not being used. */
  @Input() templatesAsInput: PrimeTemplate[] | null = null;

  // Used primeng inputs:
  @Input() options?: T[]; // An array of objects to display as the available options.
  @Input() optionLabel?: string; // Name of the label field of an option.
  @Input() optionValue?: string; // Name of the value field of an option.
  @Input() placeholder?: string; // Default text to display when no option is selected.
  @Input() emptyFilterMessage?: string; // Text to display when filtering does not return any results. Defaults to global value in i18n translation configuration.
  @Input() filter?: boolean; // When specified, displays an input field to filter the items on keyup.
  @Input() filterBy?: string; // When filtering is enabled, filterBy decides which field or fields (comma separated) to search against.
  @Input() autoDisplayFirst?: boolean; // Whether to display the first item as the label if no placeholder is defined and value is null.
  @Input() disabled?: boolean; // When present, it specifies that the component should be disabled.
  @Input() virtualScroll?: boolean; // Whether the data should be loaded on demand during scroll.
  @Input() virtualScrollItemSize?: number; // Height of an item in the list for VirtualScrolling.
  @Input() editable?: boolean; // 	When present, custom value instead of predefined options can be entered using the editable input field.
  @Input() readonly?: boolean = false; // When present, it specifies that the component cannot be edited.
  @Input() resetFilterOnHide?: boolean; // Clears the filter value when hiding the dropdown.
  @Input() appendTo?: any; // Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
  @Input() optionDisabled?: string; // Name of the disabled field of an option.
  @Input() styleClass?: string; // 	Style class of the element.
  @Input() panelStyleClass?: string; // Style class of the overlay panel element.
  @Input() group?: boolean; // Whether to display options as grouped when nested options are provided.
  @Input() showClear?: boolean; // When enabled, a clear icon is displayed to clear the value.

  // Unused primeng inputs (you can move to used when needed):
  // @Input() optionGroupLabel?: string; // Name of the label field of an option group.
  // @Input() optionGroupChildren?: string; // Name of the options field of an option group.
  // @Input() name?: string; // 	Name of the input element.
  // @Input() scrollHeight?: string; // Height of the viewport in pixels, a scrollbar is defined if height of list exceeds this value.
  // @Input() style?: string; // Inline style of the element.
  // @Input() panelStyle?: string; // Inline style of the overlay panel element.

  // @Input() filterValue?: string; // 	When specified, filter displays with this value.
  // @Input() filterBy?: string; // When filtering is enabled, filterBy decides which field or fields (comma separated) to search against.
  // @Input() filterMatchMode?: 'contains' | 'startsWith' | "endsWith" | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte'; // Defines how the items are filtered, valid values are "contains" (default)
  // @Input() filterPlaceholder?: string; // Placeholder text to show when filter input is empty.
  // @Input() filterLocale?: string; // Locale to use in filtering. The default locale is the host environment's current locale.
  // @Input() required?: boolean; // When present, it specifies that an input field must be filled out before submitting the form.

  // @Input() emptyMessage?: string; // Text to display when there is no data. Defaults to global value in i18n translation configuration.

  // @Input() ariaLabelledBy?: string; // Establishes relationships between the component and label(s) where its value should be one or more element IDs.
  // @Input() maxlength?: number; // Maximum number of character allows in the editable input field.
  // @Input() tabindex?: number; // Index of the element in tabbing order.

  // @Input() inputId?: string; // Identifier of the accessible input element.
  // @Input() dataKey?: string; // A property to uniquely identify a value in options.
  // @Input() autofocus?: boolean; // When present, it specifies that the component should automatically get focus on load.
  // @Input() autofocusFilter?: boolean; // 	Applies focus to the filter element when the overlay is shown.
  // @Input() dropdownIcon?: string; // Icon class of the dropdown icon.

  // @Input() baseZIndex?: number; // Base zIndex value to use in layering.
  // @Input() autoZIndex?: number; // Whether to automatically manage layering.
  // @Input() showTransitionOptions?: string; // Transition options of the show animation.
  // @Input() hideTransitionOptions?: string; // Transition options of the hide animation.
  // @Input() ariaFilterLabel?: string; // Defines a string that labels the filter input.
  // @Input() ariaLabel?: string; // Used to define a string that autocomplete attribute the current element.
  // @Input() tooltip?: any; // Advisory information to display in a tooltip on hover.
  // @Input() tooltipStyleClass?: string; // Style class of the tooltip.
  // @Input() tooltipPosition?: 'top' | 'bottom' | 'left' | 'right'; // Position of the tooltip
  // @Input() tooltipPositionStyle?: string; // Type of CSS position.
  // @Input() virtualScroll?: boolean; // Whether the data should be loaded on demand during scroll.
  // @Input() virtualScrollItemSize?: number; // Height of an item in the list for VirtualScrolling.
  // @Input() virtualScrollOptions?: ScrollerOptions; // null	Whether to use the scroller feature. The properties of scroller component can be used like an object in it.
  // @Input() overlayOptions?: OverlayOptions; // Whether to use overlay API feature. The properties of overlay API can be used like an object in it.
  // @Input() lazy?: boolean; // Defines if data is loaded and interacted with in lazy manner.

  changeDetectorRef = inject(ChangeDetectorRef);
  touched = false;

  changeFn!: (value: any) => void;
  touchFn!: () => void;

  ngOnInit(): void {
    this.attachHandlers();
  }

  attachHandlers = (): void => {
    this.primeDropdown.onClick.subscribe(this.onClick);
    this.primeDropdown.onChange.subscribe(this.onChange);
    this.primeDropdown.onFilter.subscribe(this.onFilter);
    this.primeDropdown.onFocus.subscribe(this.onFocus);
    this.primeDropdown.onBlur.subscribe(this.onBlur);
    this.primeDropdown.onShow.subscribe(this.onShow);
    this.primeDropdown.onHide.subscribe(this.onHide);
    this.primeDropdown.onClear.subscribe(this.onClear);
    this.primeDropdown.onLazyLoad.subscribe(this.onLazyLoad);

    this.show = this.primeDropdown.show.bind(this.primeDropdown);
    this.hide = this.primeDropdown.hide.bind(this.primeDropdown);
  };

  writeValue = (obj: any): void => {
    this.selectedValue = obj;
    this.changeDetectorRef.detectChanges();
  };

  registerOnChange = (fn: any): void => {
    this.changeFn = fn;
  };

  registerOnTouched = (fn: any): void => {
    this.touchFn = fn;
  };

  setDisabledState = (isDisabled: boolean): void => {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  };

  selectedValueChangeHandler = ($event: any) => {
    this.selectedValue = $event;
    this.changeDetectorRef.detectChanges();

    if (this.changeFn) {
      this.changeFn(this.selectedValue);
    }

    if (!this.touched) {
      if (this.touchFn) {
        this.touchFn();
      }
      this.touched = true;
    }
  };
}
