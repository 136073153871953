import { CdkVirtualScrollViewport, ScrollingModule, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, ContentChild, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicSizeVirtualScrollDirective } from '../../directives';
import { asyncScheduler, BehaviorSubject, Observable, Subject, Subscription, take, takeUntil, withLatestFrom } from 'rxjs';
import { DynamicSizeVirtualScrollStrategy } from '../../utils/dynamic-size-virtual-scroll-strategy';
import moment, { Moment } from 'moment';
import { DATE_SHORT_YEAR_FORMAT } from '../../constants';
import { ScrollableItem } from '../../types/scrollable-item';

@Component({
  selector: 'db-dynamic-size-virtual-scroll',
  templateUrl: './dynamic-size-virtual-scroll.component.html',
  styleUrls: ['./dynamic-size-virtual-scroll.component.scss'],
  standalone: true,
  imports: [CommonModule, ScrollingModule, DynamicSizeVirtualScrollDirective],
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,
      useClass: DynamicSizeVirtualScrollStrategy,
    },
  ],
})
export class DynamicSizeVirtualScrollComponent implements AfterViewInit, OnDestroy {
  @Input() scrollableItems$!: Observable<Array<any & ScrollableItem>>;

  @ContentChild('scrollableItemTemplate') scrollableItemTemplate!: TemplateRef<any>;

  // Event emitter for when the user scrolls to a point where fetching next data is needed
  @Output() onFetchDataInitiated = new EventEmitter<{ fetchFromDate: string }>();

  // Event emitter for every scroll event
  @Output() onScrolledElementChange = new EventEmitter<{ scrollToDate: Moment }>();

  @ViewChild(CdkVirtualScrollViewport) scroller?: CdkVirtualScrollViewport;

  private viScrollSubscriptions = new Subscription();
  private killSubscriptions$$: Subject<void> = new Subject<void>();

  //** Holds the date that we want to fetch data from. On each change, event is emitted to the parent component */
  private fetchDataStartDate$$ = new BehaviorSubject(moment().format(DATE_SHORT_YEAR_FORMAT));

  scrollToDate(date: Moment) {
    this.scrollableItems$.pipe(take(1)).subscribe((days) => {
      const scrollToValue = date.format(DATE_SHORT_YEAR_FORMAT);
      const existingDateIndex = days.findIndex((d) => d.day === scrollToValue);
      if (existingDateIndex >= 0) {
        // if data is loaded, just scroll to it
        this.scroller?.scrollToIndex(existingDateIndex, "smooth");
      } else {
        // if data is not loaded, start loading it and scroll to it (it will be either in loading state (isDummyValue) or already loaded)
        this.fetchDataStartDate$$.next(scrollToValue);
        asyncScheduler.schedule(() => {
          this.scrollToDate(date);
        });
      }
    });
  }

  scrolledIndexChangeHandler(index: number) {
    this.scrollableItems$.pipe(take(1)).subscribe((scrollableItems) => {
      const selectedIndex = index > 0 ? index : 0;
      const scrollableItem = scrollableItems[selectedIndex];
      if (!scrollableItem) {
        return;
      }
      const scrolledDate = scrollableItem.day;
      this.onScrolledElementChange.emit({ scrollToDate: moment(scrolledDate, DATE_SHORT_YEAR_FORMAT) });

      const existingDateIndex = scrollableItems.findIndex((d: ScrollableItem) => d.day === scrolledDate);
      const dataAlreadyLoaded = existingDateIndex >= 0 && !scrollableItems[existingDateIndex].isDummyValue;

      if (dataAlreadyLoaded) {
        // FIXME: changed to 7, but it should be refactored/reconsidered
        if (scrollableItems.length - existingDateIndex <= 7) {
          // if we are close to the end of the list, fetch more data
          const currentDate = moment(scrollableItems[scrollableItems.length - 1].day)
            .add(1, 'day')
            .format(DATE_SHORT_YEAR_FORMAT);
          this.fetchDataStartDate$$.next(currentDate);
        }

        return;
      }

      this.fetchDataStartDate$$.next(scrollableItem.day);
    });
  }

  trackByFn = (index: number, item: any) => item.day;

  ngAfterViewInit(): void {
    this.fetchDataStartDate$$.pipe(takeUntil(this.killSubscriptions$$)).subscribe((currentFetchStartDate) => {
      this.onFetchDataInitiated.emit({ fetchFromDate: currentFetchStartDate });
    });
  }

  ngOnDestroy(): void {
    this.viScrollSubscriptions?.unsubscribe();
    this.killSubscriptions$$.next();
    this.killSubscriptions$$.complete();
  }
}
