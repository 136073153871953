import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { FilterMetadata, SortEvent } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import { Table, TableModule, TableRowReorderEvent, TableRowSelectEvent, TableRowUnSelectEvent, TableService } from 'primeng/table';
import { SkeletonLoaderType, TableColumnType } from 'types';
import { ExecPipe } from 'shared-pipes';
import { SkeletonLoaderComponent } from '../skeleton-loader';
import { TableMultiSelectService } from './table-multi-select.service';
import { IBulkActionColumnConfig, IColumnConfig } from './interfaces/column-config';
import { ButtonIconComponent } from '../button-icon';

@Component({
  selector: 'db-table',
  templateUrl: './table.component.html',
  standalone: true,
  imports: [CommonModule, TableModule, ButtonIconComponent, SkeletonLoaderComponent, SkeletonModule, ExecPipe],
  providers: [TableService],
})
export class TableComponent implements OnInit, OnChanges {
  private readonly tableMultiSelectService = inject(TableMultiSelectService);
  private readonly skeletonLoaderRows = new Array(5).fill({});
  private readonly injector = inject(Injector);
  readonly TableColumnType = TableColumnType;

  currentPageReportLocalized = $localize`:@@common|showing-of-total:Showing {first} to {last} of {totalRecords} entries`;
  selectionCountPluralMap: { [k: string]: string } = {
    '=0': $localize`:@@common|selection-count.zero:0`,
    '=1': $localize`:@@common|selection-count.one:# selected`,
    'few': $localize`:@@common|selection-count.few:# selected`,
    'many': $localize`:@@common|selection-count.many:# selected`,
    'other': $localize`:@@common|selection-count.other:# selected`
  };

  /* deskbird specific */
  @Input() dataTestId?: string;
  @Input() columnSkeletonLoaders: { type: SkeletonLoaderType }[] = [];
  @Input() enableBulkActions: boolean = false;
  @Input() headerColumns: IBulkActionColumnConfig[] = []; // required for bulk actions
  @Input() bulkActionsColumnIndex = 2; // index of the column where the bulk actions will be displayed (the one after the checkbox column)

  /* prime specific */
  @Input() values: any[] = [];
  @Input() columns: IColumnConfig[] = [];
  @Input() globalFilterFields: string[] = [];
  @Input() paginator: boolean = false;
  @Input() totalRecords: number = 0;
  @Input() lazy: boolean = false;
  @Input() alwaysShowPaginator: boolean = false;
  @Input() showCurrentPageReport: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() rows: number = 10;
  @Input() rowsPerPageOptions: any[] = [10, 25, 50];
  @Input() tableStyleClass: string = '';
  @Input() filters: { [s: string]: FilterMetadata | FilterMetadata[] } = {};
  @Input() reorderableColumns!: boolean;
  @Input() scrollable: boolean = false;
  @Input() scrollHeight: string | undefined = undefined;


  get selection(): any[] {
    return this.tableMultiSelectService.selectedItems;
  }

  @ViewChild(Table, { read: Table, static: true }) primeTable!: Table;
  templateInjector!: Injector;

  @ContentChild('headerTemplate')
  headerTemplate?: TemplateRef<any>;

  @ContentChild('bodyTemplate')
  bodyTemplate?: TemplateRef<any>;

  @ContentChild('defaultBodyTemplate')
  defaultBodyTemplate?: TemplateRef<any>;

  @ContentChild('emptyMessageTemplate')
  emptyMessageTemplate?: TemplateRef<any>;

  @ContentChild('loadingTemplate')
  loadingTemplate?: TemplateRef<any>;

  @ViewChild('defaultLoadingTemplate')
  defaultLoadingTemplate!: TemplateRef<any>;

  /* deskbird specific */
  @Output() bulkEdit = new EventEmitter<any[]>();
  @Output() bulkDelete = new EventEmitter<any[]>();

  /* prime specific */
  /** When lazy load is true, use lazyLoadEmitter instead of filterEmitter and customSortEmitter */
  @Output() lazyLoadEmitter = new EventEmitter<any>();
  @Output() filterEmitter = new EventEmitter<any>();
  @Output() customSortEmitter = new EventEmitter<SortEvent>();
  @Output() rowReorder = new EventEmitter<TableRowReorderEvent>();

  sortState: { field: string; order: number } = { field: '', order: 0 };
  rowsValue = this.skeletonLoaderRows;

  sortHandler(event: any): void {
    if (this.sortState.field !== event.field) {
      this.sortState.field = event.field;
      this.sortState.order = event.order;
    }

    if (this.sortState.order === -1) {
      this.primeTable.reset();
    }

    this.sortState.order = event.order;
  }

  getBodyTemplate(): TemplateRef<any> {
    if (this.isLoading) {
      if (this.loadingTemplate) {
        return this.loadingTemplate;
      }
      return this.defaultLoadingTemplate;
    }

    if (this.bodyTemplate) {
      return this.bodyTemplate;
    }

    return this.defaultBodyTemplate!;
  }

  reset(): void {
    this.primeTable.reset();
  }

  resetSelection(): void {
    this.tableMultiSelectService.unselectAll();
  }

  rowSelectionChangeHandler(event: TableRowSelectEvent | TableRowUnSelectEvent, selected: boolean): void {
    event.originalEvent?.preventDefault();
    event.originalEvent?.stopPropagation();
    this.tableMultiSelectService.toggleSelection(event.data, selected);
  }

  selectAllChangeHandler(selectedValues: unknown[]): void {
    // !!! selectedAllChange event is not working in prime version 16, so need to use selectionChange event instead. As selectionChange is triggered also for row select and row unselect, we need to do something only if it's the select/unselect all
    const isSelectAll = selectedValues.length === this.values.length;
    const isUnselectAll = selectedValues.length === 0;

    if (!isSelectAll && !isUnselectAll) {
      return;
    }

    if (isSelectAll) {
      this.tableMultiSelectService.selectAll(this.values);
    } else if (isUnselectAll) {
      this.tableMultiSelectService.unselectAll();
    }
  }

  // Unused primeng inputs (you can move to used when needed):
  // @Input() tableStyle: any;
  // @Input() pageLinks: number = 5;
  // @Input() paginatorPosition: string = 'bottom';
  // @Input() paginatorDropdownAppendTo: any;
  // @Input() paginatorDropdownScrollHeight: string = '200px';
  // @Input() currentPageReportTemplate: string = '{currentPage} of {totalPages}';
  // @Input() showJumpToPageDropdown: boolean;
  // @Input() showJumpToPageInput: boolean;
  // @Input() showFirstLastIcon: boolean = true;
  // @Input() showPageLinks: boolean = true;
  // @Input() defaultSortOrder: number = 1;
  // @Input() defaultSortField: string = '';
  // @Input() customSort: boolean = false;
  // @Input() sortMode: string = 'single';
  // @Input() resetPageOnSort: boolean = true;
  // @Input() selectionMode: string;
  // @Input() selectionPageOnly: boolean;
  // @Input() contextMenuSelection: any;
  // @Input() contextMenuSelectionMode: string = 'separate';
  // @Input() dataKey: string;
  // @Input() metaKeySelection: boolean;
  // @Input() rowSelectable;
  // @Input() rowTrackBy: Function = (index: number, item: any) => item;
  // @Input() lazyLoadOnInit: boolean = true;
  // @Input() compareSelectionBy: string = 'deepEquals';
  // @Input() csvSeparator: string = ',';
  // @Input() exportFilename: string = 'download';
  // @Input() filterDelay: number = 300;
  // @Input() filterLocale: string;
  // @Input() expandedRowKeys: { [s: string]: boolean } = {};
  // @Input() editingRowKeys: { [s: string]: boolean } = {};
  // @Input() rowExpandMode: string = 'multiple';
  // @Input() scrollDirection: string = 'vertical';
  // @Input() rowGroupMode: string;
  // @Input() virtualScroll: boolean;
  // @Input() virtualScrollItemSize: number;
  // @Input() virtualScrollOptions: ScrollerOptions;
  // @Input() virtualScrollDelay: number = 250;
  // @Input() frozenWidth: string;
  // @Input() responsive: boolean;
  // @Input() contextMenu: any;
  // @Input() resizableColumns: boolean;
  // @Input() columnResizeMode: string = 'fit';
  // @Input() loading: boolean;
  // @Input() loadingIcon: string = 'pi pi-spinner';
  // @Input() showLoader: boolean = true;
  // @Input() rowHover: boolean;
  // @Input() customSort: boolean;
  // @Input() showInitialSortBadge: boolean = true;
  // @Input() autoLayout: boolean;
  // @Input() exportFunction;
  // @Input() exportHeader: string;
  // @Input() stateKey: string;
  // @Input() stateStorage: string = 'session';
  // @Input() editMode: string = 'cell';
  // @Input() groupRowsBy: any;
  // @Input() groupRowsByOrder: number = 1;
  // @Input() responsiveLayout: string = 'stack';
  // @Input() breakpoint: string = '960px';

  ngOnInit(): void {
    this.templateInjector = Injector.create({ providers: [{ provide: Table, useValue: this.primeTable }], parent: this.injector });
  }

  ngOnChanges(changes: SimpleChanges): void {
    /** Since the default loading of the prime table is not what we want,
     * we are using a template with skeleton loaders as bodyTemplate (see getBodyTemplate method)
     * but we also need a hard-coded data source so that the template is bound based on those 5 rows */
    if (changes['isLoading']?.currentValue || changes['values']?.currentValue) {
      const isLoading = changes['isLoading'] ? (changes['isLoading'].currentValue as boolean) : this.isLoading;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const values = changes['values'] ? (changes['values'].currentValue as any[]) : this.values;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.rowsValue = Array.isArray(values) || !isLoading ? [...this.values] : this.skeletonLoaderRows;
    }
  }
}
