export interface ICateringCategoryItem {
  id?: string;
  title: string;
}

export interface IMeetingEventCreationCateringCategoryItem extends ICateringCategoryItem {
  id: string;
  count: number;
}

export interface ICateringCategory {
  id: string;
  officeId: string;
  title: string;
  providerEmail: string;
  description?: string;
  items: ICateringCategoryItem[];

  isActive?: boolean; // ! or hidden, don't know. Waiting on BE
}

export type ICateringCategoryDto = Omit<ICateringCategory, 'id'> & { id?: string };

export interface IMeetingEventCreationCateringCategoryData {
  id: string;
  items: IMeetingEventCreationCateringCategoryItem[];
  title?: string;
  note?: string;
}
