import { Inject, Injectable } from '@angular/core';
import {
  ENVIRONMENT,
  Environment,
  HRIS_INTEGRATION_TYPE,
  ICorporateInfo,
  IHrisIntegration,
  getPageRestrictionsPerCompanyId,
  getPageRoleRestrictionsForCompanyId,
} from 'common-module';
import { connectSelectors, connectBundles } from 'ngrx-action-bundles';
import { distinctUntilChanged, filter, map, Observable, withLatestFrom } from 'rxjs';
import { NOT_SET } from '../constants';
import { authBundles } from './bundles';
import { authSelectors } from './selectors';
import { UserRole, IUser } from 'types';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({ providedIn: 'root' })
export class AuthModel {
  selectors = connectSelectors(authSelectors);
  actions = connectBundles(authBundles);

  isAuthenticating$: Observable<boolean>;
  user$: Observable<IUser | null>;
  authUser$: Observable<IUser>;
  corporateInfo$: Observable<ICorporateInfo | null>;
  /** The HRIS integration entity - if the company doesn't have HRIS enabled, it is null */
  hrisIntegration$: Observable<IHrisIntegration | null>;
  isLogged$: Observable<boolean>;
  idToken$: Observable<string | null>;
  userProfileImage$: Observable<string | null>;

  hasPlanningAccess$: Observable<boolean>;
  hasBookingAccess$: Observable<boolean>;

  isUserAdmin$: Observable<boolean>;
  isUserOfficeAdmin$: Observable<boolean>;
  isUserManager$: Observable<boolean>;
  isUserGroupManager$: Observable<boolean>;
  isUserRegularUser$: Observable<boolean>;
  isUserGuest$: Observable<boolean>;
  userRoleCompanyPageRestrictions$: Observable<{ client: string[] | null; admin: string[] | null }>;
  userRoleCompanyPageRestrictionsForAdmin$: Observable<string[] | null>;
  userRoleCompanyPageRestrictionsForClient$: Observable<string[] | null>;

  constructor(@Inject(ENVIRONMENT) private environment: Environment, firebaseAuth: AngularFireAuth) {
    this.idToken$ = firebaseAuth.idToken;

    this.isAuthenticating$ = this.selectors.user$.pipe(map((a) => a === NOT_SET));
    this.user$ = this.selectors.user$.pipe(filter((a): a is IUser | null => a !== NOT_SET));
    this.authUser$ = this.user$.pipe(filter((a): a is IUser => a !== null));
    this.corporateInfo$ = this.selectors.corporateInfo$.pipe(filter((a): a is ICorporateInfo | null => !!a));
    this.isLogged$ = this.user$.pipe(map((u) => !!u));
    this.userProfileImage$ = this.selectors.profileImage$.pipe(
      map((profileImage) => (profileImage ? `${profileImage}?timestamp=${Date.now()}` : null))
    );

    this.hasPlanningAccess$ = this.selectors.corporateInfo$.pipe(
      withLatestFrom(this.selectors.isDeskbirdAdmin$),
      map(([corporateInfo, isDeskbirdAdmin]) => (isDeskbirdAdmin ? true : !!corporateInfo?.allowsScheduling))
    );

    this.hasBookingAccess$ = this.selectors.corporateInfo$.pipe(
      withLatestFrom(this.selectors.isDeskbirdAdmin$),
      map(([corporateInfo, isDeskbirdAdmin]) => (isDeskbirdAdmin ? true : !!corporateInfo?.allowsResourceBooking))
    );

    this.isUserAdmin$ = this.user$.pipe(
      withLatestFrom(this.selectors.isDeskbirdAdmin$),
      map(([u, isDeskbirdAdmin]) => (isDeskbirdAdmin ? true : u?.role === UserRole.ADMIN))
    );

    this.isUserOfficeAdmin$ = this.user$.pipe(
      withLatestFrom(this.selectors.isDeskbirdAdmin$),
      map(([u, isDeskbirdAdmin]) => (isDeskbirdAdmin ? false : u?.role === UserRole.OFFICE_ADMIN))
    );

    this.isUserManager$ = this.user$.pipe(
      withLatestFrom(this.selectors.isDeskbirdAdmin$),
      map(([u, isDeskbirdAdmin]) => (isDeskbirdAdmin ? false : u?.role === UserRole.MANAGER))
    );

    this.isUserGroupManager$ = this.user$.pipe(
      withLatestFrom(this.selectors.isDeskbirdAdmin$),
      map(([u, isDeskbirdAdmin]) => (isDeskbirdAdmin ? false : u?.role === UserRole.GROUP_MANAGER))
    );

    this.isUserRegularUser$ = this.user$.pipe(
      withLatestFrom(this.selectors.isDeskbirdAdmin$),
      map(([u, isDeskbirdAdmin]) => (isDeskbirdAdmin ? false : u?.role === UserRole.USER))
    );

    this.isUserGuest$ = this.user$.pipe(
      withLatestFrom(this.selectors.isDeskbirdAdmin$),
      map(([u, isDeskbirdAdmin]) => (isDeskbirdAdmin ? false : u?.role === UserRole.GUEST))
    );

    this.hrisIntegration$ = this.corporateInfo$.pipe(
      filter(Boolean),
      distinctUntilChanged((a, b) => a?.id === b?.id),
      map((corporateInfo: ICorporateInfo) =>
        corporateInfo.integrations?.length && corporateInfo.integrations.some((x) => x.type === HRIS_INTEGRATION_TYPE)
          ? corporateInfo.integrations.find((x): x is IHrisIntegration => x.type === HRIS_INTEGRATION_TYPE)!
          : null
      )
    );

    this.userRoleCompanyPageRestrictions$ = this.user$
      .pipe(
        filter(Boolean),
        distinctUntilChanged((a, b) => a?.id === b?.id)
      )
      .pipe(
        map((user) => {
          const roleRestrictions = getPageRoleRestrictionsForCompanyId(this.environment, user.companyId);

          const hasRoleConfigured = roleRestrictions && 'roles' in roleRestrictions;
          const hasEmailConfigured = roleRestrictions && 'email' in roleRestrictions;

          const isRoleRestricted = !!(hasRoleConfigured && roleRestrictions.roles!.includes(user.role));
          const isEmailRestricted = !!(hasEmailConfigured && roleRestrictions.email === user.email);

          const isRestricted =
            hasRoleConfigured && hasEmailConfigured
              ? isRoleRestricted && isEmailRestricted
              : hasRoleConfigured
              ? isRoleRestricted
              : hasEmailConfigured
              ? isEmailRestricted
              : false;

          const userRestrictions = isRestricted
            ? { admin: roleRestrictions?.admin || null, client: roleRestrictions?.client || null }
            : { admin: null, client: null };

          const pageRestrictions = getPageRestrictionsPerCompanyId(this.environment, user.companyId);
          if (pageRestrictions) {
            const combinedAdminRestrictions = userRestrictions?.admin?.length
              ? userRestrictions.admin.concat(pageRestrictions.admin || [])
              : pageRestrictions.admin;
            const combinedClientRestrictions = userRestrictions?.client?.length
              ? userRestrictions.client.concat(pageRestrictions.client || [])
              : pageRestrictions.client;
            return {
              admin: combinedAdminRestrictions?.length ? combinedAdminRestrictions : null,
              client: combinedClientRestrictions?.length ? combinedClientRestrictions : null,
            };
          } else {
            return userRestrictions;
          }
        })
      );

    this.userRoleCompanyPageRestrictionsForAdmin$ = this.userRoleCompanyPageRestrictions$.pipe(map((v) => v.admin || null));
    this.userRoleCompanyPageRestrictionsForClient$ = this.userRoleCompanyPageRestrictions$.pipe(map((v) => v.client || null));
  }
}
