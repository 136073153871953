import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { getClientDefaultLoggedNavigationUrl, getAdminDefaultLoggedNavigationUrl, ICorporateInfo } from 'common-module';
import { AuthModel } from 'auth-module';
import { RouterModel } from 'router-module';
import { combineLatest, filter, map, Observable, take } from 'rxjs';
import { IUser } from 'types';


@Injectable({
  providedIn: 'root'
})
export class DefaultComponentActivate  {

  constructor(private routerModel: RouterModel, private authModel: AuthModel, private router: Router) { }

  canActivate(): Observable<UrlTree> {
    return combineLatest([
      this.routerModel.isAdminAppEnv$,
      this.authModel.corporateInfo$.pipe(filter((val): val is ICorporateInfo => !!val)),
      this.authModel.selectors.user$.pipe(filter((user): user is IUser => !!user))
    ]).pipe(
      map(([isAdminAppEnv, corporateInfo, user]) => {
        const url = isAdminAppEnv
          ? getAdminDefaultLoggedNavigationUrl(corporateInfo, user)
          : getClientDefaultLoggedNavigationUrl(corporateInfo);
        return this.router.parseUrl(url)
      }),
      take(1)
    );
  }
}
