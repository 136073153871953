import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { ApplicationModule } from 'common-module';
import { RouterModel } from 'router-module';
import { combineLatest, map, Observable, take } from 'rxjs';
import { AuthModel } from '../+store/model';
import { hasAccessToPageFactory } from 'shared';
import { UserRole } from 'types';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeLoad  {
  constructor(private authModel: AuthModel, private routerModel: RouterModel, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const { data } = route;

    const deskbirdAdminOnly = !!data?.['deskbirdAdminOnly'];
    const userRolesRestrictions: UserRole[] | null = (data?.['userRoles'] as UserRole[]) || null;
    const appModuleRestrictions: ApplicationModule | null = (data?.['appModuleRestrictions'] as ApplicationModule) || null;

    return combineLatest([
      this.authModel.user$,
      this.authModel.corporateInfo$,
      this.authModel.selectors.isDeskbirdAdmin$,
      this.routerModel.isAdminAppEnv$,
      this.authModel.userRoleCompanyPageRestrictions$,
    ]).pipe(
      map(([user, corporateInfo, isDeskbirdAdmin, isAdminAppEnv, userRoleCompanyPageRestrictions]) => {
        if (!user || !corporateInfo) {
          return false;
        }
        if (deskbirdAdminOnly) {
          return isDeskbirdAdmin;
        }

        if (isDeskbirdAdmin) {
          return true;
        }

        const userRoleCompanyPageRestrictionsForEnv =
          (isAdminAppEnv ? userRoleCompanyPageRestrictions.admin : userRoleCompanyPageRestrictions.client) || null;

        const hasUserRoleCompanyPageRestriction = !(userRoleCompanyPageRestrictionsForEnv
          ? hasAccessToPageFactory(userRoleCompanyPageRestrictionsForEnv)(segments.map((s) => s.path).join('/'))
          : true);

        let canContinue = false;
        if (!appModuleRestrictions && !userRolesRestrictions && !hasUserRoleCompanyPageRestriction) {
          return true;
        }

        if (!hasUserRoleCompanyPageRestriction && appModuleRestrictions && corporateInfo[appModuleRestrictions]) {
          canContinue = true;
        }
        if (!hasUserRoleCompanyPageRestriction && userRolesRestrictions && userRolesRestrictions.includes(user.role)) {
          canContinue = true;
        }

        if (!canContinue) {
          return this.router.navigated
            ? false
            : this.router.parseUrl(
                isAdminAppEnv ? `${this.routerModel.adminPartPrefix}default` : `${this.routerModel.clientPartPrefix}default`
              );
        }
        return true;
      }),
      take(1)
    );
  }
}
