<div
  id="container"
  [ngClass]="{ 'no-styles': noContainerStyles }"
  [style.width]="width"
  [style.height]="height"
>
  <div *ngIf="showCloseButton" class="button-close-container">
    <db-button-icon
      icon="pi pi-times"
      size="small"
      [attr.data-testid]="dataTestIdCloseButton"
      (click)="closeDialog.emit()"
    >
    </db-button-icon>
  </div>
  <ng-content></ng-content>
</div>
