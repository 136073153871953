export const NOT_SET = Symbol('NOT_SET');
const ACCESS = Symbol('ACCESS');

export function getAccessSymbol() {
  return ACCESS;
}

export const AUTH_STORE_NAME = 'auth';

export const accessExceptions = [
  { userId: 'J3Y2UJkfVCSFNx0R5KRRVPhJJs92', urlRegex: /floorplan/ },
  { userId: 'qL875VG1GZVgS74DAnHTkSqgCsm2', urlRegex: /floorplan/ },
  { userId: 'vDniSBi5PHTRv7Szhr90yx7mvsv2', urlRegex: /floorplan/ },
  { userId: 'sbL6yllMtGS0Ck6NNFGRJvjw4963', urlRegex: /floorplan/ },
  { userId: 'JtmHwwBvsOQqnKrkfQOLWtvgHkV2', urlRegex: /floorplan/ },
];
