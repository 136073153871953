import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";
import { RouterModel } from "router-module";
import { combineLatest, map, Observable, take, withLatestFrom } from "rxjs";
import { AuthModel } from "../+store/model";

@Injectable({
  providedIn: 'root'
})
export class AuthenticateActivate  {

  constructor(private authModel: AuthModel, private routerModel: RouterModel, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const { data } = route;
    const { navigated } = this.router;

    const isPublicOnlyRoute = !!data?.["publicOnly"];

    return combineLatest([this.authModel.isLogged$, this.routerModel.isAdminAppEnv$]).pipe(
      take(1),
      withLatestFrom(this.routerModel.selectors.url$),
      map(([[isLogged, isAdminAppEnv], url]) => {
        if (isPublicOnlyRoute) {
          if (navigated) { return isLogged === false; }
          return isLogged === false ? true : this.router.parseUrl(
            `${isAdminAppEnv ? this.routerModel.adminPartPrefix : this.routerModel.clientPartPrefix}default`
          );
        }
        if (navigated) { return isLogged === true; }
        return isLogged === true ? true : this.router.parseUrl(`/login?redirectUrl=${url}`);
      }),
    );
  }

}