import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { map, mergeMap, startWith, catchError, takeUntil, delay, first, filter, take } from 'rxjs/operators';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { UploadModel } from './model';
import { UploadService } from '../upload.service';
import { SentryService } from 'common-module';
import { Range } from 'shared';

@Injectable({
  providedIn: 'root',
})
export class UploadEffects {
  upload$ = createEffect(() =>
    this.uploadModel.actions.listen.upload$.pipe(
      mergeMap(({ files, location, additionalData, uuid, formDataName, requestMethod, csrf, timestamp }) => {
        return this.uploadService.uploadFiles(files, formDataName, location, additionalData, csrf, requestMethod).pipe(
          takeUntil(
            this.uploadModel.actions.listen.uploadCancel$.pipe(
              filter((payload) => payload.uuid === uuid),
              take(1)
            )
          ),
          mergeMap((event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              const progress = Math.round((100 * event.loaded) / event.total) as Range<0, 101>;
              if (progress === 100) {
                return [];
              }
              return [this.uploadModel.actions.create.uploadProgress({ progress, uuid })];
            }

            if (!(event instanceof HttpResponse)) {
              return [];
            }
            const { success } = event.body;
            if (!success) {
              return [this.uploadModel.actions.create.uploadFailure({ uuid, body: event.body, timestamp })];
            }
            return [
              this.uploadModel.actions.create.uploadProgress({ progress: 100, uuid }),
              this.uploadModel.actions.create.uploadSuccess({ uuid, body: event.body, timestamp }),
            ];
          }),
          catchError((error) => {
            this.sentryErrorHandler.handleError(error);
            return [this.uploadModel.actions.create.uploadFailure({ uuid, error, timestamp })];
          }),
          startWith(this.uploadModel.actions.create.uploadProgress({ progress: 0, uuid }))
        );
      })
    )
  );

  notifySuccessfulUpload$ = createEffect(() =>
    this.uploadModel.actions.listen.uploadSuccess$.pipe(
      delay(2000),
      mergeMap(({ timestamp }) =>
        this.uploadModel.selectors.successfulUploads$.pipe(
          take(1),
          map((uploads) => uploads[0]),
          map((uuid) => this.uploadModel.actions.create.uploadCleanup({ uuid, timestamp }))
        )
      )
    )
  );

  notifyFailedUpload$ = createEffect(() =>
    this.uploadModel.actions.listen.uploadFailure$.pipe(
      delay(2000),
      mergeMap(({ timestamp }) =>
        this.uploadModel.selectors.failedUploads$.pipe(
          take(1),
          map((uploads) => uploads[0]),
          map((uuid) => this.uploadModel.actions.create.uploadCleanup({ uuid, timestamp }))
        )
      )
    )
  );

  constructor(private uploadModel: UploadModel, private uploadService: UploadService, private sentryErrorHandler: SentryService) {}
}
