import { Component, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { RouterModel } from 'router-module';
import { delay, interval, map, race, startWith, take } from 'rxjs';
import { AuthModel } from '../+store/model';
import { Lottie } from 'lottie-module';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnDestroy {

  emailVerified = false;
  emailCheckTimeout: any = null;
  Lottie = Lottie;

  showLottie$ = interval(0).pipe(take(1), map(() => true), startWith(false));

  constructor(
    private authModel: AuthModel,
    private routerModel: RouterModel,
    private fireAuthService: AngularFireAuth
  ) {
    this.scheduleCheckIfVerified();
  }

  resendEmail(): void {
    this.authModel.actions.dispatch.resendVerificationEmail();
  }

  scheduleCheckIfVerified(): void {
    this.emailCheckTimeout = setTimeout(() => {
      this.fireAuthService.currentUser
        .then(fireUser => fireUser?.reload())
        .then(() => this.fireAuthService.currentUser)
        .then(fireUser => {
          if (fireUser?.emailVerified) {
            this.authModel.actions.dispatch.signInAfterConfirmation();
            race(
              this.authModel.actions.listen.loadAuthDataSuccess$,
              this.authModel.actions.listen.loadAuthDataFailure$.pipe(map(() => null))
            ).pipe(take(1), delay(0)).subscribe(data => {
              if (!data) { return; }
              this.emailVerified = true;
            });
            return;
          }
          this.emailVerified = false;
          this.scheduleCheckIfVerified();
        });
    }, 5000);
  }

  goToDeskbirdWebApp(): void {
    this.routerModel.actions.dispatch.navigate({ commands: ['/default'], extras: { queryParamsHandling: 'preserve' } });
  }

  goBackToLogin(): void {
    this.authModel.actions.dispatch.logout();
    this.routerModel.actions.dispatch.navigate({ commands: ['/login'], extras: { queryParamsHandling: 'preserve' } });
  }

  ngOnDestroy(): void {
    if (!this.emailCheckTimeout) { return; }
    clearTimeout(this.emailCheckTimeout);
  }

}
