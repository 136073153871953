import { Component, Input } from '@angular/core';

@Component({
  selector: 'db-info-label',
  templateUrl: './info-label.component.html',
  styleUrls: ['./info-label.component.scss'],
  standalone: true
})
export class InfoLabelComponent {
  @Input() infoText!: string;
}
