<span class="p-input-icon-left">
  <i class="pi pi-search"></i>
  <input type="text" pInputText [placeholder]="placeholderText" (focus)="setListVisibility(true)"
    (keyup)="handleSearch($event)" />
</span>
<div id="input-with-autocomplete-overlay" *ngIf="isListVisible" (click)="clickOutsideListHandler()"></div>

<p-virtualScroller id="input-with-autocomplete-virtualscroller"
  *ngIf="!isLoading && isListVisible && itemsList && itemsList.length > 0 && scrollHeight" [value]="itemsList"
  [scrollHeight]="scrollHeight" [itemSize]="itemHeight">
  <ng-template pTemplate="item" let-item>
    <ng-container *ngTemplateOutlet="itemTemplateRef; context: {$implicit: item}"></ng-container>
  </ng-template>
</p-virtualScroller>

<ng-container *ngIf="isLoading && isListVisible">
  <ng-container *ngTemplateOutlet="loadingTemplateRef"></ng-container>
</ng-container>

<ng-container *ngIf="!isLoading && (!itemsList || itemsList.length === 0) && isListVisible">
  <ng-container *ngTemplateOutlet="noDataTemplateRef"></ng-container>
</ng-container>
