import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormatDateTimePipe, TimeZoneAbbreviationPipe } from '../../pipes';
import { TimeFormatByUserPipe } from '../../pipes/time-format-by-user.pipe';
import { DEFAULT_TIMEZONE, TIME_FORMAT } from '../../constants';
import { IUser } from 'types';

@Component({
  selector: 'db-start-end-time',
  templateUrl: './start-end-time.component.html',
  styleUrls: ['./start-end-time.component.scss'],
  standalone: true,
  imports: [CommonModule, TimeFormatByUserPipe, FormatDateTimePipe, TimeZoneAbbreviationPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartEndTimeComponent {
  readonly timeFormat = TIME_FORMAT;

  @Input() startTime!: number | string;
  @Input() endTime!: number | string;
  @Input() officeTimeZone: string | undefined;
  @Input() authUser!: IUser;

  get timeZone(): string {
    return this.officeTimeZone || DEFAULT_TIMEZONE;
  }
}
