import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { SentryTransactionInterceptor } from '../interceptors/sentry-transaction.interceptor';
import { TraceService } from '@sentry/angular-ivy';
import { Router } from '@angular/router';

export const sentryTransactionProviders: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SentryTransactionInterceptor,
    multi: true,
  },
  {
    provide: TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [TraceService],
    multi: true,
  },
];
