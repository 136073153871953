import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { InputTextModule } from "primeng/inputtext";
import { CommonModule } from "@angular/common";
import { VirtualScrollerModule } from "primeng/virtualscroller";

@Component({
  selector: 'db-input-with-autocomplete',
  templateUrl: './input-with-autocomplete.component.html',
  styleUrls: ['./input-with-autocomplete.component.scss'],
  standalone: true,
  imports: [CommonModule, InputTextModule, VirtualScrollerModule]
})
export class InputWithAutocompleteComponent implements DoCheck {
  isListVisible = false;
  scrollHeight: string | null = null;

  @Input() itemsList: any[] | null = [];
  @Input() placeholderText = '';
  @Input() isLoading = false;
  @Input() itemHeight!: number;
  @Input() itemsVisible = 5;
  @Input() itemTemplateRef!: TemplateRef<any>;
  @Input() loadingTemplateRef!: TemplateRef<any>;
  @Input() noDataTemplateRef!: TemplateRef<any>;
  @Output() search = new EventEmitter<string>();

  clickOutsideListHandler(): void {
    this.isListVisible = false;
  }

  setListVisibility = (isVisible: boolean) => { this.isListVisible = isVisible; }

  ngDoCheck(): void {
    const visibleItems = Array.isArray(this.itemsList) && this.itemsList.length < this.itemsVisible ? this.itemsList.length : this.itemsVisible;
    this.scrollHeight = `${this.itemHeight * visibleItems}px`;
  }

  handleSearch(event: KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value;
    this.search.emit(value)
  }
}
