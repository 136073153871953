import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { ICorporateInfo } from 'common-module';
import { RouterModel } from 'router-module';

import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthModel } from '../+store/model';

@Injectable({
  providedIn: 'root'
})
export class NoAccessActivate  {

  constructor(
    private router: Router,
    private authModel: AuthModel,
    private routerModel: RouterModel
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.authModel.isLogged$.pipe(
      withLatestFrom(this.routerModel.selectors.url$),
      tap(([isLogged, url]) => {
        if (isLogged) { return; }
        this.routerModel.actions.dispatch.navigate({ commands: ['/login'], extras: { queryParams: { 'redirectUrl': url }, queryParamsHandling: 'merge' } });
      }),
      filter(val => !!val),
      switchMap(() => this.authModel.selectors.corporateInfo$),
      filter((val): val is ICorporateInfo => !!val),
      take(1),
      map(c => c.allowsScheduling === false && c.allowsResourceBooking === false),
      map(noAccess => {
        if (noAccess) { return noAccess; }
        return this.router.parseUrl('/default')
      })
    );
  }
}
