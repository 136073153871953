import { Component } from '@angular/core';
import { RouterModel } from 'router-module';
import { AuthModel } from '../+store/model';

@Component({
  selector: 'db-not-active-user',
  templateUrl: './not-active-user.component.html',
  styleUrls: ['./not-active-user.component.scss']
})
export class NotActiveUserComponent {

  emailCheckResult$ = this.authModel.selectors.emailCheckResult$;

  constructor(private authModel: AuthModel, private routerModel: RouterModel) { }

  visitDeskbirdWebsiteClickHandler(): void {
    this.routerModel.actions.dispatch.navigateByUrl({ url: 'http://deskbird.com' });
  }
}
