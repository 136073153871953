<form #form="ngForm" autocomplete="off">
  <h2>{{ title }}</h2>

  <input
    name="firstName"
    pInputText
    required
    placeholder="First name"
    [ngModel]="firstName"
    i18n-placeholder="@@common|first-name"
  />

  <input
    name="lastName"
    pInputText
    required
    placeholder="Last name"
    [ngModel]="lastName"
    i18n-placeholder="@@common|last-name"
  />

  <db-button
    width="full-width"
    [disabled]="!!form.invalid"
    (click)="setName.emit(form.value)"
    i18n="@@common|confirm"
  >
    Confirm
  </db-button>
</form>
