export enum FeatureFlag {
  ENABLE_BOOKING_FOR_GUESTS = 'DEV_ENABLE_BOOKING_FOR_GUESTS',
  ENABLE_SCHEDULING_CALENDAR_SYNC = 'ENABLE_SCHEDULING_CALENDAR_SYNC',
  ENABLE_BOOKING_LIMIT_50 = 'DEV_ENABLE_BOOKING_LIMIT_50',
  ENABLE_DAILY_OFFICE_VIEW = 'DEV_ENABLE_DAILY_OFFICE_VIEW',
  ENABLE_OFFICE_ROLES = 'DEV_ENABLE_OFFICE_ROLES',
  ENABLE_HYBRID_WORK_POLICIES_PREVIEW = 'ENABLE_HYBRID_WORK_POLICIES_PREVIEW',
  ENABLE_ASSISTANT = 'DEV_ENABLE_ASSISTANT',
  HIDE_ICONS_IN_MENU = 'HIDE_ICONS_IN_MENU',
  ENABLE_CATERING_AND_SERVICES = 'ENABLE_CATERING_AND_SERVICES',
  ENABLE_OFFICE_EQUIPMENT = 'ENABLE_OFFICE_EQUIPMENT',
  ENABLE_FAIL_ON_RECURRING_MEETING_CONFLICT = 'ENABLE_FAIL_ON_RECURRING_MEETING_CONFLICT',
  ENABLE_PUBIC_API_KEYS = 'ENABLE_PUBIC_API_KEYS',
  ENABLE_ROLE_BASED_LEAD_TIME = 'DEV_ENABLE_ROLE_BASED_LEAD_TIME',
  DEV_AREA_CONFIGURATION_V2 = 'DEV_AREA_CONFIGURATION_V2',
}
