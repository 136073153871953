import { IUserAccess } from "./user-access";

export interface IUserGroup {
  id: string;
  companyId: string;
  name: string;
  userIds: string[];
  parentId: string;
  subChildrenIds: string[];
  groupManagers: IUserAccess[];

  provider?: string;
  children?: IUserGroup[];
  lastSynced?: number;
  // dev
  isNew?: boolean;
  _parent?: IUserGroup;
}
