import { DeskAreaType, ProviderType, SyncStatus } from '../enums';
import { IAvailability } from './availability';
import { IDeskAreaItem } from './desk-area-item';
import { IAccessRules } from './access-rules';
import { IEquipmentDto } from './meeting-room-calendar-data';

export interface IDeskAreaGroup {
  name: string;
  order: number;
  id?: string;
  image?: string;
  floorConfigReady?: boolean;
  interactiveImage?: string;
}

export interface IMeetingRoomSyncStatus {
  provider: ProviderType;
  status: SyncStatus;
}

export interface IDeskArea {
  type: DeskAreaType;
  group: IDeskAreaGroup;
  isActive: boolean;
  image?: string;
  name: string;
  id?: string;
  description: string;
  isBookable: boolean;
  mapImage: string;
  availability?: IAvailability;

  accessRules?: IAccessRules;

  capacity: number;
  workspaceId: string;
  resourceId?: string;
  services: any[];
  amenities: any[];
  pictures: any[];
  images?: string[];
  order: number;

  minimumDuration: number;
  instantBooking: boolean;
  isAvailabilityKnown: boolean;
  maximumReservationPeriod: number;
  minimumReservationPeriod: number;
  cancellationPeriod: number;
  items: IDeskAreaItem[];

  isRestricted?: boolean;
  isHidden?: boolean;
  isTimeRestricted?: boolean;
  restrictedTime?: number;

  usersWithAccess?: { id: string; canDoBooking: boolean; isUserGroup: boolean }[];

  externalData?: {
    provider: string;
    resourceId: string;
    resourceEmail: string;
  };

  syncStatus?: IMeetingRoomSyncStatus;
  equipmentIds?: IEquipmentDto['id'][];

  isCateringAndServicesEnabled?: boolean;
}

export interface IDeskAreaForBookingSummary {
  id: string;
  name: string;
  description: string;
  type: DeskAreaType;
  /**Office id */
  workspaceId: string;
  accessRules: IAccessRules;
  externalData?: { provider: string };

  /**Floor*/
  group: { name: string };
}
