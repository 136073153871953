import { StaticProvider, enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { isLocalEnvironment, isProductionEnvironment, isStagingEnvironment } from 'common-module';
import { bootstrapWithLanguage } from 'internalization-module';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LAZY_MODULES } from './app/constants';
import { routes } from './app/app-routing.module';

if (!isLocalEnvironment(environment.appURL)) {
  enableProdMode();
}

if (isProductionEnvironment(environment.env) || isStagingEnvironment(environment.env)) {
  const isProd = isProductionEnvironment(environment.env);

  Sentry.init({
    dsn: 'https://a78fdb4fea9b4f6ba0c6ccc180f9608d@o961754.ingest.sentry.io/5910142',
    environment: isProd ? 'production' : 'staging',
    integrations: [
      Sentry.browserTracingIntegration({
        tracingOrigins: [environment.apiURL, environment.appURL],
      }),
    ],
    tracesSampler: ({ parentSampled, transactionContext, location }) => {
      if (parentSampled || transactionContext.parentSampled || location?.search?.includes('sentry_sample_rate=1')) {
        return 1;
      }

      for (const [path, dynamicSampleRate] of Object.entries(environment.sentryDynamicSampleRate)) {
        if (location?.pathname?.startsWith(path)) {
          return dynamicSampleRate;
        }
      }

      return environment.sentrySampleRate;
    },
    release: environment.sentryRelease,
  });
}

const searchParams = new URLSearchParams(window.location.search);
const langQueryParam = searchParams.get('lang') || undefined;
if (langQueryParam) {
  searchParams.delete('lang');
  const search = searchParams.toString();
  window.history.replaceState(
    window.history.state,
    window.document.title,
    window.location.pathname + (search.length > 0 ? '?' + search : '')
  );
}

Promise.all([import('user-profile-module'), import('profile-module'), import('floor-plan-module')])
  .then(([userProfileModule, profileModule, floorPlanModule]) => {
    routes.unshift(...userProfileModule.routes, ...profileModule.routes, ...floorPlanModule.routes);
    const extraProviders: StaticProvider[] = [
      {
        provide: LAZY_MODULES,
        useValue: [
          { component: profileModule.ProfileComponent, module: profileModule.ProfileModule },
          { component: userProfileModule.UserProfileWrapperComponent, module: userProfileModule.UserProfileModule },
          { component: floorPlanModule.FloorPlanOutletComponent, module: floorPlanModule.FloorPlanWithProvidersModule },
        ],
      },
    ];
    bootstrapWithLanguage(AppModule, langQueryParam, extraProviders);
  })
  .catch(console.error);
