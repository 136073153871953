import { Component, Inject, OnInit } from '@angular/core';
import { WINDOW, isStagingEnvironment } from 'common-module';
import { WindowService } from 'global-module';
import { RouterModel } from 'router-module';
import { environment } from '../../environments/environment';
import { animationFrameScheduler, debounceTime, filter, map, observeOn, switchMap, take } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { TeamsService } from 'auth-module';

const productionLink = 'https://deskbird.page.link/app-download';
const stagingLink = 'https://deskbirdstaging.page.link/app-download';

@Component({
  selector: 'db-mobile-blocker',
  templateUrl: './mobile-blocker.component.html',
  styleUrls: ['./mobile-blocker.component.scss'],
})
export class MobileBlockerComponent implements OnInit {
  showMobileBlocker = false;
  isIos = false;
  blockerHidden = false;

  isTeamsAppInitialized$ = this.teamsService.initialized$;

  constructor(
    @Inject(WINDOW) private window: Window, 
    private windowService: WindowService, 
    private routerModel: RouterModel,
    private router: Router,
    private teamsService: TeamsService
    ) {}

  ngOnInit(): void {
    this.router.events.pipe(
      observeOn(animationFrameScheduler),
      filter((val) => val instanceof NavigationEnd),
      debounceTime(0),
      take(1),
      switchMap(() => this.routerModel.selectors.url$),
      map((url) => !!url.includes('saml'))
    ).subscribe((ignore) => {
        const { navigator } = this.windowService.getWindow();
        this.showMobileBlocker = !ignore && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      });
  }

  openApp(): void {
    const url = isStagingEnvironment(environment.env) ? stagingLink : productionLink;

    this.window.open(url);
  }
}
