import { Component } from '@angular/core';
import { RouterModel } from 'router-module';
import { AuthModel } from '../+store/model';
import { asyncScheduler, combineLatest, filter, map, observeOn, race, switchMap, take, withLatestFrom } from 'rxjs';
import { IEmailCheck } from 'common-module';
import { TeamsService } from '../services/teams.service';
import { NotificationModel, NotificationType } from 'notification-module';
import { LoadingTenseState } from 'shared';
import { IUser } from 'types';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {

  isTeamsAppInitialized$ = this.teamsService.initialized$;
  emailCheckResult$ = combineLatest([
    this.authModel.selectors.emailCheckResult$.pipe(filter(d => !!d)),
    this.isTeamsAppInitialized$
  ]).pipe(map(([emailCheckResult, isTeamsAppInitialized]) => {
    if (!isTeamsAppInitialized) { return emailCheckResult; }
    return {
      ...emailCheckResult,
      loginMethods: {
        ...emailCheckResult?.loginMethods,
        google: { enabled: false },
      }
    }
  }));

  LoadingTenseState = LoadingTenseState;

  isSigningInWithGoogle = false;
  isSigningInWithMicrosoft = false;
  isSigningInWithSAML = false;

  constructor(
    private authModel: AuthModel,
    private routerModel: RouterModel,
    private teamsService: TeamsService,
    private notificationModel: NotificationModel
  ) { }

  onGoogleSignIn(): void {

    this.isSigningInWithGoogle = true;
    this.authModel.actions.dispatch.googleSignIn();

    return void race(
      this.authModel.actions.listen.googleSignInSuccess$.pipe(
        map((payload) => ({ user: payload.user, error: null }))
      ),
      this.authModel.actions.listen.googleSignInFailure$.pipe(
        map((payload) => ({ user: null, error: payload.error }))
      )
    ).subscribe(({ user, error }) => {
      this.isSigningInWithGoogle = false;
      if (error) {
        this.authModel.actions.dispatch.logout();
        return;
      }

      if (user && user!.signup) {
        this.routerModel.actions.dispatch.navigate({
          commands: ['/login', 'sign-up-wizard'],
          extras: { queryParams: { page: 'upload-avatar' }, queryParamsHandling: 'merge' }
        });
        return;
      }


      this.authModel.actions.dispatch.checkEmailCleanup();
      this.routerModel.actions.dispatch.navigate({ commands: ['/default'], extras: { queryParamsHandling: 'preserve' } });
    });
  }

  onMicrosoftSignIn(): void {
    this.isSigningInWithMicrosoft = true;

    this.teamsService.initialized$.pipe(
      observeOn(asyncScheduler),
      take(1),
      withLatestFrom(this.emailCheckResult$),
      switchMap(([isTeamsInitialized, emailCheckResult]) => {
        if (isTeamsInitialized) {
          this.authModel.actions.dispatch.microsoftTeamsSignIn({ email: emailCheckResult?.email });
          return race(
            this.authModel.actions.listen.microsoftTeamsSignInSuccess$.pipe(
              map((payload) => ({ user: payload.user, error: null }))
            ),
            this.authModel.actions.listen.microsoftTeamsSignInFailure$.pipe(
              map((payload) => ({ user: null, error: payload.error }))
            )
          ).pipe(take(1));
        } else {
          this.authModel.actions.dispatch.microsoftSignIn();
          return race(
            this.authModel.actions.listen.microsoftSignInSuccess$.pipe(
              map((payload) => ({ user: payload.user, error: null }))
            ),
            this.authModel.actions.listen.microsoftSignInFailure$.pipe(
              map((payload) => ({ user: null, error: payload.error }))
            )
          ).pipe(take(1));
        }
      }),
      switchMap(({ user, error }) => {
        if (error || !user) {
          if (error?.code === "auth/popup-blocked") {
            this.notificationModel.actions.create.showNotification({
              data: $localize`:@@auth-module|error-login-popup-blocked:Popup blocked`,
              notificationType: NotificationType.ERROR
            });
          }
          return [{ user: null, error: true }];
        }

        return [{ user, error: null }]
      })
    ).subscribe(({ user, error }) => {
      this.isSigningInWithMicrosoft = false;

      if (error) {
        this.authModel.actions.dispatch.logout();
        return;
      }

      if (user && user.signup) {
        this.routerModel.actions.dispatch.navigate({
          commands: ['/login', 'sign-up-wizard'],
          extras: { queryParams: { page: 'upload-avatar' }, queryParamsHandling: 'merge' }
        });
        return;
      }

      this.authModel.actions.dispatch.checkEmailCleanup();
      this.routerModel.actions.dispatch.navigate({ commands: ['/default'], extras: { queryParamsHandling: 'preserve' } });

    });

  }

  onSamlSignIn(): void {
    this.isSigningInWithSAML = true;

    this.emailCheckResult$.pipe(
      filter((val): val is IEmailCheck => !!val),
      filter(result => !!(result?.loginMethods?.saml?.providerId)),
      take(1),
      map(result => result?.loginMethods?.saml?.providerId as string),
      switchMap(provider => {
        if (!provider) {
          this.notificationModel.actions.create.showNotification({
            data: $localize`:@@auth-module|error-no-saml-provider:No SAML provider.`,
            notificationType: NotificationType.ERROR
          });
          return [];
        }

        this.authModel.actions.dispatch.samlSignIn({ provider });
        return race(
          this.authModel.actions.listen.samlSignInSuccess$.pipe(
            map((payload) => ({ user: payload.user, error: null }))
          ),
          this.authModel.actions.listen.samlSignInFailure$.pipe(
            map((payload) => ({ user: null, error: payload.error }))
          )
        ).pipe(withLatestFrom(this.authModel.selectors.isNewUser$), take(1))
      })
    ).subscribe(([{ user, error }, isNewUser]) => {
      this.isSigningInWithSAML = false;
      if (error || !user) {
        this.notificationModel.actions.create.showNotification({
          data: $localize`:@@auth-module|error-login-failed:Login failed`,
          notificationType: NotificationType.ERROR
        });
        this.authModel.actions.dispatch.logout();
      }
      this.handleSignInResult(user, !!isNewUser)
    });

  }

  onEmailSignIn(): void {
    this.routerModel.actions.dispatch.navigate({ commands: ['login', 'sign-in-email'], extras: { queryParamsHandling: 'preserve' } });
  }

  onEmailSignUp(): void {
    this.routerModel.actions.dispatch.navigate({
      commands: ['login', 'sign-up-wizard'],
      extras: { queryParams: { page: 'sign-up' }, queryParamsHandling: 'merge' }
    });
  }

  handleSignInResult = (user: IUser | null, isNewUser: boolean): void => {
    if (user) {
      if (isNewUser || user.signup) {
        return void this.routerModel.actions.dispatch.navigate({
          commands: ['/login', 'sign-up-wizard'],
          extras: { queryParams: { page: 'upload-avatar' }, queryParamsHandling: 'merge' }
        });
      }

      this.authModel.actions.dispatch.checkEmailCleanup();
      this.routerModel.actions.dispatch.navigate({ commands: ['/default'], extras: { queryParamsHandling: 'preserve' } });
    }
  }

}
