import { AuthCredential } from 'firebase/auth';
import { forNamespace } from 'ngrx-action-bundles';
import { props } from '@ngrx/store';
import { ICompany, ICorporateInfo, IDemoCompany, IEmailCheck } from 'common-module';
import { HttpErrorResponse } from '@angular/common/http';
import { ISlackDataPayload } from '../interfaces-and-types';
import { IUser, IUserFavoriteResource, TimeFormat } from 'types';

export const factory = forNamespace('[AUTH]');

export const authenticateBundle = factory.singleAction('authenticate', props<{ initialUrl?: string }>());
export const setUserBundle = factory.singleAction(
  'setUser',
  props<{ user: IUser | null; isNewUser?: boolean; isDeskbirdAdmin?: boolean }>()
);
export const loginAfterSignUpBundle = factory.singleAction('loginAfterSignUp');
export const setEmailBundle = factory.singleActionWithCleanup('setEmail', props<{ email: string }>());
export const setSAMLUserNameBundle = factory.singleActionWithCleanup(
  'setSamlUserName',
  props<{ userId: string; firstName: string; lastName: string }>()
);
export const setInitialsBundle = factory.singleActionWithCleanup('setInitials', props<{ initials: string; avatarColor?: string }>());
export const setProfileImageBundle = factory.singleActionWithCleanup('setProfileImage', props<{ profileImage: string }>());
export const updateEmailCheckResultVerifiedBundle = factory.singleAction(
  'updateEmailCheckResultVerified',
  props<{ isVerified: boolean }>()
);
export const setFreeTrialDataBundle = factory.singleAction(
  'freeTrialData',
  props<{ freeTrialStartDate: string | null; freeTrialEndDate: string | null }>()
);
export const restrictedOfficeAccess = factory.asyncAction('restrictedOfficeAccess');
export const refetchUserData = factory.asyncAction('refetchUserData', undefined, props<{ user: IUser }>());
export const loadAuthDataBundle = factory.asyncAction(
  'loadAuthData',
  props<{ user?: IUser }>(),
  props<{ user: IUser | null; isNewUser?: boolean; isDeskbirdAdmin: boolean; corporateInfo: ICorporateInfo }>(),
  props<{ error: HttpErrorResponse }>()
);
export const microsoftCredentialsSyncBundle = factory.singleAction('microsoftCredentialsSync', props<{ credentials: AuthCredential }>());
export const loginBundle = factory.asyncAction(
  'login',
  props<{ email: string; password: string; sync?: { credentials: AuthCredential; type: 'googl' | 'msft' } }>(),
  props<{ user: IUser | null; isNewUser?: boolean; isDeskbirdAdmin: boolean; corporateInfo: ICorporateInfo }>(),
  props<{ error: any }>()
);

export const logoutBundle = factory.asyncAction('logout', undefined, undefined, props<{ error: any }>());

export const googleSignInBundle = factory.asyncAction(
  'googleSignIn',
  undefined,
  props<{ user: IUser | null; isNewUser: boolean; isDeskbirdAdmin: boolean; corporateInfo: ICorporateInfo }>(),
  props<{ error: any; user: IUser | null }>()
);

export const microsoftSignInBundle = factory.asyncAction(
  'microsoftSignIn',
  undefined,
  props<{ user: IUser | null; isNewUser: boolean; isDeskbirdAdmin: boolean; corporateInfo: ICorporateInfo }>(),
  props<{ error: any; user: IUser | null }>()
);

export const microsoftTeamsSignInBundle = factory.asyncAction(
  'microsoftTeamsSignIn',
  props<{ email?: string }>(),
  props<{ user: IUser | null; isNewUser: boolean; isDeskbirdAdmin: boolean; corporateInfo: ICorporateInfo }>(),
  props<{ error: any; user: IUser | null }>()
);

export const samlSignInBundle = factory.asyncAction(
  'samlSignIn',
  props<{ provider: string }>(),
  props<{ user: IUser | null; isNewUser?: boolean }>(),
  props<{ error: any }>()
);

export const resendVerificationEmailBundle = factory.asyncAction('resendVerificationEmail', undefined, undefined, props<{ error: any }>());

export const updateUserBundle = factory.asyncAction(
  'updateUser',
  props<{ updates: Partial<IUser>; userId: string; noNotification?: boolean }>(),
  props<{ updates: Partial<IUser>; userId: string }>(),
  props<{ error: any; updates: Partial<IUser> }>()
);

export const checkEmailBundle = factory.asyncActionWithCleanup(
  'checkEmail',
  props<{ email: string }>(),
  props<{ emailCheckResult: IEmailCheck }>(),
  props<{ error: any; email: string }>()
);

export const loadUserProfile = factory.asyncAction('loadUserProfile', undefined, props<{ user: IUser }>(), props<{ error: any }>());

export const loadPublicDomainsBundle = factory.asyncAction(
  'loadPublicDomains',
  undefined,
  props<{ publicDomains: string[] }>(),
  props<{ error: any }>()
);

export const refreshTokenBundle = factory.asyncAction('refreshToken', undefined, undefined, props<{ error: any }>());

export const passwordResetBundle = factory.asyncAction(
  'passwordReset',
  props<{ email: string }>(),
  undefined,
  props<{ error: HttpErrorResponse }>()
);

export const registerBundle = factory.asyncAction(
  'register',
  props<{ email: string; password: string; firstName: string; lastName: string }>(),
  props<{ user: IUser | null }>(),
  props<{ error: HttpErrorResponse }>()
);

export const freeTrialRegisterBundle = factory.asyncAction(
  'freeTrialRegister',
  props<{ email: string; password: string; firstName: string; lastName: string }>(),
  props<{ user: IUser | null; isEmailVerified?: boolean; isDeskbirdAdmin?: boolean }>(),
  props<{ error: HttpErrorResponse }>()
);

export const signInAfterConfirmation = factory.asyncAction(
  'signInAfterConfirmation',
  undefined,
  props<{ user: IUser; corporateInfo: ICorporateInfo; updates: Partial<IUser> }>(),
  props<{ error: HttpErrorResponse }>()
);

export const addUserOfficeIdBundle = factory.singleAction('addUserOfficeId', props<{ officeId: string }>());

export const createFreeTrialCompany = factory.asyncAction(
  'createFreeTrialCompany',
  props<{ demoCompany: IDemoCompany }>(),
  props<{ company: ICompany }>(),
  props<{ error: any }>()
);

export const sendSlackDataBundle = factory.asyncAction('sendSlackData', props<ISlackDataPayload>());

export const setUserTimeFormatBundle = factory.asyncAction(
  'setUserTimeFormat',
  props<{ hourFormat: TimeFormat }>(),
  undefined,
  props<{ error: any }>()
);

export const setFavoriteResourceBundle = factory.singleAction('setFavoriteResource', props<{ resource: IUserFavoriteResource }>());

export const removeFavoriteResourceBundle = factory.singleAction('removeFavoriteResource', props<{ resourceId: number }>());

export const authBundles = [
  authenticateBundle,
  setUserBundle,
  refreshTokenBundle,
  microsoftCredentialsSyncBundle,
  loginBundle,
  logoutBundle,
  googleSignInBundle,
  microsoftSignInBundle,
  microsoftTeamsSignInBundle,
  samlSignInBundle,
  resendVerificationEmailBundle,
  updateUserBundle,
  checkEmailBundle,
  loadUserProfile,
  loadPublicDomainsBundle,
  passwordResetBundle,
  registerBundle,
  freeTrialRegisterBundle,
  loginAfterSignUpBundle,
  setEmailBundle,
  setInitialsBundle,
  setUserTimeFormatBundle,
  setProfileImageBundle,
  signInAfterConfirmation,
  loadAuthDataBundle,
  updateEmailCheckResultVerifiedBundle,
  addUserOfficeIdBundle,
  createFreeTrialCompany,
  setFreeTrialDataBundle,
  setSAMLUserNameBundle,
  restrictedOfficeAccess,
  refetchUserData,
  sendSlackDataBundle,
  setFavoriteResourceBundle,
  removeFavoriteResourceBundle,
];
