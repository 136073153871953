import { Component, ElementRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'db-button',
  standalone: true,
  imports: [CommonModule, ButtonModule, RippleModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ButtonComponent {
  @Input() icon = '';
  @Input() dataTestId?: string;

  @Input() disabled = false;

  @Input() isLoading = false;
  @Input() isSubmit = false;
  @Input() isBold = true;

  @Input() width: 'fit-content' | 'full-width' | 'min-width-160' = 'min-width-160';
  @Input() buttonType: 'normal' | 'outlined' = 'normal';
  @Input() size? : 'small';

  /** Severity is used to control button color - example in https://www.primefaces.org/primeng-v14-lts/button
   * Default is empty string which means primary color
   */
  @Input() severity: 'primary' | 'secondary' | 'danger' = 'primary';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() style: { [klass: string]: any } | null | undefined;

  constructor(elementRef: ElementRef<HTMLElement>) {
    elementRef.nativeElement.addEventListener('click', (event: Event) => {
      // Note : event listener is required here because stopImmediatePropagation not working properly on HostBinding - https://github.com/angular/angular/issues/9587
      if (this.isLoading || this.disabled) {
        event.stopImmediatePropagation();
      }
    });
  }
}
