import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthModel } from "../+store/model";

@Injectable({
  providedIn: 'root'
})
export class LoginDeactivate  {

  constructor(private authModel: AuthModel) { }

  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this.authModel.actions.dispatch.setEmailCleanup();
    this.authModel.actions.dispatch.setInitialsCleanup();
    this.authModel.actions.dispatch.setProfileImageCleanup();

    this.authModel.actions.dispatch.checkEmailCleanup();
    return true;
  }

}