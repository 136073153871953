import { Component } from '@angular/core';
import { AuthModel } from '../+store/model';
import { filter, map, race, switchMap, take, withLatestFrom, tap } from 'rxjs';
import { NgForm } from '@angular/forms';
import { RouterModel } from 'router-module';
import { ConfirmationModel } from 'confirmation-module';
import { GlobalLoaderModel } from 'loader-module';
import { LoadingTenseState } from 'shared';

@Component({
  selector: 'app-sign-in-email',
  templateUrl: './sign-in-email.component.html',
  styleUrls: ['./sign-in-email.component.scss']
})
export class SignInEmailComponent {

  visibility: 'text' | 'password' = 'password';
  isLoading = false;
  microsoftSyncCredentials: any;
  googleSyncCredentials: any;
  LoadingTenseState = LoadingTenseState;
  emailCheckResult$ = this.authModel.selectors.emailCheckResult$.pipe(filter(d => !!d));

  constructor(
    private authModel: AuthModel,
    private routerModel: RouterModel,

    private confirmationModel: ConfirmationModel,
    private globalLoaderModel: GlobalLoaderModel
  ) {
    this.routerModel.selectors.path$.pipe(
      take(1),
      filter(path => path === '/login/sign-in-email/msft-sync' || path === '/login/sign-in-email/googl-sync'),
      switchMap((path) => this.authModel.actions.listen.microsoftCredentialsSync$.pipe(take(1), withLatestFrom([{
        googlSync: path === '/login/sign-in-email/googl-sync',
        msftSync: path === '/login/sign-in-email/msft-sync'
      }]))),
    ).subscribe(([payload, syncType]) => {
      if (syncType.googlSync) {
        this.googleSyncCredentials = payload.credentials;
        this.microsoftSyncCredentials = null;
      } else {
        this.microsoftSyncCredentials = payload.credentials;
        this.googleSyncCredentials = null;
      }
      this.confirmationModel.actions.dispatch.openGenericConfirmation({
        config: {
          headerText: $localize`:@@confirmations|microsoft-account-sync-header:Account already exists for this email`,
          bodyText: $localize`:@@confirmations|microsoft-account-sync-body:Continue by signing in with your existing email and password and your accounts will be linked automatically`,
          confirmButtonLabel: $localize`:@@confirmations|microsoft-account-sync-confirm:Sign in`,
          hideCancel: true
        }
      });
    });
  }

  toggleVisibility(): void {
    this.visibility = this.visibility === 'text' ? 'password' : 'text';
  }

  onEmailSignIn(form: NgForm): void {
    if (form.invalid) { return; }
    this.isLoading = true;
    const { password, email } = form.value;
    if (!password || !email) { return; }

    this.authModel.actions.dispatch.login({
      email, password,
      sync: {
        credentials: this.microsoftSyncCredentials || this.googleSyncCredentials,
        type: this.microsoftSyncCredentials ? 'msft' : 'googl'
      }
    });
    race(
      this.authModel.actions.listen.loginSuccess$,
      this.authModel.actions.listen.loginFailure$
    ).pipe(take(1)).subscribe(() => {
      setTimeout(() => this.isLoading = false, 1000)
    });
  }

  forgotPassword(): void {
    this.emailCheckResult$.pipe(take(1), switchMap((emailResult) => {
      this.globalLoaderModel.actions.dispatch.showLoader({ visibility: true });
      this.authModel.actions.dispatch.passwordReset({ email: emailResult?.email || '' });

      return race(
        this.authModel.actions.listen.passwordResetSuccess$.pipe(map(() => true)),
        this.authModel.actions.listen.passwordResetFailure$.pipe(map(() => false))
      ).pipe(take(1), tap(() => {
        this.globalLoaderModel.actions.dispatch.showLoader({ visibility: false });
      }));
    })).subscribe();
  }

}
