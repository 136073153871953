import { NotificationType } from './enums';

export const NOTIFICATION_STORE_NAME = 'notification';

export const notificationTypeIconNames = {
  [NotificationType.DEFAULT]: 'svg-neutral-toast',
  [NotificationType.WARNING]: 'svg-warning-sign',
  [NotificationType.ERROR]: 'svg-delete-sign',
  [NotificationType.SUCCESS]: 'svg-success-checkmark',
};

export const notificationTypeLocales = {
  [NotificationType.WARNING]: $localize`:@@notification-module|summary|warning:WARNING`,
  [NotificationType.ERROR]: $localize`:@@notification-module|summary|error:ERROR`,
  [NotificationType.SUCCESS]: $localize`:@@notification-module|summary|success:Success`,
  [NotificationType.DEFAULT]: $localize`:@@notification-module|summary|info:Info`,
};
