import { Directive, ElementRef, Input } from '@angular/core';
import { Subscription, animationFrameScheduler, debounceTime, fromEvent, observeOn } from 'rxjs';

@Directive({
  selector: '[dbStickyObserver]',
  standalone: true,
})
/** Use on a container with position sticky, to observe when it gets stuck (on top of its parent container) */
export class StickyObserverDirective {
  private scrollSubscription?: Subscription;
  private element: HTMLElement;
  private parentElement?: HTMLElement;
  private isSticky: boolean = false;

  @Input() offsetTopBufferPx = 0;

  constructor(el: ElementRef) {
    this.element = el.nativeElement;

    if (this.element.parentElement) {
      this.parentElement = this.element.parentElement;
      this.scrollSubscription = fromEvent(this.element.parentElement, 'scroll')
        .pipe(observeOn(animationFrameScheduler))
        .subscribe(this.scrollHandler);
    }
  }

  private scrollHandler = () => {
    if (!this.parentElement) {
      return;
    }

    const isStuck = this.isElementStuckOnTopOfParent(this.parentElement);

    if (isStuck && !this.isSticky) {
      this.appendClassSticky();
      this.isSticky = true;
    } else {
      if (!isStuck && this.isSticky) {
        this.removeClassSticky();
        this.isSticky = false;
      }
    }
  };

  private isElementStuckOnTopOfParent(el: HTMLElement): boolean {
    return this.element.offsetTop - el.offsetTop > this.offsetTopBufferPx;
  }

  private appendClassSticky() {
    this.element.classList.add('sticky-header');
  }

  private removeClassSticky() {
    this.element.classList.remove('sticky-header');
  }

  ngOnDestroy(): void {
    this.scrollSubscription?.unsubscribe();
  }
}
