import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import moment from 'moment';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { IDateRange } from '../../types';

@Component({
  selector: 'db-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  standalone: true,
  imports: [CommonModule, CalendarModule, FormsModule],
})
export class DateRangePickerComponent {
  @ViewChild('calendar') calendar!: Calendar;
  @Input() dateRange!: Date[];
  @Input() maxRangeDays?: number | null = null;
  @Input() placeholder: string = '';
  @Input() noBorder = true;
  @Input() iconInContainer = false;
  @Output() onDateRangeChange = new EventEmitter<IDateRange>();

  dateRangeChangedHandler([startDateString, endDateString]: [string, string]) {
    const startDate = moment(startDateString);
    const endDate = endDateString ? moment(endDateString) : null;

    if (endDate && this.maxRangeDays) {
      const allowedDaysDiff = this.maxRangeDays - 1;
      const rangeMoreThanAllowedDays = endDate.diff(startDate, 'days') > allowedDaysDiff;
      const maxAllowedEndDate = rangeMoreThanAllowedDays ? moment(startDate).add(allowedDaysDiff, 'days') : endDate;

      const dateRage = {
        startDate: startDate,
        endDate: maxAllowedEndDate,
      };

      this.onDateRangeChange.emit(dateRage);
    } else {
      const dateRange: IDateRange = { startDate, endDate };
      this.onDateRangeChange.emit(dateRange);
    }
  }
}
