/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule, JsonPipe } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { EventEmitterValue } from 'shared-types';
import { PrimeTemplate } from 'primeng/api';
import { filter, startWith, switchMap, take, tap } from 'rxjs';

@Component({
  selector: 'db-multiselect',
  standalone: true,
  imports: [CommonModule, MultiSelectModule, FormsModule, JsonPipe],
  templateUrl: './multiselect.component.html',
  styleUrls: [], // see overrides/db-ui/multiselect.scss
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiselectComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectComponent<TItem extends { [key: string]: any }> implements AfterContentInit {
  cd = inject(ChangeDetectorRef);
  @Input() dataTestId?: string;
  @Input() label?: string;
  @Input() showAsterisk?: boolean;
  @Input() optional?: boolean;
  /** Set flag to true if you want to provide custom templates. It's by default false so that there's no need to execute the complex logic to bind properly the prime templates after initial initialization of control. */
  @Input() withCustomTemplates: boolean = false;
  @Input() selectedOptions: TItem[] | undefined = undefined;

  /** primeng inputs */
  @Input() options: TItem[] = [];
  @Input() optionValue: string = 'value';
  @Input() optionLabel!: string;
  @Input() chip = true;
  @Input() placeholder: string = '';
  @Input() filter: boolean = true; // When specified, displays an input field to filter the items on keyup.
  @Input() filterBy: string | undefined; // When filtering is enabled, filterBy decides which field or fields (comma separated) to search against.
  @Input() showHeader: boolean = true; // Whether to show the header.
  @Input() selectedItemsLabel: string = 'ellipsis'; // Label to display after exceeding max selected labels e.g. ({0} items selected), defaults "ellipsis" keyword to indicate a text-overflow.
  @Input() showToggleAll: boolean = false; // Whether to show the checkbox at header to toggle all items at once.
  @Input() maxSelectedLabels: number = 3; // Decides how many selected item labels to show at most.
  @Input() panelStyleClass: string | undefined; // Style class of the overlay panel element.
  @Input() optionDisabled: string = ''; // Name of the disabled field of an option.
  @Input() resetFilterOnHide: boolean = true; // Clears the filter value when hiding the dropdown.
  @Input() group: boolean = false; //  Whether to display options as grouped when nested options are provided.
  @Input() showClear: boolean = false; // When enabled, a clear icon is displayed to clear the value.
  @Input() virtualScroll: boolean = false; // Whether the data should be loaded on demand during scroll.
  @Input() virtualScrollItemSize: number = 48; // Height of an item in the list for VirtualScrolling.

  // Unused primeng inputs (you can move to used when needed):
  // @Input() style: { [klass: string]: any } | null | undefined; //Inline style of the element.
  // @Input() styleClass: string | undefined; // Style class of the element.
  // @Input() panelStyle: any; // Inline style of the overlay panel.
  // @Input() inputId: string | undefined; // Identifier of the focus input to match a label defined for the component.
  // @Input() disabled: boolean | undefined; // When present, it specifies that the element should be disabled.
  // @Input() readonly: boolean | undefined; // When present, it specifies that the component cannot be edited.
  // @Input() filterPlaceHolder: string | undefined; // Defines placeholder of the filter input.
  // @Input() filterLocale: string | undefined; // Locale to use in filtering. The default locale is the host environment's current locale.
  // @Input() overlayVisible: boolean | undefined; // Specifies the visibility of the options panel.
  // @Input() tabindex: number | undefined; //  Index of the element in tabbing order.
  // @Input() appendTo: HTMLElement | ElementRef | TemplateRef<any> | string | null | undefined | any; // Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
  // @Input() dataKey: string | undefined; // A property to uniquely identify a value in options.
  // @Input() name: string | undefined; // Name of the input element.
  // @Input() label: string | undefined; // Label of the input for accessibility.
  // @Input() ariaLabelledBy: string | undefined; // Establishes relationships between the component and label(s) where its value should be one or more element IDs.
  // @Input() displaySelectedLabel: boolean = true; //Whether to show labels of selected item labels or use default label.
  // @Input() selectionLimit: number | undefined; // Number of maximum options that can be selected.
  // @Input() emptyFilterMessage: string = ''; // Text to display when filtering does not return any results.
  // @Input() emptyMessage: string = ''; // Text to display when there is no data. Defaults to global value in i18n translation configuration.
  // @Input() dropdownIcon: string | undefined; // Icon class of the dropdown icon.
  // @Input() optionGroupLabel: string | undefined; // Name of the label field of an option group.
  // @Input() optionGroupChildren: string = 'items'; // Name of the options field of an option group.
  // @Input() scrollHeight: string = '200px'; // Height of the viewport in pixels, a scrollbar is defined if height of list exceeds this value.
  // @Input() lazy: boolean = false; // Defines if data is loaded and interacted with in lazy manner.
  // @Input() virtualScrollOptions: ScrollerOptions | undefined; // Whether to use the scroller feature. The properties of scroller component can be used like an object in it.
  // @Input() overlayOptions: OverlayOptions | undefined; // Whether to use overlay API feature. The properties of overlay API can be used like an object in it.
  // @Input() ariaFilterLabel: string | undefined; // Defines a string that labels the filter input.
  // @Input() filterMatchMode: 'contains' | 'startsWith' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte' =
  //   'contains'; // Defines how the items are filtered.
  // @Input() tooltip: string = ''; // Advisory information to display in a tooltip on hover.
  // @Input() tooltipPosition: 'top' | 'left' | 'right' | 'bottom' = 'right'; // Position of the tooltip.
  // @Input() tooltipPositionStyle: string = 'absolute'; // Type of CSS position.
  // @Input() tooltipStyleClass: string | undefined; // Style class of the tooltip.
  // @Input() autofocusFilter: boolean = true; // Applies focus to the filter element when the overlay is shown.
  // @Input() display: string = 'comma'; // No description available.
  // @Input() autocomplete: string = 'on'; // No description available.

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChange = new EventEmitter<EventEmitterValue<MultiSelect['onChange']>>(); // Event emitted when the value changes.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFilter = new EventEmitter<EventEmitterValue<MultiSelect['onFilter']>>(); // Event emitted when the value changes.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFocus = new EventEmitter<EventEmitterValue<MultiSelect['onFocus']>>(); // Event emitted when the value changes.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onBlur = new EventEmitter<EventEmitterValue<MultiSelect['onBlur']>>(); // Event emitted when the value changes.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter<EventEmitterValue<MultiSelect['onClick']>>(); // Event emitted when the value changes.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClear = new EventEmitter<EventEmitterValue<MultiSelect['onClear']>>(); // Event emitted when the value changes.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onPanelShow = new EventEmitter<EventEmitterValue<MultiSelect['onPanelShow']>>(); // Event emitted when the value changes.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onPanelHide = new EventEmitter<EventEmitterValue<MultiSelect['onPanelHide']>>(); // Event emitted when the value changes.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onLazyLoad = new EventEmitter<EventEmitterValue<MultiSelect['onLazyLoad']>>(); // Event emitted when the value changes.

  show!: MultiSelect['show'];
  hide!: MultiSelect['hide'];

  _onChange = (_: any): void => {};
  _onTouched = (_: any): void => {};
  _disabledState: boolean = false;
  _multiselectVisibility = true;
  _value: any = undefined;

  @ContentChildren(PrimeTemplate) templates = new QueryList<PrimeTemplate>();
  @ViewChildren(MultiSelect) primeNgMultiselectList = new QueryList<MultiSelect>();
  get primeNgMultiselect() {
    return this.primeNgMultiselectList.first;
  }

  attachHandlers = (): void => {
    if (!this.primeNgMultiselect) {
      return;
    }

    this.primeNgMultiselect.onChange.subscribe(this.onChange);
    this.primeNgMultiselect.onFilter.subscribe(this.onFilter);
    this.primeNgMultiselect.onFocus.subscribe(this.onFocus);
    this.primeNgMultiselect.onBlur.subscribe(this.onBlur);
    this.primeNgMultiselect.onClick.subscribe(this.onClick);
    this.primeNgMultiselect.onClear.subscribe(this.onClear);
    this.primeNgMultiselect.onPanelShow.subscribe(this.onPanelShow);
    this.primeNgMultiselect.onPanelHide.subscribe(this.onPanelHide);
    this.primeNgMultiselect.onLazyLoad.subscribe(this.onLazyLoad);

    this.show = this.primeNgMultiselect.show.bind(this.primeNgMultiselect);
    this.hide = this.primeNgMultiselect.hide.bind(this.primeNgMultiselect);
  };

  ngAfterContentInit(): void {
    if (this.withCustomTemplates) {
      this.templates.changes
        .pipe(
          startWith(0),
          switchMap(() => {
            setTimeout(() => {
              this._multiselectVisibility = false;
              this.cd.detectChanges();
            });
            return this.primeNgMultiselectList.changes.pipe(
              filter((list: QueryList<MultiSelect>) => list.length === 0),
              take(1)
            );
          }),
          switchMap(() => {
            setTimeout(() => {
              this._multiselectVisibility = true;
              this.cd.detectChanges();
            });
            return this.primeNgMultiselectList.changes.pipe(
              filter((list: QueryList<MultiSelect>) => list.length > 0),
              take(1)
            );
          })
        )
        .subscribe(() => {
          this.primeNgMultiselect.writeValue(this._value);
          this.primeNgMultiselect.registerOnChange(this._onChange);
          this.primeNgMultiselect.registerOnTouched(this._onTouched);
          this.primeNgMultiselect.setDisabledState(this._disabledState);
          this.attachHandlers();
          this.cd.detectChanges();
        });
    } else {
      setTimeout(() => {
        this.primeNgMultiselect.writeValue(this._value);
        this.primeNgMultiselect.registerOnChange(this._onChange);
        this.primeNgMultiselect.registerOnTouched(this._onTouched);
        this.primeNgMultiselect.setDisabledState(this._disabledState);
        this.attachHandlers();
        this.cd.detectChanges();
      });
    }
  }

  writeValue(obj: any): void {
    this._value = obj;
    this.primeNgMultiselect?.writeValue(obj);
    this.cd.detectChanges();
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
    this.primeNgMultiselect?.registerOnChange(fn);
  }

  registerOnTouched(fn: (_: any) => void): void {
    this._onTouched = fn;
    this.primeNgMultiselect?.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabledState = isDisabled;
    this.primeNgMultiselect?.setDisabledState(isDisabled);
  }
}
