import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { RouterModel } from 'router-module';
import { AuthModel } from '../+store/model';
import { asyncScheduler, combineLatest, delay, filter, map, observeOn, race, switchMap, take, tap, withLatestFrom, zip } from 'rxjs';
import { UploadModel } from 'upload-module';
import { acceptedImageUploadFormats } from 'common-module';
import moment from 'moment';
import { GlobalLoaderModel } from 'loader-module';
import { LoadingTenseState } from 'shared';

@Component({
  selector: 'app-sign-in-wizard',
  templateUrl: './sign-in-wizard.component.html',
  styleUrls: ['./sign-in-wizard.component.scss']
})
export class SignInWizardComponent {

  visibility: 'text' | 'password' = 'password';
  acceptedImageFormats = acceptedImageUploadFormats;
  uploadBtnName = 'user-avatar';
  userData = { firstName: '', lastName: '' };
  isLoading = false;
  isUploading$ = this.uploadModel.isUploading$('sign-up-avatar-upload');
  isConfirmingProfile = false;
  LoadingTenseState = LoadingTenseState;
  avatarLocation = '/api/user/uploadAvatar';

  profileImage$ = this.authModel.selectors.profileImage$.pipe(
    map(profileImage => profileImage ? `${profileImage}?timestamp=${Date.now()}` : null)
  );
  SAMLWizardUserId$ = this.authModel.selectors.SAMLWizardUserId$;
  initials$ = this.authModel.selectors.initials$;
  avatarColor$ = this.authModel.selectors.avatarColor$;

  currentUserData$ = combineLatest([
    this.authModel.selectors.emailCheckResult$,
    this.authModel.user$,
    this.authModel.selectors.corporateInfo$,
    this.authModel.selectors.initials$,
    this.authModel.selectors.avatarColor$,
    this.authModel.selectors.profileImage$,
  ]).pipe(
    map(([lastEmailCheckResult, currentUser, corporateInfo, initials, avatarColor, profileImage]) => ({
      id: currentUser?.id || '',
      email: currentUser?.email || lastEmailCheckResult?.email,
      userExists: currentUser?.email === lastEmailCheckResult?.email ? lastEmailCheckResult?.userExists : true,
      companyLogoUrl: corporateInfo?.logo || lastEmailCheckResult?.companyLogoUrl,
      companyName: corporateInfo?.name || lastEmailCheckResult?.companyName,
      initials: initials,
      userProfileImage: profileImage,
      avatarColor,
      emailVerified: lastEmailCheckResult?.isVerified || false,
      allowsScheduling: corporateInfo?.allowsScheduling || false
    }))
  );
  queryParams$ = this.routerModel.selectors.queryParams$;
  page$ = this.queryParams$.pipe(map(queryParams => queryParams['page']));
  isOnSignUpPage$ = this.queryParams$.pipe(map(({ page }) => ['page'].includes(page)));

  constructor(
    private routerModel: RouterModel,
    private authModel: AuthModel,
    private uploadModel: UploadModel,
    private location: Location,
    private globalLoader: GlobalLoaderModel
  ) { }

  toggleVisibility(): void {
    this.visibility = this.visibility === 'text' ? 'password' : 'text';
  }

  enterName(value: { firstName: string; lastName: string; }): void {
    const { firstName, lastName } = value;
    this.userData = { firstName, lastName };
    this.SAMLWizardUserId$.pipe(
      take(1),
      tap((SAMLWizardUserId): void => {
        if (!SAMLWizardUserId) {
          return void this.routerModel.actions.dispatch.navigate(
            {
              commands: [],
              extras: { queryParams: { page: 'set-password' } }
            });
        }
        this.globalLoader.actions.dispatch.showLoader({ visibility: true });
        this.authModel.actions.dispatch.updateUser({ updates: { firstName, lastName }, userId: SAMLWizardUserId });
      }),
      filter(val => !!val),
      switchMap(() => race(
        this.authModel.actions.listen.updateUserSuccess$.pipe(take(1), map(() => true)),
        this.authModel.actions.listen.updateUserFailure$.pipe(take(1), map(() => false))
      ).pipe(
        tap(() => this.globalLoader.actions.dispatch.showLoader({ visibility: false }))
      )),
      filter(val => !!val),
    ).subscribe((): void => {
      this.routerModel.actions.dispatch.navigate(
        {
          commands: [],
          extras: { queryParams: { page: 'upload-avatar' } }
        });
    });

  }

  register(form: NgForm): void {
    if (form.invalid) { return; }
    this.isLoading = true;
    const { password } = form.value;
    this.currentUserData$.pipe(take(1)).subscribe(emailResult => {
      if (!emailResult?.email) {
        this.isLoading = false;
        return;
      }
      this.authModel.actions.dispatch.register({
        email: emailResult.email,
        password,
        firstName: this.userData.firstName,
        lastName: this.userData.lastName
      });
      race(
        this.authModel.actions.listen.registerSuccess$.pipe(map((payload) => ({ user: payload.user, error: null }))),
        this.authModel.actions.listen.registerFailure$.pipe(map((payload) => ({ user: null, error: payload.error })))
      ).pipe(
        take(1),
        observeOn(asyncScheduler)
      ).subscribe(({ user, error }) => {
        this.isLoading = false;
        if (error || !user) { return; } // TODO handle it
        this.routerModel.actions.dispatch.navigate({ commands: [], extras: { queryParams: { page: 'upload-avatar' } } });
      });
    });
  }

  hasOpacity(obj: any, propertyName: string): boolean {
    if (obj === null || Array.isArray(obj) || typeof obj !== 'object' || !obj.hasOwnProperty(propertyName)) { return false; }
    return true;
  }

  handleUpload({ success, body }: { body: any, success: boolean }): void {
    if (!success || !body) { return; }
    const url = body?.data?.url || null;
    if (!url) { return; }
    this.authModel.actions.dispatch.setProfileImage({ profileImage: url });
  }

  removeAvatar(): void {
    this.currentUserData$.pipe(
      take(1),
      filter(data => !!data.userProfileImage),
      tap((userData) => {
        this.isLoading = true;
        this.authModel.actions.dispatch.updateUser({ updates: { profileImage: '' }, userId: userData.id.toString() });
      }),
      switchMap(() => race(
        this.authModel.actions.listen.updateUserSuccess$.pipe(map((payload) => ({ user: payload.updates, error: null }))),
        this.authModel.actions.listen.updateUserFailure$.pipe(map((payload) => ({ user: null, error: payload.error })))
      ).pipe(take(1)))
    ).subscribe(() => {
      this.authModel.actions.dispatch.setProfileImage({ profileImage: '' });
      this.isLoading = false;
    });
  }

  confirmProfile(): void {
    zip(
      this.authModel.selectors.emailCheckResult$,
      this.authModel.selectors.freeTrialStartDate$,
    ).pipe(take(1)).subscribe(([emailCheckData, freeTrialStartDate]) => {
      const needsToVerifyEmail = emailCheckData && !emailCheckData.isVerified;

      const freeTrialStartDateMoment = moment(freeTrialStartDate).utc();
      const minDiff = moment().diff(freeTrialStartDateMoment, 'minutes');
      const canSkipEmailVerification = minDiff < 120;

      if (needsToVerifyEmail && !canSkipEmailVerification) {
        this.routerModel.actions.dispatch.navigate({ commands: ['/login/verify-email'], extras: { queryParamsHandling: 'preserve' } })
        return;
      }
      this.isConfirmingProfile = true;
      this.authModel.actions.dispatch.signInAfterConfirmation();
      race(
        this.authModel.actions.listen.signInAfterConfirmationSuccess$,
        this.authModel.actions.listen.signInAfterConfirmationFailure$.pipe(map(() => null))
      ).pipe(take(1), delay(0)).subscribe(data => {
        if (!data) { return; }

        this.isConfirmingProfile = false;
        this.authModel.actions.dispatch.checkEmailCleanup();
        this.routerModel.actions.dispatch.navigate({ commands: ['/default'], extras: { queryParamsHandling: 'preserve' } });
      });
    });
  }

  goBackToLogin(): void {
    this.isUploading$.pipe(
      take(1),
      filter(isUploading => !isUploading),
      switchMap(() => this.queryParams$.pipe(map(({ page }) => ['upload-avatar'].includes(page)), take(1))),
      withLatestFrom(this.SAMLWizardUserId$)
    ).subscribe(([isOnUploadAvatar, SAMLWizardUserId]) => {
      if (isOnUploadAvatar || SAMLWizardUserId) {
        if (SAMLWizardUserId) {
          this.authModel.actions.dispatch.setSamlUserNameCleanup();
        }
        this.authModel.actions.dispatch.logout();
        this.routerModel.actions.dispatch.navigate({ commands: ['/login'], extras: { queryParamsHandling: 'preserve' } });
        return;
      }
      this.location.back();
    });
  }
}
