import { Inject, Injectable } from "@angular/core";
import { WINDOW } from "../constants";

interface LocalStorageData {
  locale: string
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  localStorage: Storage;

  constructor(@Inject(WINDOW) window: Window) {
    this.localStorage = window.localStorage;
  }

  setItem(key: string, data: string) {

    this.localStorage.setItem(key, data);
  }

  getItem(key: string): string | null {
    return this.localStorage.getItem(key);
  }
}