import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'db-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoMessageComponent {
  @Input() text!: string;
  @Input() severity: 'success' | 'error' | 'warn' | 'info' = 'info';
  @Input() hideIcon = false;
}
