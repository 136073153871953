<ng-container *ngIf="!isLoading && bookingProps">
  <div class="info-col">
    <div
      class="details"
      *ngIf="!fitDetailsOnOneLine; else detailsOnOneLineTemplate"
    >
      <!-- GUEST NAME (IF SUCH)-->
      <div *ngIf="bookingProps.guest" class="small-text text-gray">
        {{ bookingProps.guest.firstName }} {{ bookingProps.guest.lastName }}
      </div>

      <!-- RESOURCE NAME AND ICONS-->
      <div class="d-flex align-items-center">
        <!-- RESOURCE NAME -->
        <span class="small-text-bold text-gray truncate-text">{{
          resourceName
        }}</span>

        <db-area-restriction-icon
          *ngIf="
            bookingProps.resource &&
            bookingProps.resource.type !== deskAreaType.MeetingRoom &&
            bookingProps.resource.accessRules
          "
          [accessRules]="bookingProps.resource.accessRules"
        >
        </db-area-restriction-icon>

        <!-- DEDICATED ICON -->
        <img
          *ngIf="isDedicatedResource"
          src="/assets/icons/dedicated-resource.svg"
          alt="dedicated resource"
          height="14"
        />

        <!-- FAVORITE FEATURE -->
        <db-favorite-space-action-icon
          *ngIf="
            bookingProps.resource &&
            bookingProps.zoneItem &&
            bookingProps.resource.type !== deskAreaType.MeetingRoom &&
            isFavoriteIconPossible
          "
          [resourceItem]="bookingProps.zoneItem"
          [deskArea]="{ accessRules: bookingProps.resource.accessRules }"
          [gtmEntryPoint]="favoriteSpaceGtmEntryPoint"
          [ngClass]="{ hovered: isHovered }"
          [authUser]="{
            favoriteResources: authUserFavoriteResources,
            id: authUserId
          }"
          [dataTestIdBase]="favoriteSpaceDataTestId || undefined"
        >
        </db-favorite-space-action-icon>
      </div>

      <!-- AREA NAME -->
      <div
        *ngIf="
          bookingProps &&
          bookingProps.resource &&
          bookingProps.resource.type !== deskAreaType.MeetingRoom &&
          bookingProps.resource.name
        "
        class="very-small-text"
      >
        {{ bookingProps.resource.name }}
      </div>
    </div>

    <!-- BOOKING TIME RANGE -->
    <div class="very-small-text text-dark-gray">
      <db-booking-start-end-time
        [hourFormat]="authUserTimeFormat"
        [isFullDay]="bookingProps.isDayPass"
        [startTime]="bookingProps.bookingStartTime"
        [endTime]="bookingProps.bookingEndTime"
        [timeZone]="bookingProps.workspace.address?.timeZone"
      >
      </db-booking-start-end-time>
    </div>
  </div>
  <div class="status-col">
    <db-booking-status
      [booking]="bookingProps"
      [authUser]="{ hourFormat: authUserTimeFormat! }"
      [dataTestIdForCheckInCta]="
        bookingStatusDataTestIdForCheckInCta || undefined
      "
      (bookingCheckInConfirmation)="checkInHandler()"
    >
    </db-booking-status>
  </div>
</ng-container>

<ng-template #detailsOnOneLineTemplate>
  <div class="details one-line-details" *ngIf="bookingProps">
    <!-- RESOURCE NAME AND ICONS-->
    <div class="d-flex align-items-center">
      <!-- RESOURCE NAME -->
      <span class="small-text-bold text-gray truncate-text">{{
        resourceName
      }}</span>

      <db-area-restriction-icon
        *ngIf="
          bookingProps.resource &&
          bookingProps.resource.type !== deskAreaType.MeetingRoom &&
          bookingProps.resource.accessRules
        "
        [accessRules]="bookingProps.resource.accessRules"
        [withRightMargin]="false"
      >
      </db-area-restriction-icon>

      <!-- DEDICATED ICON -->
      <img
        *ngIf="isDedicatedResource"
        src="/assets/icons/dedicated-resource.svg"
        alt="dedicated resource"
        height="14"
      />

      <!-- FAVORITE FEATURE -->
      <db-favorite-space-action-icon
        *ngIf="
          bookingProps.resource &&
          bookingProps.zoneItem &&
          bookingProps.resource.type !== deskAreaType.MeetingRoom &&
          isFavoriteIconPossible
        "
        [resourceItem]="bookingProps.zoneItem"
        [deskArea]="{ accessRules: bookingProps.resource.accessRules }"
        [gtmEntryPoint]="favoriteSpaceGtmEntryPoint"
        [ngClass]="{ hovered: isHovered }"
        [authUser]="{
          favoriteResources: authUserFavoriteResources,
          id: authUserId
        }"
        [midTextIcon]="true"
        [dataTestIdBase]="favoriteSpaceDataTestId || undefined"
      >
      </db-favorite-space-action-icon>
    </div>

    <div class="vertical-dash"></div>

    <!-- AREA NAME -->
    <div
      *ngIf="
        bookingProps &&
        bookingProps.resource &&
        bookingProps.resource.type !== deskAreaType.MeetingRoom &&
        bookingProps.resource.name
      "
      class="small-text"
    >
      {{ bookingProps.resource.name }}
    </div>

    <ng-container *ngIf="bookingProps.guest">
      <div class="vertical-dash"></div>
      <div class="small-text-bold text-gray">
        {{ bookingProps.guest.firstName }} {{ bookingProps.guest.lastName }}
      </div>
      &nbsp;
      <div class="small-text" i18n="@@common|guest-with-brackets">(Guest)</div>
    </ng-container>
  </div>
</ng-template>

<ng-container *ngIf="isLoading">
  <div class="info-col">
    <db-skeleton-loader
      skeletonWidth="60px"
      skeletonHeight="15px"
      skeletonClass="mb-8"
    ></db-skeleton-loader>
    <db-skeleton-loader
      skeletonWidth="90px"
      skeletonHeight="10px"
    ></db-skeleton-loader>
  </div>
  <div class="status-col">
    <db-skeleton-loader
      skeletonWidth="60px"
      skeletonHeight="20px"
    ></db-skeleton-loader>
  </div>
</ng-container>
