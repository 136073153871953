import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  APP_URL,
  samlIsNewUserParameterName,
  samlSuccessRoute,
  samlUserId,
  samlMobileTokenParameterName,
  samlTokenParameterName,
  iosSamlSuccessPath,
  WINDOW,
} from 'common-module';
import { GlobalLoaderModel } from 'loader-module';
import { NotificationModel, NotificationType } from 'notification-module';
import { RouterModel } from 'router-module';
import { Subscription, interval, Subject, race, of } from 'rxjs';
import { filter, map, take, withLatestFrom, startWith, tap, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { AuthModel } from '../+store/model';
import { combineLatestForFrame } from 'shared';

const COUNTDOWN = 6;

@Component({
  selector: 'db-saml',
  templateUrl: './saml.component.html',
  styleUrls: ['./saml.component.scss'],
})
export class SamlComponent implements OnDestroy, OnInit {
  isLoggedIn$ = this.authModel.isLogged$;
  samlUserId$ = this.authModel.selectors.SAMLWizardUserId$;
  subscription: Subscription = new Subscription();
  isNavigating = false;
  isDestroying = false;

  queryParamsNavigationCleanUp = {
    [samlIsNewUserParameterName]: undefined,
    [samlMobileTokenParameterName]: undefined,
  };

  userNames$ = new Subject<{ firstName: string; lastName: string }>();
  isNewUser$ = this.routerModel.selectors.queryParams$.pipe(
    map((queryParams) => queryParams[samlIsNewUserParameterName] && !queryParams[samlMobileTokenParameterName])
  );
  url$ = this.routerModel.selectors.url$;

  count = COUNTDOWN;

  constructor(
    private router: Router,
    private authModel: AuthModel,
    private routerModel: RouterModel,
    private globalLoader: GlobalLoaderModel,
    private notificationModel: NotificationModel,
    @Inject(WINDOW) private window: Window,
    @Inject(APP_URL) private appURL: string
  ) {}

  private generateProvider(providerName: string): string {
    return providerName.includes('saml.') ? providerName : `saml.${providerName}`;
  }

  ngOnInit(): void {
    this.subscription.add(
      combineLatestForFrame([this.routerModel.selectors.queryParams$, this.routerModel.allRouteParams$, this.isLoggedIn$, this.samlUserId$])
        .pipe(
          filter(() => !this.isNavigating),
          take(1),
          mergeMap(([queryParams, allRouteParams, isLoggedIn, samlUserId]) => {
            // URL navigated by mobile apps is http://app-staging.deskbird.com/saml?providerId=saml.deskbird-staging&platform=ios

            const providerId = queryParams['providerId'];
            const platform = queryParams['platform'];

            const providerName = queryParams['providerName'];
            const providerRouteName = allRouteParams['provider'];

            const isSamlRedirectBack = localStorage.getItem('saml');
            const isMobile = !!localStorage.getItem('mobile');

            if (platform) {
              localStorage.setItem('platform', platform);
            }

            if (isSamlRedirectBack) {
              // After successful SSO authentication, firebase returns token and auth effects redirect here again via http://app-staging.deskbird.com/saml?token={}&isNewUser=t{}&userId={firebaseId}
              localStorage.removeItem('saml');
              if (!isLoggedIn && !samlUserId) {
                return [null];
              }
            }

            // Either user is logged in or SAML registration is in progress and the user has to still enter his name and we have only samlUserId
            if (isLoggedIn || samlUserId) {
              if (!isSamlRedirectBack || !isMobile) {
                this.count = 0;
              }
              return [true];
            }

            if (providerRouteName) {
              localStorage.removeItem('mobile');
              const provider = this.generateProvider(providerRouteName);
              return this.execSamlSignIn$(provider).pipe(map(() => false));
            }

            if (providerId) {
              localStorage.removeItem('mobile');
              const provider = this.generateProvider(providerId);
              localStorage.setItem('mobile', 'true');

              return this.execSamlSignIn$(provider).pipe(map(() => false));
            }

            if (providerName) {
              localStorage.removeItem('mobile');
              const provider = this.generateProvider(providerName);
              return this.execSamlSignIn$(provider).pipe(map(() => false));
            }

            if (!localStorage.getItem('mobile')) {
              this.count = 0;
            }
            return [true];
          }),
          tap((value) => {
            if (value !== null) {
              return;
            }

            this.routerModel.actions.dispatch.navigate({ commands: ['/login'] });
          }),
          filter((val) => !!val),
          withLatestFrom(this.routerModel.selectors.queryParams$),
          mergeMap(([, queryParams]) => {
            if (this.count > 0) {
              return (
                queryParams[samlIsNewUserParameterName] && !queryParams[samlMobileTokenParameterName]
                  ? this.userNames$.pipe(takeUntil(this.authModel.actions.listen.signInAfterConfirmationSuccess$))
                  : of({ firstName: '', lastName: '' })
              ).pipe(
                withLatestFrom(this.routerModel.selectors.queryParams$),
                tap(([{ firstName, lastName }, queryParams]) => {
                  const userId = queryParams[samlUserId];
                  const isNewUser = !!queryParams[samlIsNewUserParameterName];
                  const token = queryParams[samlTokenParameterName];
                  if (!userId || !isNewUser) {
                    if (token) {
                      this.fixUrl(token, isNewUser);
                    } // NOTE: href navigation (page reload)
                    return;
                  }
                  this.authModel.actions.dispatch.updateUser({ updates: { firstName, lastName }, userId, noNotification: true });
                  this.globalLoader.actions.dispatch.showLoader({ visibility: true });
                }),
                mergeMap(([, queryParams]) => {
                  const userId = queryParams[samlUserId];
                  const isNewUser = queryParams[samlIsNewUserParameterName];
                  if (!userId || !isNewUser) {
                    return [true];
                  }
                  return race(
                    this.authModel.actions.listen.updateUserSuccess$.pipe(map(() => true)),
                    this.authModel.actions.listen.updateUserFailure$.pipe(
                      map(() => false),
                      tap(() => {
                        this.globalLoader.actions.dispatch.showLoader({ visibility: false });
                        this.notificationModel.actions.dispatch.showNotification({
                          data: $localize`:@@auth-module|error-update-user:Profile update failed`,
                          notificationType: NotificationType.ERROR,
                        });
                      })
                    )
                  ).pipe(
                    take(1),
                    filter((val) => !!val),
                    tap(() => this.authModel.actions.dispatch.signInAfterConfirmation()),
                    switchMap(() => {
                      return race(
                        this.authModel.actions.listen.signInAfterConfirmationSuccess$.pipe(map(() => true)),
                        this.authModel.actions.listen.signInAfterConfirmationFailure$.pipe(map(() => false))
                      );
                    }),
                    tap((signInAfterConfirmationSuccess) => {
                      const samlToken = queryParams[samlTokenParameterName];
                      const isNewUser = !!queryParams[samlIsNewUserParameterName];
                      if (samlToken && signInAfterConfirmationSuccess) {
                        this.fixUrl(samlToken, isNewUser);
                      } // NOTE: href navigation (page reload)
                      this.globalLoader.actions.dispatch.showLoader({ visibility: false });
                    }),
                    filter((val) => !!val)
                  );
                }),
                mergeMap(() => {
                  return interval(1000).pipe(startWith(-1), take(this.count));
                })
              );
            }

            const samlToken = queryParams[samlTokenParameterName];
            const isNewUser = !!queryParams[samlIsNewUserParameterName];
            if (samlToken) {
              this.fixUrl(samlToken, isNewUser);
            } // NOTE: href navigation (page reload)

            return [];
          })
        )
        .subscribe({
          next: () => {
            if (this.count === COUNTDOWN - 1) {
              localStorage.removeItem('mobile');
            }
            this.count--;
          },
          complete: () => {
            if (this.count !== 0 || this.isDestroying) {
              return;
            }
            this.authModel.isLogged$.pipe(take(1)).subscribe((isLogged) => {
              this.routerModel.actions.dispatch.navigate({
                commands: [isLogged ? '/default' : '/login'],
                extras: { queryParamsHandling: 'merge', queryParams: this.queryParamsNavigationCleanUp },
              });
            });
          },
        })
    );
  }

  fixUrl = (samlToken: string, isNewUser: boolean) => {
    localStorage.setItem('saml', 'true');
    localStorage.setItem('mobile', 'true');

    const isIos = localStorage.getItem('platform') === 'ios';
    const path = isIos ? iosSamlSuccessPath : this.appURL.concat(samlSuccessRoute);
    const pathParams = `?${samlMobileTokenParameterName}=${samlToken}&${samlIsNewUserParameterName}=${isNewUser.toString()}`;

    this.window.location.href = `${path}${pathParams}`;
  };

  execSamlSignIn$ = (provider: string) => {
    localStorage.setItem('saml', 'true');
    this.isNavigating = true;
    this.routerModel.actions.dispatch.navigate({ commands: ['/saml'], extras: { queryParams: { providerId: null, providerName: null } } });
    return this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      take(1),
      tap(() => {
        this.authModel.actions.dispatch.samlSignIn({ provider });
      })
    );
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.isDestroying = true;
  }
}
