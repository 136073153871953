import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MessageModule } from 'primeng/message';
import { InfoMessageComponent } from './info-message/info-message.component';



@NgModule({
  declarations: [
    InfoMessageComponent
  ],
  imports: [
    CommonModule,
    MessageModule
  ],
  exports: [
    InfoMessageComponent
  ]
})
export class InfoMessageModule { }
