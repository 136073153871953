import { IAccessRules } from '../access-rules';
import { BookingStatus, CheckInStatus, DeskAreaType } from '../../enums';
import { IWorkspace } from '../offices/workspace';
import { IDeskAreaItemUser } from '../desk-area-item-user';
import { IDeskAreaForBookingSummary } from '../desk-area';
import { IGuestUser } from './booking-guest';
import { IAssignedSpaceAccessInfo } from '../assigned-space-access-info';

export interface IBookingViewProps {
  id: string;
  bookingStartTime: number;
  bookingEndTime: number;
  bookingAutoCancellationTime: number;
  isDayPass: boolean;
  checkInAvailableFrom: number;
  checkInStatus: CheckInStatus;
  bookingStatus: BookingStatus;

  workspaceId: string;
  workspace: Pick<IWorkspace, 'address'>;

  resourceId: string;
  resource: {
    type: DeskAreaType;
    name: string;
    accessRules?: IAccessRules;
  };

  guest?: Pick<IGuestUser, 'firstName' | 'lastName'>;
  guestId?: number;

  zoneItemId: number;
  zoneItemName: string;

  zoneItem?: {
    id: number;
    name: string;
    accessInfo?: Pick<IAssignedSpaceAccessInfo, 'type'>;
    resourceType: DeskAreaType;
    status: 'active' | 'inactive';
  };
}

export interface IBookingUserProfileViewProps {
  id: string;
  bookingStartTime: number;
  bookingEndTime: number;
  isDayPass: boolean;

  //** Office id */
  workspaceId: string;
  workspace: Pick<IWorkspace, 'address'>;
  //** Area id */
  resourceId: string;
  //** Area item id */
  zoneItemId: string;
  zoneItemName: string;
  userId: string;

  zoneItem: {
    id: number;
    name: string;
    accessInfo?: Pick<IAssignedSpaceAccessInfo, 'allowOthersToBook' | 'type'>;
    status: 'active' | 'inactive';
    user?: IDeskAreaItemUser;
  };

  resource: IDeskAreaForBookingSummary;
}

export type IBookingTimeParams =
  | (Pick<IBookingViewProps, 'isDayPass' | 'bookingStartTime' | 'bookingEndTime'> & {
      workspace: Pick<IWorkspace, 'address'>;
    })
  | (Pick<IBookingUserProfileViewProps, 'isDayPass' | 'bookingStartTime' | 'bookingEndTime'> & {
      workspace: Pick<IWorkspace, 'address'>;
    });
