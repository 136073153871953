import { Inject, Injectable, Optional } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider, OAuthProvider, SAMLAuthProvider, User as FirebaseUser } from 'firebase/auth';

import { AuthModel } from './model';
import { AuthService } from '../services/auth.service';
import {
  catchError,
  combineLatest,
  map,
  of,
  switchMap,
  zip,
  Observable,
  take,
  asyncScheduler,
  throwError,
  from,
  takeUntil,
  merge,
  race,
  withLatestFrom,
  filter,
  firstValueFrom,
  switchMapTo,
  mergeMap,
  debounceTime,
  defer,
  delay,
} from 'rxjs';
import { RouterModel } from 'router-module';
import {
  APP_URL,
  WINDOW,
  samlSuccessRoute,
  samlIsNewUserParameterName,
  samlTokenParameterName,
  SentryService,
  DebugService,
  CompanyStatus,
  samlMobileTokenParameterName,
  GoogleTagManagerService,
  estimateTimeFormats,
} from 'common-module';
import { AUTO_LOGIN_CREDENTIALS } from '../../injection-tokens';
import { NotificationModel, NotificationType } from 'notification-module';
import { FirebaseError } from 'firebase/app';
import { TeamsService } from '../services/teams.service';
import { IPostRegistrationPayload } from '../interfaces-and-types';
import moment from 'moment';
import { prepareUserNames } from '../utils';
import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { getAbbreviation } from 'shared';
import { UserRole, TimeFormat, IUser } from 'types';

@Injectable({
  providedIn: 'root',
})
export class AuthEffects {
  googleProvider = new GoogleAuthProvider().setCustomParameters({ prompt: 'select_account' });
  microsoftProvider = new OAuthProvider('microsoft.com').setCustomParameters({ prompt: 'select_account' });

  getLoginCustomError(error: FirebaseError): string {
    const fireErrorCode = error?.code;

    if (fireErrorCode === 'auth/wrong-password') {
      return $localize`:@@auth-module|error-login-wrong-pass:Wrong password`;
    }
    if (fireErrorCode === 'auth/user-not-found') {
      return $localize`:@@auth-module|error-login-user-not-found:Wrong email address`;
    }
    if (fireErrorCode === 'auth/too-many-requests') {
      return $localize`:@@auth-module|error-login-many-requests:Account blocked due to unusual activity - try again later`;
    }

    return $localize`:@@auth-module|error-login-failed:Login failed`;
  }

  init = createEffect(() =>
    this.actions$.pipe(
      ofType('@ngrx/router-store/request'),
      take(1),
      map(
        ({
          payload: {
            event: { url: initialUrl },
          }
        }) => this.authModel.actions.create.authenticate({ initialUrl })
      )
    )
  );

  authenticate = createEffect(() =>
    this.authModel.actions.listen.authenticate$.pipe(
      switchMap((initialUrlData) =>
        zip(
          this.routerModel.selectors.url$.pipe(
            debounceTime(0),
            filter((val) => typeof val === 'string'),
            take(1)
          ),
          from(this.fireAuthService.getRedirectResult()).pipe(catchError((error) => of(error as HttpErrorResponse))),
          [initialUrlData],
          this.fireAuthService.user
        ).pipe(take(1))
      ),
      switchMap(([storeUrl, redirectResultOrErrorDeprecated, initialUrlData, fireUser]) => {
        // !!! redirectResultOrErrorDeprecated is always null - it stopped working at some point, so we need to use the fireAuthService.user instead

        const userUid = fireUser?.uid;
        const isSamlLogin = !!fireUser?.providerData.find((i) => i?.providerId.includes('saml'));
        const isSamlTokenPage =
          storeUrl.includes('saml') && (storeUrl.includes(samlTokenParameterName) || storeUrl.includes(samlMobileTokenParameterName));
        let url = storeUrl;
        if (initialUrlData && initialUrlData?.initialUrl) {
          url = initialUrlData.initialUrl;
        }

        const isOnFreeTrial = url.includes('free-trial');
        this.debugService.send(`url: ${url}`);

        if (redirectResultOrErrorDeprecated instanceof HttpErrorResponse) {
          const error = redirectResultOrErrorDeprecated as HttpErrorResponse;
          this.sentryErrorHandler.handleError({
            ...error,
            message: `Firebase redirectResult error ${fireUser ? fireUser.uid : ''} ${error.message}`,
          });
        }

        const redirectResult = redirectResultOrErrorDeprecated as any;

        const userData$: Observable<{
          isNewUser: boolean;
          customToken: string | null;
          user: FirebaseUser | null;
        }> = defer(() => {
          if (!userUid) {
            return of({ isNewUser: false, customToken: null, user: null });
          }

          const userFromRedirectOrFireAuth = (redirectResult.user as FirebaseUser) || fireUser || null;
          if (isSamlLogin) {
            const requestedUser = {
              id: userUid,
              email: fireUser.email || redirectResult?.user?.email || '',
              firstName: (redirectResult?.additionalUserInfo?.profile as any)?.first_name || '',
              lastName: (redirectResult?.additionalUserInfo?.profile as any)?.last_name || '',
              profile: redirectResult?.additionalUserInfo?.profile,
            };
            return this.authService.authSaml(requestedUser).pipe(
              map((resp) => {
                if (resp.error || !resp.success) {
                  return { isNewUser: false, customToken: null, user: null };
                }

                const isNewUser = !!resp.data?.isNewUser;
                const customToken = !!localStorage.getItem('saml') && !isSamlTokenPage ? resp.data?.customToken : null;
                this.debugService.send(
                  `resp customToken: ${resp.data?.customToken} - saml in storage : ${localStorage.getItem(
                    'saml'
                  )} - samlTokenPage: ${isSamlTokenPage}`
                );

                return { isNewUser, customToken, user: userFromRedirectOrFireAuth };
              }),
              catchError((samlError) => {
                console.error(samlError);
                return [{ isNewUser: false, customToken: null, user: null }];
              })
            );
          }
          return of({
            isNewUser: !!redirectResult.additionalUserInfo?.isNewUser,
            customToken: null,
            user: userFromRedirectOrFireAuth,
          });
        }).pipe(
          catchError((error) => {
            console.error(error);
            return of({ isNewUser: false, customToken: null, user: null });
          })
        );

        return userData$.pipe(
          switchMap((data) => {
            return zip(this.authService.getUser().pipe(catchError(() => of(null))), this.fireAuthService.idTokenResult.pipe(take(1)), [
              { data },
            ]).pipe(take(1));
          }),
          switchMap(([user, idTokenResult, userData]) => (user && fireUser ? [{ user, fireUser, idTokenResult, userData }] : [null])),
          take(1),
          map((userData) => [userData, isOnFreeTrial, isSamlLogin] as const)
        );
      }),
      switchMap((userData) =>
        zip(
          this.authService.getCorporateInfo().pipe(
            catchError((err) => [err]),
            take(1)
          ),
          this.routerModel.selectors.url$.pipe(
            take(1),
            map((url) => [...userData, url] as const)
          ),
          this.routerModel.selectors.queryParams$
        )
      ),
      switchMap(([corporateInfoResponse, [userData, isOnFreeTrial, isSamlLogin, url], queryParams]) => {
        this.debugService.send(`resp customToken: ${userData}`);
        const { redirectUrl = null } = queryParams;
        const isMicrosoftLogin = userData?.fireUser.providerData.find((i) => i?.providerId.includes('microsoft.com'));
        const isGoogleLogin = isMicrosoftLogin ? false : userData?.fireUser.providerData.find((i) => i?.providerId.includes('google.com'));

        let skipProfileImageUpload = false;

        let userWithVerifiedEmail: IUser | null = null;

        const isMobile = localStorage.getItem('mobile');
        if (isMobile && userData?.userData.data.customToken) {
          // For mobile we need to set the custom token in the url as a query parameter so the mobile app can use it
          // in order to sign in the user.
          const { isDefaultBackendLastName } = prepareUserNames(userData.user.firstName, userData.user.lastName);
          // Do not use Angular router to navigate
          // as the native web view in the mobile apps
          // needs to consume the token parameter from URL

          const newHref = userData?.userData.data.customToken
            ? this.appURL
                .concat(samlSuccessRoute)
                .concat(`?${samlTokenParameterName}=`)
                .concat(userData.userData.data.customToken)
                .concat(userData.userData.data.isNewUser || isDefaultBackendLastName ? `&${samlIsNewUserParameterName}=true` : '')
                .concat(('&userId=' + userData.user.id) as string)
            : this.appURL.concat(samlSuccessRoute);

          this.window.location.href = newHref;
          return [];
        }

        if (!!userData?.user.id && (isMicrosoftLogin || isGoogleLogin || isSamlLogin || userData.fireUser.emailVerified)) {
          userWithVerifiedEmail = userData.user;
        }

        if (!userWithVerifiedEmail) {
          if (this.autoLoginCredentials && this.autoLoginCredentials.email && this.autoLoginCredentials.password) {
            const { email, password } = this.autoLoginCredentials;
            return [this.authModel.actions.create.login({ email, password })];
          }

          const corporateInfoError = 'error' in corporateInfoResponse;
          if (!userData || !userData.user || !userData.fireUser.email || corporateInfoError) {
            this.fireAuthService.signOut();
            const isSamlRoute = url.includes('saml');
            const search = this.window.location.search.replace(/returnUrl=[^&]+/g, '');
            const windowUrl = `${this.window.location.pathname}${search}`;
            const isSlackLoginPage = url.includes('slack');
            const queryParams =
              windowUrl.includes('/login') || ['', '/'].includes(windowUrl) || isSlackLoginPage ? {} : { redirectUrl: windowUrl };
            const goToMaintenance = corporateInfoResponse.status === 503;
            if (redirectUrl) {
              queryParams['redirectUrl'] = redirectUrl;
            }

            return merge(
              [this.authModel.actions.create.setUser({ user: null })],
              from(
                isSamlRoute || isOnFreeTrial || goToMaintenance
                  ? []
                  : [
                      this.routerModel.actions.create.navigate({
                        commands: [goToMaintenance ? '/maintenance' : '/login'],
                        extras: { queryParamsHandling: 'merge', queryParams },
                      }),
                    ]
              ).pipe(delay(0))
            );
          }

          const creationTimeMoment = corporateInfoResponse?.data.trialStartDate
            ? moment(corporateInfoResponse.data.trialStartDate).utc()
            : moment().add('1', 'day');
          const minDiff = moment().diff(creationTimeMoment, 'minutes');
          if (minDiff >= 120 || minDiff < 0) {
            return [
              this.authModel.actions.create.setUser({ user: null, isNewUser: !!userData?.userData?.data?.isNewUser }),
              this.authModel.actions.create.setEmail({ email: userData.fireUser.email }),
              this.routerModel.actions.create.navigate({
                commands: ['/login/verify-email'],
                extras: { queryParamsHandling: 'preserve' },
              }),
            ];
          }
          userWithVerifiedEmail = userData.user;
          skipProfileImageUpload = true;
        }

        if (!userData?.user) {
          return merge(
            [this.authModel.actions.create.setUser({ user: null })],
            of(this.routerModel.actions.create.navigate({ commands: ['/login'], extras: { queryParamsHandling: 'preserve' } })).pipe(
              delay(0)
            )
          );
        }

        if (isSamlLogin && userData?.userData.data.isNewUser) {
          const { firstName, lastName } = prepareUserNames(userWithVerifiedEmail.firstName, userWithVerifiedEmail.lastName);
          const initials = getAbbreviation(`${firstName.trim()} ${lastName.trim()} `);

          const isMobileSAMLLogin = localStorage.getItem('mobile');
          return [
            this.authModel.actions.create.setSamlUserName({
              userId: userData.user.id.toString(),
              firstName: userData?.user?.firstName,
              lastName: userData?.user?.lastName,
            }),
            this.authModel.actions.create.setEmail({ email: userWithVerifiedEmail.email }),
            this.authModel.actions.create.setUser({ user: null, isNewUser: !!userData?.userData?.data?.isNewUser }),
            this.authModel.actions.create.setInitials({ initials }),
            ...(isMobileSAMLLogin
              ? []
              : [
                  this.routerModel.actions.create.navigate({
                    commands: ['/login/sign-up-wizard'],
                    extras: { queryParamsHandling: 'merge', queryParams: { redirectUrl: redirectUrl || undefined } },
                  }),
                ]),
          ];
        }

        if (!userWithVerifiedEmail.profileImage && userWithVerifiedEmail.signup && !skipProfileImageUpload) {
          const isMobileSAMLLogin = localStorage.getItem('mobile');

          const { firstName, lastName, isDefaultBackendLastName } = prepareUserNames(
            userWithVerifiedEmail.firstName,
            userWithVerifiedEmail.lastName
          );
          const page = isDefaultBackendLastName && isSamlLogin ? undefined : 'upload-avatar';
          const initials = getAbbreviation(`${firstName.trim()} ${lastName.trim()} `);

          const setSAMLWizardData =
            isDefaultBackendLastName && isSamlLogin
              ? [
                  this.authModel.actions.create.setSamlUserName({
                    userId: userWithVerifiedEmail.id.toString(),
                    firstName,
                    lastName,
                  }),
                ]
              : [];
          return [
            ...setSAMLWizardData,
            this.authModel.actions.create.setEmail({ email: userWithVerifiedEmail.email }),
            this.authModel.actions.create.setUser({ user: null, isNewUser: !!userData?.userData?.data?.isNewUser }),
            this.authModel.actions.create.setInitials({ initials }),
            ...(isMobileSAMLLogin
              ? []
              : [
                  this.routerModel.actions.create.navigate({
                    commands: ['/login/sign-up-wizard'],
                    extras: { queryParamsHandling: 'merge', queryParams: { redirectUrl: redirectUrl || undefined, page } },
                  }),
                ]),
          ];
        }

        return of(corporateInfoResponse).pipe(
          switchMap((response) => {
            if (response == null || !response.success) {
              console.error(response);
              this.fireAuthService.signOut();
              return [
                this.authModel.actions.create.setUser({ user: null }),
                this.routerModel.actions.create.navigate({
                  commands: ['/login'],
                  extras: { queryParamsHandling: 'merge', queryParams: { redirectUrl: redirectUrl || undefined } },
                }),
                this.notificationModel.actions.create.showNotification({
                  data: $localize`: @@auth-module|error-corporate-info-failure:Cannot fetch corporate info`,
                  notificationType: NotificationType.ERROR,
                }),
              ];
            }
            const corporateInfo = response.data;
            const isDeskbirdAdmin = userData?.idTokenResult?.claims?.['group'] === 'admin';

            if (userWithVerifiedEmail && corporateInfo) {
              this.googleTagManager.initAuthAndCompanyData(userWithVerifiedEmail, corporateInfo);
            }

            const actions: Action[] = [
              this.authModel.actions.create.freeTrialData({
                freeTrialStartDate: response.data.status === CompanyStatus.Trial ? response.data.trialStartDate : null,
                freeTrialEndDate: response.data.status === CompanyStatus.Trial ? response.data.trialEndDate : null,
              }),
              this.authModel.actions.create.loginSuccess({ user: userWithVerifiedEmail, isDeskbirdAdmin, corporateInfo }),
            ];
            if (redirectUrl) {
              actions.push(this.routerModel.actions.create.navigateByUrl({ url: redirectUrl }));
            }
            return actions;
          }),
          catchError((error) => {
            console.error(error);
            this.fireAuthService.signOut();
            return [
              this.authModel.actions.create.setUser({ user: null }),
              this.routerModel.actions.create.navigate({ commands: ['/login'], extras: { queryParamsHandling: 'preserve' } }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`: @@auth-module|error-corporate-info-failure:Cannot fetch corporate info`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          })
        );
      })
    )
  );

  checkEmail = createEffect(() =>
    this.authModel.actions.listen.checkEmail$.pipe(
      switchMap(({ email }) =>
        this.authService.checkEmail(email).pipe(
          switchMap(({ data, success, error }) =>
            success
              ? [this.authModel.actions.create.checkEmailSuccess({ emailCheckResult: { ...data, email } })]
              : throwError(() => {
                  return new Error(`Error in response - ${error} `);
                })
          ),
          catchError((err) => {
            this.sentryErrorHandler.handleError(err);
            if (err.status === 503) {
              return [this.authModel.actions.create.checkEmailFailure({ error: err, email })];
            } else {
              return [
                this.notificationModel.actions.create.showNotification({
                  data: $localize`:@@common|error-check-email:Error checking email`,
                  notificationType: NotificationType.ERROR,
                }),
                this.authModel.actions.create.checkEmailFailure({ error: err, email }),
              ];
            }
          })
        )
      )
    )
  );

  loadPublicDomainsList = createEffect(() =>
    this.authModel.actions.listen.loadPublicDomains$.pipe(
      switchMap(() =>
        this.authService.loadPublicDomainsList().pipe(
          takeUntil(this.authModel.actions.listen.loadPublicDomainsCancel$),
          map((publicDomains) => this.authModel.actions.create.loadPublicDomainsSuccess({ publicDomains })),
          catchError((error) => {
            this.sentryErrorHandler.handleError(error);
            return [this.authModel.actions.create.loadPublicDomainsFailure({ error })];
          })
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.authModel.actions.listen.login$.pipe(
      switchMap(({ email, password, sync }) =>
        from(
          this.fireAuthService.signInWithEmailAndPassword(email, password).then((result) => ({ result, credentials: sync?.credentials }))
        ).pipe(
          switchMap(({ result, credentials }) => {
            if (credentials) {
              result.user?.linkWithCredential(credentials);
            }
            return zip(this.fireAuthService.currentUser, this.authService.getCorporateInfo());
          }),
          switchMap(([fireUser, corporateInfo]) => {
            if (!fireUser || !fireUser.email) {
              this.fireAuthService.signOut();
              return [
                this.authModel.actions.create.setEmailCleanup(),
                this.routerModel.actions.create.navigate({
                  commands: ['/login'],
                  extras: { queryParamsHandling: 'preserve' },
                }),
                this.notificationModel.actions.create.showNotification({
                  data: $localize`: @@auth-module|error-email-not-found:Email not found`,
                  notificationType: NotificationType.ERROR,
                }),
              ];
            }

            const creationTimeMoment = moment(corporateInfo.data.trialStartDate).utc();
            const minDiff = moment().diff(creationTimeMoment, 'minutes');

            const canProceedWithInactiveEmail = corporateInfo.data.status === CompanyStatus.Trial && minDiff < 120;

            if (!fireUser.emailVerified && !canProceedWithInactiveEmail) {
              return [
                this.notificationModel.actions.create.showNotification({
                  data: $localize`: @@auth-module|error-email-not-verified:Email not verified`,
                  notificationType: NotificationType.ERROR,
                }),
                this.routerModel.actions.create.navigate({
                  commands: ['/login/verify-email'],
                  extras: { queryParamsHandling: 'preserve' },
                }),
              ];
            }

            return combineLatest([
              [corporateInfo],
              this.authService.getUser(),
              this.fireAuthService.idTokenResult.pipe(take(1)),
              this.routerModel.selectors.queryParams$,
            ]).pipe(
              take(1),
              switchMap(([corporateInfoResponse, user, idTokenResult, queryParams]) => {
                let { redirectUrl = null } = queryParams;
                if (!!localStorage.getItem('slack-login')) {
                  redirectUrl = '/slack';
                }
                const { success = true, message, data: corporateInfo } = corporateInfoResponse;
                if (!success && message) {
                  return throwError(() => {
                    return new Error(`Error in response - ${message} `);
                  });
                }

                if (!user.profileImage && user.signup) {
                  const { firstName, lastName, avatarColor, profileImage } = user;
                  const initials = getAbbreviation(`${firstName} ${lastName} `);
                  return [
                    this.authModel.actions.create.freeTrialData({
                      freeTrialStartDate: corporateInfo.status === CompanyStatus.Trial ? corporateInfo.trialStartDate : null,
                      freeTrialEndDate: corporateInfo.status === CompanyStatus.Trial ? corporateInfo.trialEndDate : null,
                    }),
                    this.authModel.actions.create.setInitials({ initials, avatarColor }),
                    this.authModel.actions.create.setProfileImage({ profileImage }),
                    this.routerModel.actions.create.navigate({
                      commands: ['/login/sign-up-wizard'],
                      extras: { queryParams: { page: 'upload-avatar' }, queryParamsHandling: 'merge' },
                    }),
                  ];
                }

                const isDeskbirdAdmin = idTokenResult?.claims?.['group'] === 'admin';

                if (user && corporateInfo) {
                  this.googleTagManager.initAuthAndCompanyData(user, corporateInfo);
                }

                return merge(
                  [
                    this.authModel.actions.create.checkEmailCleanup(),
                    this.authModel.actions.create.freeTrialData({
                      freeTrialStartDate: corporateInfo.status === CompanyStatus.Trial ? corporateInfo.trialStartDate : null,
                      freeTrialEndDate: corporateInfo.status === CompanyStatus.Trial ? corporateInfo.trialEndDate : null,
                    }),
                    this.authModel.actions.create.loginSuccess({
                      user,
                      corporateInfo: corporateInfo,
                      isDeskbirdAdmin: isDeskbirdAdmin,
                      isNewUser: false,
                    }),
                  ],
                  of(
                    redirectUrl
                      ? this.routerModel.actions.create.navigateByUrl({ url: redirectUrl })
                      : this.routerModel.actions.create.navigate({ commands: ['/default'], extras: { queryParamsHandling: 'preserve' } })
                  )
                );
              }),
              catchError((error) => {
                this.sentryErrorHandler.handleError(error);
                this.fireAuthService.signOut();
                return [
                  this.authModel.actions.create.setEmailCleanup(),
                  this.routerModel.actions.create.navigate({ commands: ['/login'], extras: { queryParamsHandling: 'preserve' } }),
                  this.notificationModel.actions.create.showNotification({
                    data: $localize`: @@auth-module|error-user-data-fetch-error:Cannot fetch user data`,
                    notificationType: NotificationType.ERROR,
                  }),
                ];
              })
            );
          }),
            catchError((error) => {
              const errorMessage =
                error instanceof FirebaseError
                  ? this.getLoginCustomError(error)
                  : $localize`: @@auth-module|error-login-failed:Login failed`;

              return [
                this.notificationModel.actions.create.showNotification({
                  data: errorMessage,
                  notificationType: NotificationType.ERROR,
                }),
                this.authModel.actions.create.loginFailure({ error }),
              ];
            })
        )
      )
    )
  );

  register$ = createEffect(() =>
    this.authModel.actions.listen.register$.pipe(
      switchMap(({ email, password, firstName, lastName }) =>
        this.fireAuthService
          .createUserWithEmailAndPassword(email, password)
          .then(({ user: fireUser }) => {
            if (!fireUser) {
              throw new Error('Missing data');
            }
            return this.authService
              .postRegistration({ firstName, lastName, email, firebaseId: fireUser.uid }, false)
              .pipe(
                take(1),
                map((d) => fireUser)
              )
              .toPromise() as Promise<firebase.default.User>;
          })
          .then((fireUser) => {
            return Promise.all([
              this.authService.getUser().pipe(take(1)).toPromise() as Promise<IUser>,
              fireUser.sendEmailVerification().catch((err) => {
                console.error(err);
                return null;
              }),
            ]);
          })
          .then(([user]) => this.authModel.actions.create.registerSuccess({ user }))
          .catch((error) => {
            this.sentryErrorHandler.handleError(error);
            return this.authModel.actions.create.registerFailure({ error });
          })
      ),
      withLatestFrom(this.routerModel.selectors.queryParams$),
      switchMap(([result, { redirectUrl = null }]) => {
        if ('error' in result) {
          return [
            result,
            this.notificationModel.actions.create.showNotification({
              data: $localize`: @@auth-module|register-failed:Registration failed`,
              notificationType: NotificationType.ERROR,
            }),
          ];
        }
        const actions: Action[] = [result];
        if (redirectUrl) {
          actions.push(this.routerModel.actions.create.navigateByUrl({ url: redirectUrl }));
        }
        return actions;
      })
    )
  );

  freeTrialRegister$ = createEffect(() =>
    this.authModel.actions.listen.freeTrialRegister$.pipe(
      switchMap(({ email, password, firstName, lastName }) =>
        this.fireAuthService
          .createUserWithEmailAndPassword(email, password)
          .then(({ user: fireUser }) => {
            if (!fireUser) {
              throw new Error('Missing data');
            }
            return firstValueFrom(
              this.authService.postRegistration({ firstName, lastName, email, firebaseId: fireUser.uid, role: UserRole.ADMIN }, false).pipe(
                take(1),
                map((d) => fireUser)
              )
            );
          })
          .then((fireUser) =>
            Promise.all([
              fireUser,
              firstValueFrom(this.authService.getUser().pipe(take(1))),
              firstValueFrom(this.fireAuthService.idTokenResult.pipe(take(1))),
              fireUser.sendEmailVerification().catch((err) => {
                console.error(err);
                return null;
              }),
            ])
          )
          .then(([fireUser, user, idTokenResult]) => {
            return this.authModel.actions.create.freeTrialRegisterSuccess({
              user,
              isEmailVerified: fireUser.emailVerified,
              isDeskbirdAdmin: idTokenResult?.claims['group'] === 'admin',
            });
          })
          .catch((error) => {
            this.sentryErrorHandler.handleError(error);
            return this.authModel.actions.create.freeTrialRegisterFailure({ error });
          })
      ),
      switchMap((result) => {
        if ('error' in result) {
          return [
            result,
            this.notificationModel.actions.create.showNotification({
              data: $localize`: @@auth-module|register-failed:Registration failed`,
              notificationType: NotificationType.ERROR,
            }),
          ];
        }

        return [result];
      })
    )
  );

  signInWithSamlRedirect$ = createEffect(() =>
    this.authModel.actions.listen.samlSignIn$.pipe(
      switchMap(({ provider }) => {
        const samlProvider = new SAMLAuthProvider(provider);

        return this.fireAuthService
          .signInWithRedirect(samlProvider)
          .then(() => this.authModel.actions.create.samlSignInSuccess({ user: null }))
          .catch((error) => {
            this.sentryErrorHandler.handleError(error);
            return this.authModel.actions.create.samlSignInFailure({ error });
          });
      })
    )
  );

  signInWithGoogle$ = createEffect(() =>
    this.authModel.actions.listen.googleSignIn$.pipe(
      switchMap(() =>
        from(this.fireAuthService.signInWithPopup(this.googleProvider)).pipe(
          switchMap(({ user: fireUser, credential, additionalUserInfo }) => {
            if (!fireUser || !additionalUserInfo || !credential || !additionalUserInfo.profile) {
              return throwError(() => {
                return new Error('Missing data');
              });
            }
            const { isNewUser, profile } = additionalUserInfo;

            const postRegPayload: IPostRegistrationPayload = {
              firstName: (profile as any).given_name,
              lastName: (profile as any).family_name,
              email: fireUser.email as string,
              firebaseId: fireUser.uid,
            };

            if (isNewUser) {
              return this.authService.postRegistration(postRegPayload, true).pipe(
                take(1),
                switchMap(() => fireUser.reload()),
                switchMap(() =>
                  combineLatest([
                    this.fireAuthService.currentUser,
                    of((credential as any).accessToken),
                    of(true),
                    this.fireAuthService.idTokenResult.pipe(take(1)),
                  ])
                )
              );
            }

            return combineLatest([
              of(fireUser),
              of((credential as any).accessToken),
              of(false),
              this.fireAuthService.idTokenResult.pipe(take(1)),
            ]);
          }),
          switchMap(([fireUser, accessToken, isNewUser, idTokenResult]) => {
            const userObserver = accessToken
              ? this.authService.syncGoogle(accessToken).pipe(take(1))
              : this.authService.getUser().pipe(
                  take(1),
                  map((user) => ({ user }))
                );
            return combineLatest([
              of(fireUser),
              userObserver,
              of(isNewUser),
              this.authModel.selectors.emailCheckResult$,
              this.authService.getCorporateInfo().pipe(
                switchMap((res) =>
                  res.success
                    ? [res.data]
                    : throwError(() => {
                        return new Error('Missing data');
                      })
                )
              ),
              [idTokenResult],
              this.routerModel.selectors.queryParams$,
            ]).pipe(take(1));
          }),
          switchMap(([fireUser, authUser, isNewUser, emailCheckResult, corporateInfo, idTokenResult, queryParams]) => {
            let { redirectUrl = null } = queryParams;
            if (!!localStorage.getItem('slack-login')) {
              redirectUrl = '/slack';
            }

            const user = authUser?.user;

            if (fireUser) {
              this.authModel.actions.dispatch.updateEmailCheckResultVerified({ isVerified: fireUser.emailVerified });
            }

            if (!user) {
              return [this.authModel.actions.create.googleSignInFailure({ error: { code: 'USER_NOT_FOUND' }, user: authUser as any })];
            }

            const isDeskbirdAdmin = idTokenResult?.claims?.['group'] === 'admin';
            const actions: Action[] = [
              this.authModel.actions.create.googleSignInSuccess({ user, isNewUser, corporateInfo, isDeskbirdAdmin }),
            ];
            this.googleTagManager.initAuthAndCompanyData(user, corporateInfo);

            if (redirectUrl) {
              actions.push(this.routerModel.actions.create.navigateByUrl({ url: redirectUrl }));
            }
            if (isNewUser && fireUser?.email && emailCheckResult?.email !== fireUser.email) {
              this.authModel.actions.dispatch.checkEmail({ email: fireUser.email });
              return race(
                this.authModel.actions.listen.checkEmailSuccess$.pipe(take(1)),
                this.authModel.actions.listen.checkEmailFailure$.pipe(take(1))
              ).pipe(
                switchMap((result) => {
                  if ('error' in result) {
                    return [result];
                  }
                  return actions;
                })
              );
            }

            return actions;
          }),
          catchError((error) => {
            if (error?.code === 'auth/account-exists-with-different-credential') {
              return this.authModel.selectors.emailCheckResult$.pipe(
                take(1),
                switchMap((emailCheckResult) => {
                  asyncScheduler.schedule(() => {
                    this.authModel.actions.dispatch.microsoftCredentialsSync({ credentials: error.credential });
                  });

                  const actions = [
                    this.routerModel.actions.create.navigate({
                      commands: ['/login/sign-in-email/googl-sync'],
                      extras: { queryParamsHandling: 'preserve' },
                    }),
                  ];
                  if (emailCheckResult?.email !== error.email) {
                    this.authModel.actions.dispatch.checkEmail({ email: error.email as string });
                    return race(
                      this.authModel.actions.listen.checkEmailSuccess$.pipe(take(1)),
                      this.authModel.actions.listen.checkEmailFailure$.pipe(take(1))
                    ).pipe(switchMapTo(actions));
                  }

                  return actions;
                })
              );
            }

            this.sentryErrorHandler.handleError(error);
            return [
              this.authModel.actions.create.googleSignInFailure({ error, user: null }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`: @@auth-module|google-sso-failure:Error signing in with provided google account`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          })
        )
      )
    )
  );

  signInWithMicrosoft$ = createEffect(() =>
    this.authModel.actions.listen.microsoftSignIn$.pipe(
      switchMap(() =>
        from(this.fireAuthService.signInWithPopup(this.microsoftProvider)).pipe(
          withLatestFrom(this.authModel.selectors.emailCheckResult$),
          switchMap(([{ user: fireUser, credential, additionalUserInfo }, emailCheckResult]) => {
            if (!fireUser || !additionalUserInfo || !credential || !additionalUserInfo.profile) {
              return throwError(() => {
                return new Error('Missing data');
              });
            }

            const { isNewUser, profile } = additionalUserInfo;

            const postRegPayload: IPostRegistrationPayload = {
              firstName: (profile as any).givenName,
              lastName: (profile as any).surname,
              email: fireUser.email as string,
              firebaseId: fireUser.uid,
            };

            if (isNewUser) {
              const postRegistration = this.authService.postRegistration(postRegPayload, true).pipe(
                take(1),
                switchMap(() => fireUser.reload()),
                switchMap(() => combineLatest([of(fireUser), of((credential as any).accessToken)]))
              );

              if (fireUser.email !== emailCheckResult?.email) {
                this.authModel.actions.dispatch.checkEmail({ email: fireUser.email as string });
                return race(
                  this.authModel.actions.listen.checkEmailSuccess$.pipe(take(1)),
                  this.authModel.actions.listen.checkEmailFailure$.pipe(take(1))
                ).pipe(
                  switchMap((result) => {
                    if ('error' in result) {
                      const { error } = result;
                      return throwError(() => error);
                    }
                    return postRegistration;
                  })
                );
              }

              return postRegistration;
            }

            return combineLatest([of(fireUser), of((credential as any).accessToken), of(isNewUser)]);
          }),
            switchMap(([fireUser, accessToken, isNewUser]) => {
              const userObserver = accessToken ? this.authService.syncMicrosoft(accessToken).pipe(take(1)) : this.authService.getUser();
              return combineLatest([
                of(fireUser),
                userObserver,
                this.authService.getCorporateInfo().pipe(
                  switchMap((res) =>
                    res.success
                      ? [res.data]
                      : throwError(() => {
                          return new Error('Missing data');
                        })
                  )
                ),
                this.fireAuthService.idTokenResult.pipe(take(1)),
                [!!isNewUser],
                this.routerModel.selectors.queryParams$,
              ]).pipe(take(1));
            }),
            switchMap(([fireUser, authUser, corporateInfo, idTokenResult, isNewUser, queryParams]) => {
              this.authModel.actions.dispatch.updateEmailCheckResultVerified({ isVerified: fireUser.emailVerified });
              const user = (authUser as any).user;
              if (user && corporateInfo) {
                this.googleTagManager.initAuthAndCompanyData(user, corporateInfo);
              }
              const isDeskbirdAdmin = idTokenResult?.claims?.['group'] === 'admin';
              const actions: Action[] = [
                this.authModel.actions.create.microsoftSignInSuccess({ user, corporateInfo, isDeskbirdAdmin, isNewUser }),
              ];
              let { redirectUrl = null } = queryParams;
              if (!!localStorage.getItem('slack-login')) {
                redirectUrl = '/slack';
              }
              if (redirectUrl) {
                actions.push(this.routerModel.actions.create.navigateByUrl({ url: redirectUrl }));
              }
              return actions;
            }),
            catchError((error) => {
              if (error?.code === 'auth/account-exists-with-different-credential') {
                return this.authModel.selectors.emailCheckResult$.pipe(
                  take(1),
                  switchMap((emailCheckResult) => {
                    asyncScheduler.schedule(() => {
                      this.authModel.actions.dispatch.microsoftCredentialsSync({ credentials: error.credential });
                    });

                    const actions = [
                      this.routerModel.actions.create.navigate({
                        commands: ['/login/sign-in-email/msft-sync'],
                        extras: { queryParamsHandling: 'preserve' },
                      }),
                    ];
                    if (emailCheckResult?.email !== error.email) {
                      this.authModel.actions.dispatch.checkEmail({ email: error.email as string });
                      return race(
                        this.authModel.actions.listen.checkEmailSuccess$.pipe(take(1)),
                        this.authModel.actions.listen.checkEmailFailure$.pipe(take(1))
                      ).pipe(switchMapTo(actions));
                    }

                    return actions;
                  })
                );
              }

              this.sentryErrorHandler.handleError(error);
              const errorMessage =
                error instanceof FirebaseError
                  ? this.getLoginCustomError(error)
                  : $localize`: @@auth-module|microsoft-sso-failure-with-error-code:Error signing in with provided microsoft account ${
                      error?.code ? `(${error?.code})` : ''
                    }`;

              return [
                this.notificationModel.actions.create.showNotification({
                  data: errorMessage,
                  notificationType: NotificationType.ERROR,
                }),
                this.authModel.actions.create.microsoftSignInFailure({ error, user: null }),
              ];
            })
        )
      )
    )
  );

  signInWithMicrosoftTeams$ = createEffect(() =>
    this.authModel.actions.listen.microsoftTeamsSignIn$.pipe(
      switchMap((data) =>
        this.teamsService.initialized$.pipe(
          filter((val) => !!val),
          take(1),
          map(() => data),
          switchMap((data) => {
            const getAuthToken = new Promise<{
              token: string | null;
              message: string | null;
              success: boolean;
              data: typeof data;
            }>((resolve) => {
              console.log('dispatching authentication.getAuthToken...');
              this.teamsService.authentication.getAuthToken({
                resources: ['https://web-staging.deskbird.app'],
                successCallback(token: string): void {
                  console.log('authentication.getAuthToken success', token);
                  resolve({ token, message: null, success: true, data });
                },
                failureCallback(message: string): void {
                  console.log('authentication.getAuthToken failure', message);
                  resolve({ token: null, message, success: false, data });
                },
              });
            });

            const op = getAuthToken.then((authTokenRes) => {
              const microsoftToken = authTokenRes?.token;
              if (microsoftToken) {
                return firstValueFrom(this.authService.authenticateWithMicrosoftToken(microsoftToken).pipe(take(1)))
                  .then((result: any) => {
                    console.log('this.authService.authenticateWithMicrosoftToken success', result);
                    return this.fireAuthService.signInWithCustomToken(result.data.customToken).then((firebaseResult) => ({
                      result: firebaseResult,
                      token: result.data.customToken,
                      isNewUser: result.data.isNewUser as boolean,
                      microsoftToken,
                      error: false,
                      errorMessage: '',
                    }));
                  })
                  .catch((err) => {
                    console.log('this.authService.authenticateWithMicrosoftToken failure', err);
                    return { error: true, errorMessage: 'authenticateWithMicrosoftToken failure' } as any;
                  });
              }
              return { error: true, errorMessage: 'failed to get microsoft auth token' } as any;
            });

            return from(op);
          }),
          switchMap((signInResult) => {
            if (!signInResult || signInResult.error) {
              throw new Error(`TEAMS: Missing sign in data${signInResult?.errorMessage ? `: ${signInResult?.errorMessage}` : ''} `);
            }
            const {
              result: { user: fireUser },
              token,
              isNewUser,
              microsoftToken,
            } = signInResult;
            if (!fireUser || !token) {
              throw new Error('TEAMS: Missing user or token data');
            }

            const context = this.teamsService.currentContextValue;
            const catchFn = (err: any) => {
              if (!err.status || err.status !== 404) {
                throw err;
              }
              return firstValueFrom(this.authService.getUser().pipe(take(1)));
            };

            const userPromise =
              token && context && context.user?.tenant?.id
                ? firstValueFrom(this.authService.syncMicrosoftForTeams(microsoftToken, context.user.tenant.id).pipe(take(1)))
                    .then(({ user, success }) => {
                      if (!success) {
                        console.log('this.authService.syncMicrosoftForTeams failure');
                        throw new Error('TEAMS: Error user sync');
                      }
                      console.log('this.authService.syncMicrosoftForTeams success');
                      return Promise.resolve(user);
                    })
                    .catch(catchFn)
                : firstValueFrom(this.authService.getUser().pipe(take(1))).catch(catchFn);

            return Promise.all([
              fireUser,
              userPromise,
              firstValueFrom(
                this.authService.getCorporateInfo().pipe(
                  take(1),
                  switchMap((d) => (d.success ? of(d.data) : throwError(() => d.errorCode)))
                )
              ),
              firstValueFrom(this.fireAuthService.idTokenResult.pipe(take(1))),
              isNewUser,
            ]).then(([fireUser, authUser, corporateInfo, idTokenResult, isNewUser]) => {
              if (!authUser) {
                return Promise.reject(new Error('TEAMS: User is missing'));
              }
              this.googleTagManager.initAuthAndCompanyData(authUser, corporateInfo);

              return this.authModel.actions.create.microsoftTeamsSignInSuccess({
                user: authUser,
                corporateInfo,
                isDeskbirdAdmin: idTokenResult?.claims?.['group'] === 'admin',
                isNewUser,
              });
            });
          }),
            catchError((error) => {
              this.sentryErrorHandler.handleError(error);

              if (error?.code === 'auth/account-exists-with-different-credential') {
                return this.authModel.selectors.emailCheckResult$.pipe(
                  take(1),
                  switchMap((emailCheckResult) => {
                    asyncScheduler.schedule(() => {
                      this.authModel.actions.dispatch.microsoftCredentialsSync({ credentials: error.credential });
                    });

                    const actions = [
                      this.routerModel.actions.create.navigate({
                        commands: ['/login/sign-in-email/msft-sync'],
                        extras: { queryParamsHandling: 'preserve' },
                      }),
                    ];
                    if (emailCheckResult?.email !== error.email) {
                      this.authModel.actions.dispatch.checkEmail({ email: error.email as string });
                      return race(
                        this.authModel.actions.listen.checkEmailSuccess$.pipe(take(1)),
                        this.authModel.actions.listen.checkEmailFailure$.pipe(take(1))
                      ).pipe(switchMapTo(actions));
                    }
                    return actions;
                  })
                );
              }

              console.log('microsoftSignInFailure failure', error);

              let notification = {
                data: $localize`: @@auth-module|microsoft-sso-failure-with-error-code:Error signing in with provided microsoft account ${
                  error?.code ? `(${error?.code})` : ''
                }`,
                notificationType: NotificationType.ERROR,
              };

              switch (error.message) {
                case 'TEAMS: Missing sign in data': {
                  notification = {
                    data: $localize`: @@auth-module|microsoft-teams-sso-error-getting-token:Error getting microsoft teams auth token`,
                    notificationType: NotificationType.ERROR,
                  };
                  break;
                }
                case 'TEAMS: Missing user or token data': {
                  notification = {
                    data: $localize`: @@auth-module|microsoft-teams-sso-error-getting-user-with-token:Error getting user data with provided microsoft teams auth token`,
                    notificationType: NotificationType.ERROR,
                  };
                  break;
                }
                case 'TEAMS: Error user sync': {
                  notification = {
                    data: $localize`: @@auth-module|microsoft-teams-sso-error-user-sync:Error user sync`,
                    notificationType: NotificationType.ERROR,
                  };
                  break;
                }
                case 'TEAMS: User is missing': {
                  notification = {
                    data: $localize`: @@auth-module|microsoft-teams-sso-error-missing-user:User is missing`,
                    notificationType: NotificationType.ERROR,
                  };
                  break;
                }
              }

              return [
                this.notificationModel.actions.create.showNotification(notification),
                this.authModel.actions.create.microsoftTeamsSignInFailure({ error, user: null }),
              ];
            })
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.authModel.actions.listen.logout$.pipe(
      switchMap(() => this.fireAuthService.signOut()),
      switchMap(() => {
        this.googleTagManager.resetAuthAndCompanyData();
        return merge(
          [this.authModel.actions.create.logoutSuccess()],
          of(this.routerModel.actions.create.navigate({ commands: ['/login'] })).pipe(delay(0))
        );
      }),
      catchError((error) => {
        this.sentryErrorHandler.handleError(error);
        return [
          this.authModel.actions.create.loginFailure({ error }),
          this.notificationModel.actions.create.showNotification({
            data: $localize`: @@auth-module|error-logout:Logout failed`,
            notificationType: NotificationType.ERROR,
          }),
        ];
      })
    )
  );

  updateUser$ = createEffect(() =>
    this.authModel.actions.listen.updateUser$.pipe(
      mergeMap(({ updates, userId, noNotification }) =>
        this.authService.updateUser(updates).pipe(
          switchMap(({ data, success, message }) => {
            const notification = [
              success
                ? this.notificationModel.actions.create.showNotification({
                    data: $localize`: @@auth-module|success-update-user:Profile updated successfully`,
                    notificationType: NotificationType.SUCCESS,
                  })
                : this.notificationModel.actions.create.showNotification({
                    data: $localize`: @@auth-module|error-update-user:Profile update failed`,
                    notificationType: NotificationType.ERROR,
                  }),
            ];

            return success
              ? [this.authModel.actions.create.updateUserSuccess({ updates: data, userId }), ...(noNotification ? [] : notification)]
              : [
                  this.authModel.actions.create.updateUserFailure({ error: message, updates: data }),
                  ...(noNotification ? [] : notification),
                ];
          }),
            catchError((error) => {
              const notification = noNotification
                ? []
                : [
                    this.notificationModel.actions.create.showNotification({
                      data: $localize`: @@auth-module|error-update-user:Profile update failed`,
                      notificationType: NotificationType.ERROR,
                    }),
                  ];

              return [this.authModel.actions.create.updateUserFailure({ error, updates }), ...notification];
            })
        )
      )
    )
  );

  loadUserProfile$ = createEffect(() =>
    this.authModel.actions.listen.loadUserProfile$.pipe(
      switchMap(() =>
        this.authService.getUser().pipe(
          switchMap((user) => [this.authModel.actions.create.loadUserProfileSuccess({ user })]),
          catchError((error) => [this.authModel.actions.create.loadUserProfileFailure({ error })])
        )
      )
    )
  );

  passwordReset$ = createEffect(() =>
    this.authModel.actions.listen.passwordReset$.pipe(
      switchMap(({ email }) =>
        this.fireAuthService
          .sendPasswordResetEmail(email)
          .then(() => this.authModel.actions.dispatch.passwordResetSuccess())
          .catch((error) => {
            this.sentryErrorHandler.handleError(error);
            return this.authModel.actions.dispatch.passwordResetFailure({ error });
          })
      ),
      switchMap((result) => {
        if (result && 'error' in result) {
          return [
            result,
            this.notificationModel.actions.create.showNotification({
              data: $localize`: @@auth-module|error-password-reset:Error sending password reset email`,
              notificationType: NotificationType.ERROR,
            }),
          ];
        }
        return [
          result,
          this.notificationModel.actions.create.showNotification({
            data: $localize`: @@auth-module|success-reset-password-mail:Password reset email sent successfully`,
            notificationType: NotificationType.SUCCESS,
          }),
        ];
      })
    )
  );

  signInAfterConfirmation$ = createEffect(() =>
    this.authModel.actions.listen.signInAfterConfirmation$.pipe(
      switchMap(() =>
        this.authService.updateUser({ signup: false }).pipe(
          switchMap((updates) =>
            updates.success
              ? merge(
                  [this.authModel.actions.create.loadAuthData({})],
                  race(
                    this.authModel.actions.listen.loadAuthDataSuccess$.pipe(
                      take(1),
                      takeUntil(this.authModel.actions.listen.loadAuthDataCancel$),
                      map((action) =>
                        this.authModel.actions.create.signInAfterConfirmationSuccess({
                          user: action.user as IUser,
                          corporateInfo: action.corporateInfo,
                          updates: updates.data,
                        })
                      )
                    ),
                    this.authModel.actions.listen.loadAuthDataFailure$.pipe(
                      take(1),
                      takeUntil(this.authModel.actions.listen.loadAuthDataCancel$),
                      map((action) => this.authModel.actions.create.signInAfterConfirmationFailure({ error: action.error }))
                    )
                  )
                )
              : throwError(() => {
                  return new Error(updates.message);
                })
          ),
          catchError((error) => [this.authModel.actions.create.loginFailure({ error })])
        )
      )
    )
  );

  loadAuthData$ = createEffect(() =>
    this.authModel.actions.listen.loadAuthData$.pipe(
      switchMap((payload) =>
        (!payload.user
          ? zip(this.authService.getUser(), this.authService.getCorporateInfo(), this.fireAuthService.idTokenResult.pipe(take(1)))
          : zip(of(payload.user), this.authService.getCorporateInfo(), this.fireAuthService.idTokenResult.pipe(take(1)))
        ).pipe(
          switchMap(([user, corporateInfo, idTokenResult]) => {
            if (corporateInfo.errorCode || !user) {
              return throwError(() => ({ status: corporateInfo.errorCode }));
            }
            return [
              this.authModel.actions.create.loadAuthDataSuccess({
                user,
                corporateInfo: corporateInfo.data,
                isDeskbirdAdmin: idTokenResult?.claims?.['group'] === 'admin',
                isNewUser: false,
              }),
            ];
          }),
          catchError((error) => [this.authModel.actions.create.loadAuthDataFailure({ error })])
        )
      )
    )
  );

  resendVerificationEmail$ = createEffect(() =>
    this.authModel.actions.listen.resendVerificationEmail$.pipe(
      switchMap(() =>
        this.fireAuthService.user.pipe(
          take(1),
          switchMap((fireUser) => {
            if (!fireUser) {
              return [
                this.authModel.actions.create.logoutSuccess(),
                this.routerModel.actions.create.navigate({ commands: ['/login'], extras: { queryParamsHandling: 'preserve' } }),
                this.notificationModel.actions.create.showNotification({
                  data: $localize`: @@auth-module|user-not-found-error:User not found`,
                  notificationType: NotificationType.ERROR,
                }),
              ];
            }
            return from(fireUser.sendEmailVerification()).pipe(
              take(1),
              switchMap(() => {
                return [
                  this.authModel.actions.create.resendVerificationEmailSuccess(),
                  this.notificationModel.actions.create.showNotification({
                    data: $localize`: @@auth-module|success-email-verification:Verification email sent`,
                    notificationType: NotificationType.SUCCESS,
                  }),
                ];
              })
            );
          }),
            catchError((error) => {
              this.sentryErrorHandler.handleError(error);
              return [
                this.authModel.actions.create.resendVerificationEmailFailure({ error }),
                this.notificationModel.actions.create.showNotification({
                  data: $localize`: @@auth-module|error-email-verification:Error resending verification email`,
                  notificationType: NotificationType.ERROR,
                }),
              ];
            })
        )
      )
    )
  );

  createFreeTrialCompany = createEffect(() =>
    this.authModel.actions.listen.createFreeTrialCompany$.pipe(
      switchMap((payload) =>
        this.authService.createFreeTrialCompany(payload.demoCompany).pipe(
          takeUntil(this.authModel.actions.listen.createFreeTrialCompanyCancel$),
          switchMap((response) => {
            const company = response.businessCompany ?? null;
            const user = response.user ?? null;
            return company && user
              ? this.authService.getCorporateInfo().pipe(
                  switchMap((response) => {
                    if (!response.success) {
                      void this.fireAuthService.signOut();
                      return [
                        this.authModel.actions.create.createFreeTrialCompanySuccess({ company }),
                        this.notificationModel.actions.create.showNotification({
                          data: $localize`: @@auth-module|error-corporate-info-failure:Cannot fetch corporate info`,
                          notificationType: NotificationType.ERROR,
                        }),
                      ];
                    }
                    const corporateInfo = response.data;
                    const isDeskbirdAdmin = false;

                    if (user && corporateInfo) {
                      this.googleTagManager.initAuthAndCompanyData(user, corporateInfo);
                    }

                    return [
                      this.authModel.actions.create.freeTrialData({
                        freeTrialStartDate: response.data.trialStartDate,
                        freeTrialEndDate: response.data.trialEndDate,
                      }),
                      this.authModel.actions.create.loginSuccess({ user, isDeskbirdAdmin, corporateInfo }),
                      this.authModel.actions.create.createFreeTrialCompanySuccess({ company }),
                    ];
                  })
                )
              : [
                  this.authModel.actions.create.createFreeTrialCompanyFailure({ error: response.message }),
                  this.notificationModel.actions.create.showNotification({
                    data: $localize`: @@free-trial-module|register|error-create-company:Error creating company`,
                    notificationType: NotificationType.ERROR,
                  }),
                ];
          }),
          catchError((error) => {
            this.sentryErrorHandler.handleError(error);
            return [
              this.authModel.actions.create.createFreeTrialCompanyFailure({ error }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`: @@free-trial-module|register|error-create-company:Error creating company`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          })
        )
      )
    )
  );

  restrictedOfficeAccess = createEffect(() =>
    this.authModel.actions.listen.restrictedOfficeAccess$.pipe(
      switchMap(() =>
        combineLatest([this.authService.getUser(), this.fireAuthService.idTokenResult.pipe(take(1))]).pipe(
          switchMap(([user, idTokenResult]) => {
            const isDeskbirdAdmin = idTokenResult?.claims?.['group'] === 'admin';
            return merge(
              [this.authModel.actions.create.setUser({ user, isDeskbirdAdmin })],
              of(this.authModel.actions.create.restrictedOfficeAccessSuccess()).pipe(delay(0))
            );
          }),
          catchError(() => [this.authModel.actions.create.restrictedOfficeAccessFailure()])
        )
      )
    )
  );

  setUserTimeFormat = createEffect(() =>
    this.authModel.actions.listen.loginSuccess$.pipe(
      switchMap(({ user }) => {
        const userHasTimeFormat = user?.hourFormat !== TimeFormat.NOT_SET;
        if (userHasTimeFormat) {
          return [this.authModel.actions.create.setUserTimeFormatSuccess()];
        }
        const hourFormat = estimateTimeFormats();
        return this.authService.updateUser({ hourFormat }).pipe(
          map(() => this.authModel.actions.create.setUserTimeFormatSuccess()),
          catchError((error) => [this.authModel.actions.create.setUserTimeFormatFailure({ error })])
        );
      })
    )
  );

  refetchUserData = createEffect(() =>
    this.authModel.actions.listen.refetchUserData$.pipe(
      switchMap(() =>
        combineLatest([this.authService.getUser(), this.fireAuthService.idTokenResult.pipe(take(1))]).pipe(
          switchMap(([user, idTokenResult]) => {
            const isDeskbirdAdmin = idTokenResult?.claims?.['group'] === 'admin';
            return [
              this.authModel.actions.create.refetchUserDataSuccess({ user }),
              this.authModel.actions.create.setUser({ user, isDeskbirdAdmin }),
            ];
          }),
          catchError(() => [this.authModel.actions.create.refetchUserDataFailure()])
        )
      )
    )
  );

  sendSlackData = createEffect(() =>
    this.authModel.actions.listen.sendSlackData$.pipe(
      switchMap((data) =>
        this.authService.sendSlackData(data).pipe(
          switchMap(() => [this.authModel.actions.create.sendSlackDataSuccess()]),
          catchError((error) => {
            this.sentryErrorHandler.handleError(error);
            return [
              this.authModel.actions.create.sendSlackDataFailure(),
              this.routerModel.actions.create.navigateByUrl({ url: '/' }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`: @@auth-module|send-slack-data:Error sending slack data`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          })
        )
      )
    )
  );

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(APP_URL) private appURL: string,
    @Optional() @Inject(AUTO_LOGIN_CREDENTIALS) private autoLoginCredentials: any,
    private fireAuthService: AngularFireAuth,
    private actions$: Actions,
    private authModel: AuthModel,
    private notificationModel: NotificationModel,
    private routerModel: RouterModel,
    private authService: AuthService,
    private teamsService: TeamsService,
    private debugService: DebugService,
    private sentryErrorHandler: SentryService,
    private googleTagManager: GoogleTagManagerService
  ) {}
}
