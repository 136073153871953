import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { confirmationReducer } from './+store/reducer';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { CONFIRMATION_STORE_NAME } from './constants';
import { ConfirmationModel } from './+store/model';
import { DBCommonModule } from 'common-module';
import { ExecPipe } from 'shared';
import { ButtonComponent, DialogContainerComponent, DialogCardComponent } from 'db-ui';
import { InfoMessageModule } from 'info-message-module';

@NgModule({
  declarations: [ConfirmationComponent],
  imports: [
    DialogCardComponent,
    CommonModule,
    DialogContainerComponent,
    ExecPipe,
    DBCommonModule,
    ButtonComponent,
    InfoMessageModule,
    StoreModule.forFeature(CONFIRMATION_STORE_NAME, confirmationReducer),
  ],
  exports: [ConfirmationComponent],
})
export class ConfirmationModule {
  constructor(@SkipSelf() @Optional() confirmationModule: ConfirmationModel) {
    if (confirmationModule) {
      throw new Error('Confirmation module should be used only once!');
    }
  }
}
