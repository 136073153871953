import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_STORE_NAME } from '../constants';
import { IAuthState } from './reducer';

const getAuthState = createFeatureSelector<IAuthState>(AUTH_STORE_NAME);

const getUser = createSelector(getAuthState, (state: IAuthState) => state.user);
const getIsDeskbirdAdmin = createSelector(getAuthState, (state: IAuthState) => state.isDeskbirdAdmin);
const getCorporateInfo = createSelector(getAuthState, (state: IAuthState) => state.corporateInfo);
const getEmailCheckResult = createSelector(getAuthState, (state: IAuthState) => state.emailCheckResult);
const getPublicDomains = createSelector(getAuthState, (state: IAuthState) => state.publicDomains);
const getIsNewUser = createSelector(getAuthState, (state: IAuthState) => state.isNewUser);
const getEmail = createSelector(getAuthState, (state: IAuthState) => state.email);
const getInitials = createSelector(getAuthState, (state: IAuthState) => state.initials);
const getAvatarColor = createSelector(getAuthState, (state: IAuthState) => state.avatarColor);
const getFreeTrialStartDate = createSelector(getAuthState, (state: IAuthState) => state.freeTrialStartDate);
const getFreeTrialEndDate = createSelector(getAuthState, (state: IAuthState) => state.freeTrialEndDate);
const getSAMLWizardUserId = createSelector(getAuthState, (state: IAuthState) => state.SAMLWizardUserId);
const getIsSavingUser = createSelector(getAuthState, (state: IAuthState) => state.isSavingUser);

const getProfileImage = createSelector(getAuthState, (state: IAuthState) => {
  const { profileImage } = state;
  if (!profileImage) { return profileImage; }
  const timestamp = new Date().getTime();
  return `${profileImage}?timestamp=${timestamp}`
});

export const authSelectors = {
  user: getUser,
  corporateInfo: getCorporateInfo,
  emailCheckResult: getEmailCheckResult,
  publicDomains: getPublicDomains,
  isNewUser: getIsNewUser,
  email: getEmail,
  isDeskbirdAdmin: getIsDeskbirdAdmin,
  initials: getInitials,
  avatarColor: getAvatarColor,
  profileImage: getProfileImage,
  freeTrialStartDate: getFreeTrialStartDate,
  freeTrialEndDate: getFreeTrialEndDate,
  SAMLWizardUserId: getSAMLWizardUserId,
  isSavingUser: getIsSavingUser,
};
