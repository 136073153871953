import { Pipe, PipeTransform } from '@angular/core';
import { hourFormatToTimeFormat } from '../utils/hour-format-to-time-format';
import { TIME_FORMAT } from '../constants';
import { IUser } from 'types';

@Pipe({ name: 'timeFormatByUser', standalone: true })
export class TimeFormatByUserPipe implements PipeTransform {
  transform(user: IUser): string {
    if (!user?.hourFormat) {
      return TIME_FORMAT;
    }
    return hourFormatToTimeFormat(user.hourFormat);
  }
}
