import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { ApplicationModule } from 'common-module';
import { RouterModel } from 'router-module';
import { combineLatest, map, Observable, take } from 'rxjs';
import { AuthModel } from '../+store/model';
import { hasAccessToPageFactory } from 'shared';
import { UserRole } from 'types';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeActivate  {
  constructor(private authModel: AuthModel, private routerModel: RouterModel, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const { data } = route;
    const { navigated } = this.router;

    const deskbirdAdminOnly = !!data?.['deskbirdAdminOnly'];
    const userRolesRestrictions: UserRole[] | null = (data?.['userRoles'] as UserRole[]) || null;
    const appModuleRestrictions: ApplicationModule | null = (data?.['appModuleRestrictions'] as ApplicationModule) || null;

    return combineLatest([
      this.authModel.user$,
      this.authModel.corporateInfo$,
      this.routerModel.isAdminAppEnv$,
      this.authModel.selectors.isDeskbirdAdmin$,
      this.routerModel.selectors.url$,
      this.authModel.userRoleCompanyPageRestrictions$,
    ]).pipe(
      map(([user, corporateInfo, isAdminAppEnv, isDeskbirdAdmin, url, userRoleCompanyPageRestrictions]) => {
        if (!user || !corporateInfo) {
          return navigated ? false : this.router.parseUrl(`/login?redirectUrl=${url}`);
        }
        if (deskbirdAdminOnly) {
          return isDeskbirdAdmin
            ? true
            : this.router.parseUrl(`${isAdminAppEnv ? this.routerModel.adminPartPrefix : this.routerModel.clientPartPrefix}default`);
        }
        if (isDeskbirdAdmin) {
          return true;
        }

        const userRoleCompanyPageRestrictionsForEnv =
          (isAdminAppEnv ? userRoleCompanyPageRestrictions.admin : userRoleCompanyPageRestrictions.client) || null;

        const hasUserRoleCompanyPageRestriction = !(userRoleCompanyPageRestrictionsForEnv
          ? hasAccessToPageFactory(userRoleCompanyPageRestrictionsForEnv)(route.url.map((s) => s.path).join('/'))
          : true);

        let canContinue = false;
        if (!appModuleRestrictions && !userRolesRestrictions && !hasUserRoleCompanyPageRestriction) {
          canContinue = true;
        } else {
          if (!hasUserRoleCompanyPageRestriction && appModuleRestrictions && corporateInfo[appModuleRestrictions]) {
            canContinue = true;
          }
          if (!hasUserRoleCompanyPageRestriction && userRolesRestrictions && userRolesRestrictions.includes(user.role)) {
            canContinue = true;
          }
        }
        if (!canContinue) {
          return navigated
            ? false
            : this.router.parseUrl(`${isAdminAppEnv ? this.routerModel.adminPartPrefix : this.routerModel.clientPartPrefix}default`);
        }
        return true;
      }),
      take(1)
    );
  }
}
