import { estimateTimeFormats, ICorporateInfo, IEmailCheck } from 'common-module';
import { Action, createReducer, on } from '@ngrx/store';
import { NOT_SET } from '../constants';
import {
  setUserBundle,
  updateUserBundle,
  checkEmailBundle,
  loadUserProfile,
  loadPublicDomainsBundle,
  loginBundle,
  logoutBundle,
  googleSignInBundle,
  microsoftSignInBundle,
  setEmailBundle,
  setInitialsBundle,
  setProfileImageBundle,
  loadAuthDataBundle,
  registerBundle,
  updateEmailCheckResultVerifiedBundle,
  microsoftTeamsSignInBundle,
  addUserOfficeIdBundle,
  setFreeTrialDataBundle,
  setSAMLUserNameBundle,
  setFavoriteResourceBundle,
  removeFavoriteResourceBundle,
} from './bundles';
import { getAbbreviation } from 'shared';
import { UserRole, TimeFormat, IUser } from 'types';

export interface IAuthState {
  readonly user: IUser | null | typeof NOT_SET;
  readonly SAMLWizardUserId: string | null;
  readonly SAMLWizardFirstName: string | null;
  readonly SAMLWizardLastName: string | null;
  readonly isDeskbirdAdmin: boolean;
  readonly isNewUser: boolean | null;
  readonly corporateInfo: ICorporateInfo | null;
  readonly email: string | null;
  readonly emailCheckResult: IEmailCheck | null;
  readonly initials: string | null;
  readonly avatarColor: string | null;
  readonly profileImage: string | null;
  readonly publicDomains: string[] | null;
  readonly freeTrialStartDate: string | null;
  readonly freeTrialEndDate: string | null;
  readonly isSavingUser: boolean;
}

export const initialState: IAuthState = {
  user: NOT_SET,
  isDeskbirdAdmin: false,
  SAMLWizardUserId: null,
  SAMLWizardFirstName: null,
  SAMLWizardLastName: null,
  isNewUser: null,
  corporateInfo: null,
  email: null,
  emailCheckResult: null,
  initials: null,
  avatarColor: null,
  profileImage: null,
  publicDomains: null,
  freeTrialStartDate: null,
  freeTrialEndDate: null,
  isSavingUser: false,
};

export const authReducer = createReducer(
  initialState,
  on(setUserBundle.setUser, (state, payload) => {
    const { user, isNewUser = false, isDeskbirdAdmin = false } = payload;
    return {
      ...state,
      user,
      isNewUser,
      isDeskbirdAdmin,
      SAMLWizardUserId: user ? null : state.SAMLWizardUserId,
      SAMLWizardFirstName: user ? null : state.SAMLWizardFirstName,
      SAMLWizardLastName: user ? null : state.SAMLWizardLastName,
    };
  }),
  on(setFreeTrialDataBundle.freeTrialData, (state, payload) => {
    return {
      ...state,
      freeTrialStartDate: payload.freeTrialStartDate || null,
      freeTrialEndDate: payload.freeTrialEndDate || null,
    };
  }),
  on(loginBundle.loginSuccess, (state, payload) => {
    const { user, corporateInfo, isNewUser = false, isDeskbirdAdmin = false } = payload;

    const hourFormat = user ? user.hourFormat || estimateTimeFormats() : TimeFormat.NOT_SET;

    return {
      ...state,
      email: null,
      user: user ? { ...user, hourFormat } : null,
      isNewUser,
      isDeskbirdAdmin,
      corporateInfo,
      profileImage: user?.profileImage || null,
      SAMLWizardUserId: user ? null : state.SAMLWizardUserId,
      SAMLWizardFirstName: user ? null : state.SAMLWizardFirstName,
      SAMLWizardLastName: user ? null : state.SAMLWizardLastName,
    };
  }),
  on(logoutBundle.logoutSuccess, (state) => {
    return {
      ...state,
      user: null,
      corporateInfo: null,
      profileImage: null,
    };
  }),
  on(updateUserBundle.updateUser, (state) => ({ ...state, isSavingUser: true })),
  on(updateUserBundle.updateUserSuccess, (state, payload) => {
    const { updates, userId } = payload;
    const { user: existingUser } = state;
    if (existingUser === null || existingUser === NOT_SET || userId !== existingUser.id) {
      return state;
    }
    const profileImage = [null, undefined].includes(updates.profileImage as any) ? state.profileImage : updates.profileImage;

    return { ...state, user: { ...existingUser, ...updates }, profileImage: profileImage || '', isSavingUser: false };
  }),
  on(checkEmailBundle.checkEmail, (state, { email }) => {
    return { ...state, email };
  }),
  on(checkEmailBundle.checkEmailSuccess, (state, { emailCheckResult }) => {
    return { ...state, emailCheckResult, email: emailCheckResult.email || null };
  }),
  on(updateEmailCheckResultVerifiedBundle.updateEmailCheckResultVerified, (state, { isVerified }) => {
    return { ...state, emailCheckResult: state.emailCheckResult ? { ...state.emailCheckResult, isVerified } : null };
  }),
    on(loadUserProfile.loadUserProfileSuccess, (state, payload) => {
      const {
        user: { ...user },
      } = payload;
      return { ...state, user };
    }),
  on(loadPublicDomainsBundle.loadPublicDomainsSuccess, (state, payload) => {
    const { publicDomains } = payload;
    return { ...state, publicDomains };
  }),
  on(
    microsoftSignInBundle.microsoftSignInSuccess,
    googleSignInBundle.googleSignInSuccess,
    microsoftTeamsSignInBundle.microsoftTeamsSignInSuccess,
    (state, payload) => {
      const { user, isNewUser, isDeskbirdAdmin, corporateInfo } = payload;

      if (!user) {
        return state;
      }
      const { profileImage, avatarColor } = user;
      const initials = getAbbreviation(`${user?.firstName} ${user?.lastName}`);
      return {
        ...state,
        profileImage,
        avatarColor,
        user,
        initials,
        email: user.email,
        isNewUser,
        isDeskbirdAdmin,
        corporateInfo,
        SAMLWizardUserId: user ? null : state.SAMLWizardUserId,
        SAMLWizardFirstName: user ? null : state.SAMLWizardFirstName,
        SAMLWizardLastName: user ? null : state.SAMLWizardLastName,
      };
    }
  ),
  on(setEmailBundle.setEmail, (state, payload) => {
    const { email } = payload;
    return { ...state, email };
  }),
  on(setEmailBundle.setEmailCleanup, (state) => {
    return { ...state, email: null };
  }),
  on(registerBundle.registerSuccess, (state, payload) => {
    const { user } = payload;
    const initials = getAbbreviation(`${user?.firstName} ${user?.lastName}`);
    const avatarColor = user?.avatarColor || state.avatarColor;
    const profileImage = user?.profileImage || state.profileImage;
    return { ...state, initials, avatarColor, profileImage };
  }),
  on(setInitialsBundle.setInitials, (state, payload) => {
    const { initials, avatarColor } = payload;
    return { ...state, initials, avatarColor: avatarColor || '' };
  }),
  on(setInitialsBundle.setInitialsCleanup, (state) => {
    return { ...state, initials: null };
  }),
  on(setProfileImageBundle.setProfileImage, (state, payload) => {
    const { profileImage } = payload;
    return { ...state, profileImage: profileImage || null };
  }),
  on(loadAuthDataBundle.loadAuthDataSuccess, (state, payload) => {
    const { user, corporateInfo, isNewUser = false, isDeskbirdAdmin = false } = payload;
    return { ...state, user, isNewUser, isDeskbirdAdmin, corporateInfo, profileImage: user?.profileImage || null };
  }),
  on(checkEmailBundle.checkEmailCleanup, (state) => {
    return { ...state, emailCheckResult: null };
  }),
  on(addUserOfficeIdBundle.addUserOfficeId, (state, payload) => {
    const { officeId } = payload;
    if (state.user === NOT_SET || state.user === null || state.user.role === UserRole.USER || state.user.role === UserRole.GUEST) {
      return state;
    }

    const officeIds = state.user.officeIds?.concat(officeId);
    const user = { ...state.user, officeIds };
    return { ...state, user };
  }),
    on(setSAMLUserNameBundle.setSamlUserName, (state, { userId, firstName, lastName }) => {
      return { ...state, SAMLWizardUserId: userId, SAMLWizardFirstName: firstName, SAMLWizardLastName: lastName };
    }),
  on(setSAMLUserNameBundle.setSamlUserNameCleanup, (state) => {
    return { ...state, SAMLWizardUserId: null, SAMLWizardFirstName: null, SAMLWizardLastName: null };
  }),
  on(setFavoriteResourceBundle.setFavoriteResource, (state, payload) => {
    const { resource } = payload;
    const user = state.user;
    if (!user) {
      return state;
    }
    const favoriteResources = (user as IUser).favoriteResources || [];
    const updatedUser = { ...(user as IUser), favoriteResources: [...favoriteResources, resource] };
    return { ...state, user: updatedUser };
  }),
    on(removeFavoriteResourceBundle.removeFavoriteResource, (state, payload) => {
      const { resourceId } = payload;
      const user = state.user;
      if (!user) {
        return state;
      }
      const favoriteResources = (user as IUser).favoriteResources || [];
      const updatedUser = { ...(user as IUser), favoriteResources: favoriteResources.filter((fav) => fav.id !== resourceId) };
      return { ...state, user: updatedUser };
    })
);

export function generateLogoutStateCleaner<State>(fn: (state: State) => any = () => ({})) {
  return function logoutStateCleaner(reducer: any): any {
    return (state: any, action: Action) => {
      return reducer(
        ([logoutBundle.logoutSuccess.type] as string[]).includes(action.type)
          ? {
              auth: { ...initialState, user: null },
              ...fn(state),
            }
          : state,
        action
      );
    };
  };
}
